import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFacebook } from "react-facebook";
import * as Realm from "realm-web";
import {
  renewUserSession,
  logoutUserSession,
  updateFacebookFriends,
  addMachineId,
} from "../api/user";
import {
  getUnreadNotificationCount,
  subscribe,
  unsubscribe,
} from "../api/notification";
import jwtDecode from "jwt-decode";

const AccountsContext = React.createContext({
  user: null,
  sessionLoading: true,
  async login() {},
  async logout() {},
  async updateUser() {},
});

function AccountProvider(props) {
  const { children } = props;
  const [user, setUser] = useState();
  const [sessionLoading, setSessionLoading] = useState(true);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });

  const { init } = useFacebook();
  const [facebookFriendsNextPage, setFacebookFriendsNextPage] = useState(null);
  const [facebookFriendsPageEnd, setFacebookFriendsPageEnd] = useState(false);

  const facebookFriendsNextPageRef = useRef();
  facebookFriendsNextPageRef.current = facebookFriendsNextPage;

  const facebookFriendsPageEndRef = useRef();
  facebookFriendsPageEndRef.current = facebookFriendsPageEnd;

  const getFacebookFriends = async () => {
    if (facebookFriendsPageEndRef.current) return [];
    const api = await init();
    const FB = await api.getFB();
    const accessToken = localStorage.getItem("accessToken");
    let facebookFriends = [];
    let isLoading = true;
    FB.api(
      facebookFriendsNextPageRef.current
        ? facebookFriendsNextPageRef.current
        : `/${user.facebookId}/friends?access_token=${accessToken}`,
      "GET",
      {},
      async function (response) {
        try {
          if (response.paging && response.paging.next) {
            setFacebookFriendsNextPage(response.paging.next);
          } else {
            setFacebookFriendsPageEnd(true);
          }
          facebookFriends.push(
            ...response.data,
            ...(await getFacebookFriends())
          );
        } catch (error) {}
        isLoading = false;
      }
    );
    while (isLoading) {
      await new Promise((resolve) => setTimeout(resolve, 0));
    }
    return facebookFriends;
  };

  function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  async function login(token) {
    setSessionLoading(true);
    try {
      let user = jwtDecode(token);
      setUser(user);
      localStorage.setItem("token", token);
      localStorage.setItem("finishedTutorial", user.finishedTutorial);
      if (user.facebookId) {
        let accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
          const api = await init();
          try {
            accessToken = await api.getToken();
          } catch (error) {
            await api.login({ scope: "public_profile, email, user_friends" });
            accessToken = await api.getToken();
          }
          localStorage.setItem("accessToken", accessToken);
        }
      }
      if (window.Notification) {
        const registration = await navigator.serviceWorker.register(
          "/serviceworker.js"
        );
        const result = await window.Notification.requestPermission();
        if (result === "granted") {
          const subscription = await registration.pushManager.subscribe({
            applicationServerKey: urlBase64ToUint8Array(
              process.env.REACT_APP_VAPID_PUBLIC_KEY
            ),
            userVisibleOnly: true,
          });
          if (subscription) {
            const result = await subscribe(subscription);
            localStorage.setItem("subscriptionEndpoint", result.data);
          }
        }
      }
      localStorage.removeItem("referrer");
      await addMachineId();
    } catch (error) {
      console.log(error);
    }
    setSessionLoading(false);
  }

  async function updateUser() {
    setSessionLoading(true);
    try {
      const token = localStorage.getItem("token");
      const result = await renewUserSession(token);
      localStorage.setItem("token", result.data);
      const user = jwtDecode(result.data);
      localStorage.setItem("finishedTutorial", user.finishedTutorial);
      setUser(user);
    } catch (error) {
      console.log(error);
    }
    setSessionLoading(false);
  }

  async function logout() {
    setSessionLoading(true);
    try {
      if (user.subscriptionEndpoint) {
        await unsubscribe(user.subscriptionEndpoint);
      }
      await logoutUserSession();
    } catch (error) {
      console.log(error);
    }
    localStorage.removeItem("token");
    localStorage.removeItem("finishedTutorial");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("subscriptionEndpoint");
    setUser(null);
    setSessionLoading(false);
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      updateUser();
    } else {
      logout();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (user) {
        getUnreadNotificationCount().then((result) => {
          setUnreadNotificationCount(result.data.count);
        });
        (async () => {
          await app.logIn(
            Realm.Credentials.apiKey(process.env.REACT_APP_REALM_API_KEY)
          );
          const mongodb = app.currentUser.mongoClient("mongodb-atlas");
          const collection = mongodb.db("test").collection("users");
          for await (const change of collection.watch()) {
            if (change.fullDocument._id.toString() === user._id) {
              setUnreadNotificationCount(
                change.fullDocument.unreadNotifications.length
              );
            }
          }
        })();
      }
    })();
    if (user && user.facebookId) {
      (async () => {
        const facebookFriends = await getFacebookFriends();
        await updateFacebookFriends(facebookFriends);
      })();
    }
  }, [user]);

  const value = useMemo(
    () => ({
      user,
      sessionLoading,
      unreadNotificationCount,
      login,
      logout,
      updateUser,
    }),
    [user, sessionLoading, unreadNotificationCount]
  );

  return (
    <AccountsContext.Provider value={value}>
      {children}
    </AccountsContext.Provider>
  );
}

const useAccount = () => React.useContext(AccountsContext);

export { AccountProvider, useAccount };
