import React, { useState } from "react";
import { useAccount } from "../providers/AccountProvider";
import { useLocation, useNavigate } from "react-router-dom";
import color from "../utils/color";
import { verify } from "../api/user";
import Button from "@mui/material/Button";
import LogoFull from "../components/LogoFull";

const VerifyPage = () => {
  const [verification, setVerification] = useState("");
  const [error, setError] = useState(false);
  const { login } = useAccount();
  let navigate = useNavigate();
  let location = useLocation();
  const { phone, eventId } = location.state;

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!verification) {
      setError("Please enter verification code");
    }
    if (error) {
      return;
    }
    try {
      const result = await verify(phone, verification);
      login(result.data);
      if (eventId) {
        navigate(`/event/${eventId}`);
      } else {
        navigate("/");
      }
    } catch (error) {
      setError(error.response.data);
    }
    return;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          background: color.background,
        }}
      >
        <div
          style={{
            textAlign: "center",
            padding: "50px",
            background: color.secondary,
            borderRadius: "10px",
            boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
            width: window.innerWidth < 700 ? "100%" : "60%",
            height: window.innerWidth < 700 ? "100%" : "60%",
            boxSizing: "border-box",
          }}
        >
          <LogoFull
            onClick={() => {
              navigate("/");
            }}
            style={{ marginTop: "20px", height: "100px", cursor: "pointer" }}
          />
          <div style={{ color: "red" }}>{error}</div>
          <form onSubmit={handleLogin}>
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  marginBottom: "10px",
                  color: color.textSecondary,
                  textTransform: "uppercase",
                  fontSize: "12px",
                  letterSpacing: "1px",
                }}
              >
                Verification Code:
              </label>
              <input
                type="text"
                maxLength="5"
                value={verification}
                onChange={(e) => {
                  setVerification(e.target.value);
                  setError("");
                }}
                style={{
                  padding: "10px",
                  fontSize: "16px",
                  width: "100%",
                  borderRadius: "5px",
                  border: "none",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                  boxSizing: "border-box",
                  textAlign: "center",
                  letterSpacing: "4px",
                }}
              />
            </div>
            <Button
              type="submit"
              style={{
                backgroundColor: color.green,
                color: color.textPrimary,
                padding: "10px 20px",
                fontSize: "16px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Verify
            </Button>
            <br />
          </form>
        </div>
      </div>
    </>
  );
};

export default VerifyPage;
