import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useAccount } from "../providers/AccountProvider";
import color from "../utils/color";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Hunchies from "./Hunchies";
import PayModal from "./PayModal";
import ReferModal from "./ReferModal";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import InfoIcon from "@mui/icons-material/Info";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import { Divider } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import SearchInput from "./SearchInput";
import LogoIcon from "./LogoIcon";
import LogoFull from "./LogoFull";

const pages = ["Create", "How It Works"];

function Header() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const { user, unreadNotificationCount, logout } = useAccount();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigate = (page) => {
    switch (page) {
      case "Create":
        window.location.href = user ? "/create" : "/login";
        break;
      case "How It Works":
        window.location.href = "/how-it-works";
        break;
      default:
        break;
    }
  };

  const hunchiesModalRef = React.useRef();
  const referModalRef = React.useRef();

  const showHunchiesModal = () => {
    hunchiesModalRef.current.show();
  };

  const showReferModal = () => {
    referModalRef.current.show();
  };

  return (
    <>
      <div style={{ height: "64px", color: color.background }} />
      <AppBar style={{ backgroundColor: color.background, zIndex: 2 }}>
        <Container maxWidth="xl">
          {isSearchOpen ? (
            <Toolbar disableGutters style={{ height: "64px" }}>
              <Box
                sx={{
                  flexGrow: 1,
                  marginLeft: "10px",
                  marginRight: "20px",
                }}
              >
                <SearchInput />
              </Box>
              <Button
                style={{ color: color.textSecondary }}
                onClick={() => setIsSearchOpen(false)}
              >
                Cancel
              </Button>
            </Toolbar>
          ) : (
            <Toolbar disableGutters>
              <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
                <LogoIcon
                  style={{ height: "64px", cursor: "pointer" }}
                  onClick={() => (window.location.href = "/")}
                />
              </Box>
              <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
                <LogoFull
                  style={{ height: "64px", cursor: "pointer" }}
                  onClick={() => (window.location.href = "/")}
                />
              </Box>
              <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    onClick={() => handleNavigate(page)}
                    sx={{ my: 2, color: color.textSecondary, display: "block" }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
              {window.innerWidth > 600 ? (
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  <SearchInput />
                </Box>
              ) : (
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "flex-end",
                    marginRight: "10px",
                  }}
                >
                  <SearchIcon
                    onClick={() => setIsSearchOpen(true)}
                    style={{
                      color: color.textSecondary,
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              )}
              <Box sx={{ flexGrow: 0 }}>
                {user ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      onClick={handleOpenUserMenu}
                    >
                      <IconButton sx={{ p: 0, marginRight: 1 }}>
                        <Avatar
                          alt={user.name}
                          src={user.profilePicture ? user.profilePicture : "/"}
                        />
                        {window.innerWidth >= 900 &&
                          !anchorElUser &&
                          unreadNotificationCount > 0 && (
                            <div
                              style={{
                                position: "absolute",
                                top: -5,
                                right: -5,
                                backgroundColor: color.red,
                                borderRadius: "50%",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: color.textPrimary,
                                fontSize: "12px",
                              }}
                            >
                              {unreadNotificationCount}
                            </div>
                          )}
                      </IconButton>
                      <div
                        style={{
                          color: color.textSecondary,
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <PayModal ref={hunchiesModalRef} />
                        <ReferModal ref={referModalRef} />
                        <div>{user.name}</div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {user.hunchies.toFixed(1)}
                          <Hunchies style={{ fontSize: 16 }} /> &nbsp;
                          <CurrencyExchangeIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              showHunchiesModal();
                            }}
                            style={{
                              fontSize: 16,
                              cursor: "pointer",
                              color: color.gold,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {window.innerWidth >= 900 && (
                      <Button
                        style={{
                          backgroundColor: color.primary,
                          color: color.textPrimary,
                          borderRadius: "5px",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={showReferModal}
                      >
                        Refer for 500{<Hunchies color="white" />}
                      </Button>
                    )}
                  </div>
                ) : (
                  <Button
                    href={"/signup"}
                    style={{
                      backgroundColor: color.primary,
                      color: color.textPrimary,
                      borderRadius: "5px",
                      cursor: "pointer",
                      marginLeft: "10px",
                    }}
                  >
                    Get $10 - Sign Up
                  </Button>
                )}
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {window.innerWidth < 900 && (
                    <MenuItem
                      key={"refer"}
                      onClick={handleCloseUserMenu}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: color.primary,
                        margin: 5,
                        borderRadius: 5,
                      }}
                    >
                      <Typography
                        textAlign="center"
                        color={"white"}
                        onClick={showReferModal}
                      >
                        Refer for 500{<Hunchies color="white" />}
                      </Typography>
                    </MenuItem>
                  )}
                  <MenuItem
                    key={"Profile"}
                    onClick={handleCloseUserMenu}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      textAlign="flex-start"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        window.location.href = `/profile/${user._id}`;
                      }}
                    >
                      <PersonIcon /> &nbsp; Profile
                    </Typography>
                  </MenuItem>
                  {window.innerWidth >= 900 && (
                    <MenuItem
                      key={"Notifications"}
                      onClick={handleCloseUserMenu}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        textAlign="flex-start"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          window.location.href = "/notifications";
                        }}
                      >
                        <NotificationsIcon /> &nbsp; Notifications
                        {unreadNotificationCount > 0 && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 15,
                              backgroundColor: color.red,
                              borderRadius: "50%",
                              width: "16px",
                              height: "16px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: color.textPrimary,
                              fontSize: "10px",
                            }}
                          >
                            {unreadNotificationCount}
                          </div>
                        )}
                      </Typography>
                    </MenuItem>
                  )}
                  <MenuItem
                    key={"Hunchies"}
                    onClick={handleCloseUserMenu}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      textAlign="flex-start"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      onClick={showHunchiesModal}
                    >
                      <CurrencyExchangeIcon /> &nbsp; Buy / Redeem
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    key={"HowItWorks"}
                    onClick={handleCloseUserMenu}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      textAlign="flex-start"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        window.location.href = "/how-it-works";
                      }}
                    >
                      <MenuBookIcon /> &nbsp; How It Works
                    </Typography>
                  </MenuItem>
                  {/* <MenuItem
                    key={"AboutUs"}
                    onClick={handleCloseUserMenu}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      textAlign="flex-start"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        window.location.href = "/how-it-works";
                      }}
                    >
                      <InfoIcon /> &nbsp; About Us
                    </Typography>
                  </MenuItem>
                  <Divider /> */}
                  <MenuItem
                    key={"PrivacyPolicy"}
                    onClick={handleCloseUserMenu}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      textAlign="flex-start"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        window.location.href = "/privacy-policy";
                      }}
                    >
                      <LockIcon /> &nbsp; Privacy Policy
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    key={"Logout"}
                    onClick={handleCloseUserMenu}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      textAlign="flex-start"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      onClick={logout}
                    >
                      <LogoutIcon /> &nbsp; Logout
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          )}
        </Container>
      </AppBar>
    </>
  );
}
export default Header;
