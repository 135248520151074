import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useAccount } from "../providers/AccountProvider";
import color from "../utils/color";
import { verify } from "../api/user";
import Button from "@mui/material/Button";

const VerifyModal = forwardRef((props, ref) => {
  const [verification, setVerification] = useState("");
  const [error, setError] = useState(false);
  const { login } = useAccount();
  const { phone } = props;

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!verification) {
      setError("Please enter verification code");
    }
    if (error) {
      return;
    }
    try {
      const result = await verify(phone, verification);
      await login(result.data);
      setShowModal(false);
    } catch (error) {
      setError(error.response.data);
    }
    return;
  };

  const [showModal, setShowModal] = useState(false);

  const show = () => {
    setShowModal(true);
  };

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  return (
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000000,
          }}
          onClick={() => setShowModal(false)}
        >
          <div
            style={{
              backgroundColor: color.secondary,
              padding: "20px",
              borderRadius: "8px",
              color: color.textSecondary,
              width: "80%",
              maxWidth: "500px",
              margin: "auto",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2
              style={{
                marginBottom: "20px",
                color: color.textSecondary,
                textAlign: "center",
              }}
            >
              Check SMS
            </h2>
            <div style={{ color: "red" }}>{error}</div>
            <form onSubmit={handleLogin}>
              <div
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    marginBottom: "10px",
                    color: color.textSecondary,
                    textTransform: "uppercase",
                    fontSize: "12px",
                    letterSpacing: "1px",
                  }}
                >
                  Verification Code:
                </label>
                <input
                  type="text"
                  maxLength="5"
                  value={verification}
                  onChange={(e) => {
                    setVerification(e.target.value);
                    setError("");
                  }}
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    width: "100%",
                    borderRadius: "5px",
                    border: "none",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    boxSizing: "border-box",
                    textAlign: "center",
                    letterSpacing: "4px",
                  }}
                />
              </div>
              <Button
                type="submit"
                style={{
                  backgroundColor: color.green,
                  color: color.textPrimary,
                  padding: "10px 20px",
                  fontSize: "16px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Verify
              </Button>
              <br />
            </form>
          </div>
        </div>
      )}
    </>
  );
});

export default VerifyModal;
