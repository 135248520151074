import React, { useEffect, useRef, useState } from "react";
import { useAccount } from "../providers/AccountProvider";
import { convertToPast } from "../utils/convertTime";
import CommentBox from "./CommentBox";
import MessageIcon from "@mui/icons-material/Message";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import DeleteIcon from "@mui/icons-material/Delete";
import LoginModal from "./LoginModal";
import color from "../utils/color";
import { Avatar } from "@mui/material";
import { likeComment, postReply, deleteComment } from "../api/comment";
import Button from "@mui/material/Button";

const MessageContainer = ({ comment, setShouldReload }) => {
  const [replying, setReplying] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [newReply, setNewReply] = useState("");
  const [likes, setLikes] = useState(0);
  const [userLiked, setUserLiked] = useState(false);
  const [userComment, setUserComment] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const { user } = useAccount();

  const post = async () => {
    await postReply(comment._id, newReply);
    setNewReply("");
    setReplying(false);
    setShouldReload(true);
  };

  const like = async () => {
    const result = await likeComment(comment._id);
    setLikes(result.data.likedUsers.length);
    setUserLiked(
      result.data.likedUsers.findIndex((likedUser) => likedUser === user._id) >
        -1
    );
    setShouldReload(true);
  };

  const deleteClicked = async () => {
    const result = await deleteComment(comment._id);
    setDeleted(result.data.deleted);
    setDeleting(false);
    setShouldReload(true);
  };

  useEffect(() => {
    setLikes(comment.likedUsers ? comment.likedUsers.length : 0);
    if (user) {
      setUserLiked(
        comment.likedUsers
          ? comment.likedUsers.findIndex(
              (likedUser) => likedUser === user._id
            ) > -1
          : false
      );
      setUserComment(
        comment.commenter ? comment.commenter._id === user._id : false
      );
    } else {
      setUserLiked(false);
      setUserComment(false);
    }
    setDeleted(comment.deleted);
  }, [comment, user]);

  const loginModalRef = useRef();

  const showLoginModal = () => {
    loginModalRef.current.show();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        marginTop: "15px",
      }}
    >
      <Avatar
        style={{
          marginRight: "10px",
          cursor: "pointer",
        }}
        alt={comment.commenter ? comment.commenter.name : ""}
        src={
          comment.commenter && comment.commenter.profilePicture
            ? comment.commenter.profilePicture
            : "/"
        }
        onClick={() => {
          if (comment.commenter) {
            window.location.href = `/profile/${comment.commenter._id}`;
          }
        }}
      />
      <LoginModal ref={loginModalRef} />
      <div style={styles.comment}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span
            style={styles.commentAuthor}
            onClick={() => {
              if (comment.commenter) {
                window.location.href = `/profile/${comment.commenter._id}`;
              }
            }}
          >
            {comment.commenter ? comment.commenter.name : ""}
          </span>
          {comment.commenter && comment.commenter.verified && (
            <img
              src={require("../assets/images/verified.png")}
              alt="Verified"
              style={{
                marginLeft: "-15px",
                height: "30px",
                alignSelf: "center",
                marginRight: "-15px",
              }}
            />
          )}
          <span style={{ marginLeft: "5px", marginRight: "5px" }}>{"·"}</span>
          <span style={styles.commentTime}>
            {convertToPast(comment.commentedAt)}
          </span>
        </div>
        <div>
          <p style={styles.commentText}>
            {deleted ? "[Deleted]" : comment.content}
          </p>
          {replying ? (
            <div>
              <CommentBox
                placeholder="Write your reply here..."
                value={newReply}
                onChange={(e) => {
                  setNewReply(e.target.value);
                }}
              />
              <Button
                style={{ ...styles.greenButton, marginRight: "10px" }}
                onClick={post}
              >
                post reply
              </Button>
              <Button
                style={styles.redButton}
                onClick={() => setReplying(false)}
              >
                cancel
              </Button>
            </div>
          ) : deleting ? (
            <div>
              Confirm delete?
              <div style={{ marginTop: "10px" }}>
                <Button
                  style={{
                    ...styles.greenButton,
                    marginRight: "10px",
                  }}
                  onClick={deleteClicked}
                >
                  confirm
                </Button>
                <Button
                  style={styles.redButton}
                  onClick={() => setDeleting(false)}
                >
                  cancel
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              <ThumbUpIcon
                color={userLiked ? "primary" : ""}
                fontSize="small"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (!user) {
                    showLoginModal();
                  } else {
                    like();
                  }
                }}
              />
              <div style={{ marginLeft: 2, marginRight: 10 }}>{likes}</div>
              <MessageIcon
                fontSize="small"
                onClick={() => {
                  if (!user) {
                    showLoginModal();
                  } else {
                    setReplying(true);
                  }
                }}
                style={{ marginLeft: 2, marginRight: 10, cursor: "pointer" }}
              />
              {userComment && (
                <DeleteIcon
                  fontSize="small"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setDeleting(true);
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  comment: {
    width: "100%",
  },
  commentAuthor: {
    fontWeight: "bold",
    cursor: "pointer",
  },
  commentTime: {},
  commentText: {
    marginTop: "5px",
  },
  greenButton: {
    backgroundColor: color.green,
    color: color.textPrimary,
    padding: "5px 10px",
    border: "none",
    cursor: "pointer",
  },
  redButton: {
    backgroundColor: color.red,
    color: color.textPrimary,
    padding: "5px 10px",
    border: "none",
    cursor: "pointer",
  },
};

export default MessageContainer;
