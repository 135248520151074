import React, { useEffect, useState } from "react";
import { getPopularTopics, searchTopics } from "../api/topic";
import { Autocomplete, TextField } from "@mui/material";

const TopicInput = ({ topics, setTopics }) => {
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!searchTerm) {
      (async () => {
        const result = await getPopularTopics();
        setOptions(
          result.data
            .filter((value) => !topics.find((topic) => topic == value.name))
            .map((topic) => topic.name)
        );
      })();
    } else {
      (async () => {
        const result = await searchTopics(searchTerm);
        setOptions(
          result.data
            .filter((value) => !topics.find((topic) => topic == value.name))
            .map((topic) => topic.name)
        );
      })();
    }
  }, [searchTerm, topics]);

  return (
    <Autocomplete
      style={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: "5px",
      }}
      value={topics}
      onFocus={() => {
        setSearchTerm("");
      }}
      onChange={(e, newValue) => {
        const uniqueValues = newValue.filter(
          (item, index) => newValue.indexOf(item) === index
        );
        setTopics(uniqueValues);
        setSearchTerm("");
      }}
      freeSolo
      multiple
      options={options}
      getOptionLabel={(option) => option}
      filterOptions={(options, params) => {
        if (params.inputValue !== "") {
          options.push(params.inputValue);
        }
        const uniqueArray = options.filter(
          (item, index) => options.indexOf(item) === index
        );
        return uniqueArray;
      }}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
      )}
    />
  );
};

export default TopicInput;
