import { Grid } from "@mui/material";
import EventCard from "./EventCard";
import { useEffect, useState } from "react";
import { useAccount } from "../providers/AccountProvider";
import { getFriends } from "../api/user";

export default function Gallery({ fetchEvents, status, events }) {
  const [friends, setFriends] = useState([]);

  const { user } = useAccount();

  useEffect(() => {
    if (user) {
      getFriends().then((result) => {
        setFriends(result.data);
      });
    }
  }, [user]);

  const widthToAmount = (width) => {
    if (width >= 1200) {
      return 2;
    } else if (width >= 900) {
      return 2;
    } else if (width >= 600) {
      return 2;
    } else {
      return 2;
    }
  };

  useEffect(() => {
    let unmounted = false;
    const loadMore = async () => {
      if (unmounted) return;
      try {
        if (
          status != "loading" &&
          status != "finished" &&
          window.scrollY + window.innerHeight + 5 > document.body.offsetHeight
        ) {
          fetchEvents(
            events[events.length - 1] ? events[events.length - 1]._id : null,
            2 * widthToAmount(window.innerWidth)
          );
        }
      } catch (error) {
        console.log(error);
        return;
      }
    };
    loadMore();
    window.addEventListener("scroll", loadMore);
    return () => {
      window.removeEventListener("scroll", loadMore);
      unmounted = true;
    };
  }, [events]);

  return (
    <Grid container spacing={2}>
      {events.map((event, i) => {
        return (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i}>
            <div key={i} style={styles.productContainer}>
              <EventCard event={event} friends={friends} />
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}

const styles = {
  productContainer: {
    borderWidth: 0,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
};
