import { pingServer } from "./home";

let mainUrl = process.env.REACT_APP_PROD_URL;
// mainUrl = "http://localhost";

const baseUrl = async () => {
  // try {
  //   let result = await pingServer(mainUrl);
  //   if (result.status === 200) {
  //     return mainUrl;
  //   } else {
  //     return herokuBUPUrl;
  //   }
  // } catch (error) {
  //   return herokuBUPUrl;
  // }
  return mainUrl;
};

export default baseUrl;
