import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useAccount } from "../providers/AccountProvider";
import color from "../utils/color";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useSearchParams } from "react-router-dom";

export default function BottomBar() {
  const [value, setValue] = useState(0);
  const { user, unreadNotificationCount } = useAccount();

  useEffect(() => {
    const locationToValue = {
      "/": 0,
      "/how-it-works": 1,
      "/create": 2,
      "/notifications": 3,
    };
    if (user) {
      locationToValue[`/profile/${user._id}`] = 4;
    }
    setValue(locationToValue[window.location.pathname]);
  }, [user]);

  function NotificationIconWithCount({ count, style }) {
    return (
      <div style={{ position: "relative" }}>
        <NotificationsIcon style={style} />
        {count > 0 && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: color.red,
              borderRadius: "50%",
              width: "14px",
              height: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: color.textPrimary,
              fontSize: "10px",
            }}
          >
            {count}
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {window.innerWidth < 900 && (
        <>
          <div style={{ height: "84px", color: color.background }} />
          <Box
            style={{
              position: "fixed",
              width: "100%",
              bottom: 0,
              zIndex: 1000,
              paddingBottom: "20px",
              backgroundColor: color.background,
            }}
          >
            <BottomNavigation
              style={{ backgroundColor: color.background }}
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
                switch (newValue) {
                  case 0:
                    window.location.href = "/";
                    break;
                  case 1:
                    window.location.href = "/how-it-works";
                    break;
                  case 2:
                    window.location.href = user ? "/create" : "/login";
                    break;
                  case 3:
                    window.location.href = user ? `/notifications` : "/login";
                    break;
                  case 4:
                    window.location.href = user
                      ? `/profile/${user._id}`
                      : "/login";
                    break;
                  default:
                    break;
                }
              }}
            >
              <BottomNavigationAction
                style={{
                  color: value == 0 ? color.primary : color.textSecondary,
                }}
                label="Home"
                icon={
                  <HomeIcon
                    style={{
                      color: value == 0 ? color.primary : color.textSecondary,
                    }}
                  />
                }
              />
              <BottomNavigationAction
                style={{
                  color: value == 1 ? color.primary : color.textSecondary,
                }}
                label="Guide"
                icon={
                  <AutoStoriesIcon
                    style={{
                      color: value == 1 ? color.primary : color.textSecondary,
                    }}
                  />
                }
              />
              <BottomNavigationAction
                style={{
                  color: value == 2 ? color.primary : color.textSecondary,
                }}
                label="Create"
                icon={
                  <AddIcon
                    style={{
                      color: value == 2 ? color.primary : color.textSecondary,
                    }}
                  />
                }
              />
              <BottomNavigationAction
                style={{
                  color: value == 3 ? color.primary : color.textSecondary,
                }}
                label="Notification"
                icon={
                  <NotificationIconWithCount
                    count={unreadNotificationCount}
                    style={{
                      color: value == 3 ? color.primary : color.textSecondary,
                    }}
                  />
                }
              />
              <BottomNavigationAction
                style={{
                  color: value === 4 ? color.primary : color.textSecondary,
                }}
                label="Account"
                icon={
                  <PersonIcon
                    style={{
                      color: value === 4 ? color.primary : color.textSecondary,
                    }}
                  />
                }
              />
            </BottomNavigation>
          </Box>
        </>
      )}
    </>
  );
}
