import React, { useEffect, useRef, useState } from "react";
import color from "../utils/color";
import { Avatar, Button } from "@mui/material";
import Hunchies from "./Hunchies";
import { useAccount } from "../providers/AccountProvider";
import SellModal from "./SellModal";
import MultiMarket from "../utils/multiMarket";
import SingleMarket from "../utils/singleMarket";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const SingleMarketLeaderboard = ({ event, market, setShouldReload }) => {
  const [topThreeYes, setTopThreeYes] = useState([]);
  const [topThreeNo, setTopThreeNo] = useState([]);
  const [userYesVote, setUserYesVote] = useState(null);
  const [userNoVote, setUserNoVote] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [status, setStatus] = useState("");

  const { user } = useAccount();

  useEffect(() => {
    const yesVotes = event.votes.filter((vote) => vote.vote);
    const noVotes = event.votes.filter((vote) => !vote.vote);
    setTopThreeYes(yesVotes.sort((a, b) => b.amount - a.amount).slice(0, 3));
    setTopThreeNo(noVotes.sort((a, b) => b.amount - a.amount).slice(0, 3));
    if (user) {
      const userYesVote = yesVotes.find((vote) => vote.voter._id === user._id);
      if (userYesVote) {
        const multiMarket = new MultiMarket(
          event.markets.map(
            (market) =>
              new SingleMarket(market._id, market.y, market.n, market.k)
          )
        );
        const worth = multiMarket.sell(true, userYesVote.shares, market._id);
        setUserYesVote({ ...userYesVote, worth });
      } else {
        setUserYesVote(null);
      }
      const userNoVote = noVotes.find((vote) => vote.voter._id === user._id);
      if (userNoVote) {
        const multiMarket = new MultiMarket(
          event.markets.map(
            (market) =>
              new SingleMarket(market._id, market.y, market.n, market.k)
          )
        );
        const worth = multiMarket.sell(false, userNoVote.shares, market._id);
        setUserNoVote({ ...userNoVote, worth });
      } else {
        setUserNoVote(null);
      }
    }
    if (event.status) {
      setStatus(event.status);
    } else {
      if (event.processing) {
        setStatus("Processing");
      } else if (event.evidenceDescription) {
        setStatus("Resolved");
      } else {
        setStatus("Active");
      }
    }
  }, [event, market, user]);

  const sellModalRef = useRef();

  const showSellModal = () => {
    sellModalRef.current.show();
  };

  return (
    <>
      <div style={styles.container}>
        {userYesVote && (
          <div
            style={{
              color: color.textPrimary,
              backgroundColor: "rgb(72,187,120,0.2)",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
            onClick={() => {
              setSelectedOption(true);
            }}
          >
            <div>Your bet on Yes: </div>
            <div style={styles.userEntryStyle}>
              <span style={{ marginRight: "20px" }}>
                Payout: {userYesVote.shares.toFixed(1)}
                <Hunchies />
              </span>
              <span style={{ marginRight: "20px" }}>
                Spent: {userYesVote.amount.toFixed(1)}
                <Hunchies />
              </span>
              <span style={{ marginRight: "20px" }}>
                Worth: {userYesVote.worth.toFixed(1)}
                <Hunchies />
              </span>
              {status == "Active" && (
                <Button style={styles.sellButton} onClick={showSellModal}>
                  Sell
                </Button>
              )}
            </div>
          </div>
        )}
        {userNoVote && (
          <div
            style={{
              color: color.textPrimary,
              backgroundColor: "rgb(245,101,101,0.2)",
              padding: "10px",
              borderRadius: "5px",
            }}
            onClick={() => {
              setSelectedOption(false);
            }}
          >
            <div>Your bet on No: </div>
            <div style={styles.userEntryStyle}>
              <span style={{ marginRight: "20px" }}>
                Payout: {userNoVote.shares.toFixed(1)}
                <Hunchies />
              </span>
              <span style={{ marginRight: "20px" }}>
                Spent: {userNoVote.amount.toFixed(1)}
                <Hunchies />
              </span>
              <span style={{ marginRight: "20px" }}>
                Worth: {userNoVote.worth.toFixed(1)}
                <Hunchies />
              </span>
              {status == "Active" && (
                <Button style={styles.sellButton} onClick={showSellModal}>
                  Sell
                </Button>
              )}
            </div>
          </div>
        )}
        <SellModal
          ref={sellModalRef}
          event={event}
          market={market}
          setShouldReload={setShouldReload}
          selectedOption={selectedOption}
          userYesVote={userYesVote ? userYesVote.shares : 0}
          userNoVote={userNoVote ? userNoVote.shares : 0}
        />
        <h2 style={styles.title}>Leaderboard</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            borderBottom: "2px solid #14B8A6",
            paddingBottom: "10px",
            marginBottom: "10px",
            width: "100%",
          }}
        >
          <CheckIcon style={{ color: color.green }} />
          <CloseIcon style={{ color: color.red }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <div style={{ width: "50%" }}>
            {topThreeYes.length == 0 && (
              <div style={{ ...styles.entryStyle, opacity: 0 }}>
                No one has bet on Yes
              </div>
            )}
            {topThreeYes.map((vote, index) => (
              <div
                key={vote.voter._id}
                style={styles.entryStyle}
                onClick={() => {
                  window.location.href = `/profile/${vote.voter._id}`;
                }}
              >
                <span>{index + 1}.</span>
                <Avatar
                  style={styles.avatarStyle}
                  src={
                    vote.voter.profilePicture ? vote.voter.profilePicture : "/"
                  }
                  alt={vote.voter.name}
                />
                <span style={userYesVote == vote ? { color: color.green } : {}}>
                  {vote.voter.name}: {vote.amount.toFixed(1)}
                  <Hunchies />
                </span>
              </div>
            ))}
            {topThreeYes.length == 3 && (
              <div style={styles.entryStyle}>. . .</div>
            )}
          </div>
          <div style={{ width: "50%" }}>
            {topThreeNo.length == 0 && (
              <div style={{ ...styles.entryStyle, opacity: 0 }}>
                No one has bet on No
              </div>
            )}
            {topThreeNo.map((vote, index) => (
              <div
                key={vote.voter._id}
                style={styles.entryStyle}
                onClick={() => {
                  window.location.href = `/profile/${vote.voter._id}`;
                }}
              >
                <span>{index + 1}.</span>
                <Avatar
                  style={styles.avatarStyle}
                  src={
                    vote.voter.profilePicture ? vote.voter.profilePicture : "/"
                  }
                  alt={vote.voter.name}
                />
                <span style={userNoVote == vote ? { color: color.green } : {}}>
                  {vote.voter.name}: {vote.amount.toFixed(1)}
                  <Hunchies />
                </span>
              </div>
            ))}
            {topThreeNo.length == 3 && (
              <div style={styles.entryStyle}>. . .</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    margin: "10px",
    backgroundColor: color.secondary,
    padding: "20px",
    borderRadius: "8px",
  },
  title: {
    alignItems: "center",
    color: color.textPrimary,
    width: "100%",
    textAlign: "center",
  },
  entryStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
    color: color.textPrimary,
    cursor: "pointer",
  },
  userEntryStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avatarStyle: {
    margin: "0 10px",
  },
  sellButton: {
    backgroundColor: color.blue,
    color: color.textPrimary,
    border: "none",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "5px 10px",
  },
};

export default SingleMarketLeaderboard;
