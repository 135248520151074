const convertToPast = (time) => {
  const date = new Date(time);
  const difference = Date.now() - date.getTime();
  return difference < 60000
    ? Math.floor(difference / 1000) +
        (Math.floor(difference / 1000) > 1 ? " seconds ago" : " second ago")
    : difference < 3600000
    ? Math.floor(difference / 60000) +
      (Math.floor(difference / 60000) > 1 ? " minutes ago" : " minute ago")
    : difference < 86400000
    ? Math.floor(difference / 3600000) +
      (Math.floor(difference / 3600000) > 1 ? " hours ago" : " hour ago")
    : difference < 604800000
    ? Math.floor(difference / 86400000) +
      (Math.floor(difference / 86400000) > 1 ? " days ago" : " day ago")
    : difference < 2629800000
    ? Math.floor(difference / 604800000) +
      (Math.floor(difference / 604800000) > 1 ? " weeks ago" : " week ago")
    : difference < 31557600000
    ? Math.floor(difference / 2629800000) +
      (Math.floor(difference / 2629800000) > 1 ? " months ago" : " month ago")
    : Math.floor(difference / 31557600000) +
      (Math.floor(difference / 31557600000) > 1 ? " years ago" : " year ago");
};

const convertToString = (time) => {
  const date = new Date(time);
  return date.toLocaleString([], {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export { convertToPast, convertToString };
