import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAccount } from "../providers/AccountProvider";
import CommentSection from "../components/CommentSection";
import { convertToString, convertToPast } from "../utils/convertTime";
import color from "../utils/color";
import { Alert, Avatar, Divider } from "@mui/material";
import DeleteModal from "../components/DeleteModal";
import Loading from "../components/Loading";
import Deleted from "../components/Deleted";
import Page from "../components/Page";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import BasicMenu from "../components/BasicMenu";
import { Chip } from "@mui/material";
import SingleMarket from "../components/SingleMarket";
import ReportModal from "../components/ReportModal";
import MultiMarket from "../components/MultiMarket";
import { getEvent } from "../api/event";
import ReferModal from "../components/ReferModal";
import { useDispatch } from "react-redux";
import { updateCreatedEvent } from "../redux/createdEventsSlice";
import { updateExploreEvent } from "../redux/exploreEventsSlice";
import { updateFriendEvent } from "../redux/friendEventsSlice";
import { updateParticipatedEvent } from "../redux/participatedEventsSlice";
import ClaimPayoutModal from "../components/ClaimPayoutModal";
import HistoryModal from "../components/HistoryModal";
import { Helmet } from "react-helmet";
import { updateTopicEvent } from "../redux/topicEventsSlice";

const EventPage = () => {
  const { id } = useParams();
  const [event, setEvent] = useState({});
  const [deleted, setDeleted] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [markets, setMarkets] = useState([]);
  const [result, setResult] = useState({});
  const [status, setStatus] = useState("");

  const navigate = useNavigate();
  const { user } = useAccount();
  const creatorSettings = ["Edit", "History", "Delete", "Share"];
  const viewerSettings = ["History", "Report", "Share"];

  const fetchEvent = async () => {
    const result = await getEvent(id);
    setEvent(result.data);
    setMarkets(result.data.markets);
    setDeleted(result.data.deleted);
    if (result.data.evidenceDescription) {
      setResult({
        description: result.data.markets.find(
          (market) => market._id === result.data.result.market
        ).description,
        result: result.data.result.result,
      });
    }
    if (result.data.status) {
      setStatus(result.data.status);
    } else {
      if (result.data.processing) {
        setStatus("Processing");
      } else if (result.data.evidenceDescription) {
        setStatus("Resolved");
      } else {
        setStatus("Active");
      }
    }
    document.title = result.data.title;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    fetchEvent().then(() => setLoading(false));
    return () => {
      document.title = "HunchPot";
    };
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!shouldReload) return;
    fetchEvent().then((event) => {
      try {
        dispatch(updateCreatedEvent(event));
        dispatch(updateParticipatedEvent(event));
        dispatch(updateExploreEvent(event));
        dispatch(updateFriendEvent(event));
        dispatch(updateTopicEvent(event));
      } catch (error) {
        console.log(error);
      }
      setShouldReload(false);
    });
  }, [shouldReload]);

  useEffect(() => {
    if (
      !shouldReload &&
      status == "Resolved" &&
      event &&
      user &&
      event.votes.find(
        (vote) =>
          vote.voter._id === user._id &&
          vote.market === event.result.market &&
          vote.vote === event.result.result &&
          !vote.payoutSent &&
          vote.shares > 0
      )
    ) {
      showClaimPayoutModal();
    }
  }, [status, user, event, shouldReload]);

  const deleteModalRef = useRef();

  const showDeleteModal = () => {
    deleteModalRef.current.show();
  };

  const reportModalRef = useRef();

  const showReportModal = () => {
    reportModalRef.current.show();
  };

  const handleSettingClick = (setting) => {
    switch (setting) {
      case "Edit":
        navigate(`/edit/${event._id}`);
        break;
      case "Delete":
        showDeleteModal();
        break;
      case "Share":
        showReferModal();
        break;
      case "Report":
        showReportModal();
        break;
      case "History":
        showHistoryModalRef();
        break;
      default:
        break;
    }
  };

  const referModalRef = useRef();

  const showReferModal = () => {
    referModalRef.current.show();
  };

  const claimPayoutModalRef = useRef();

  const showClaimPayoutModal = () => {
    claimPayoutModalRef.current.show();
  };

  const historyModalRef = useRef();

  const showHistoryModalRef = () => {
    historyModalRef.current.show();
  };

  return (
    <>
      <Helmet>
        <title>{event.title}</title>
        <meta property="og:title" content={event.title} />
        <meta property="og:description" content={event.description} />
        <meta property="og:image" content={event.image} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <ReferModal ref={referModalRef} />
      <HistoryModal ref={historyModalRef} event={event} />
      <ClaimPayoutModal
        ref={claimPayoutModalRef}
        event={event}
        setShouldReload={setShouldReload}
      />
      {loading && <Loading />}
      <Page style={styles.app}>
        {alert && (
          <div
            style={{
              position: "fixed",
              top: 0,
              width: window.innerWidth < 900 ? "100%" : 900,
              zIndex: 100000,
            }}
          >
            <Alert onClose={() => setAlert("")} severity="success">
              {alert}
            </Alert>
          </div>
        )}
        {deleted ? (
          <Deleted event={event} />
        ) : (
          <>
            <DeleteModal ref={deleteModalRef} event={event} />
            <ReportModal ref={reportModalRef} event={event} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "space-between",
                margin: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(
                      `/profile/${event.creator ? event.creator._id : ""}`
                    )
                  }
                >
                  <Avatar
                    style={{ margin: "10px", width: "40px", height: "40px" }}
                    alt={event.creator ? event.creator.name : ""}
                    src={
                      event.creator && event.creator.profilePicture
                        ? event.creator.profilePicture
                        : "/"
                    }
                  />
                  <h5 style={styles.infoText}>
                    {event.creator ? event.creator.name : ""}
                  </h5>
                  {event.creator && event.creator.verified && (
                    <img
                      src={require("../assets/images/verified.png")}
                      alt="Verified"
                      style={{
                        marginLeft: "-15px",
                        height: "30px",
                        alignSelf: "center",
                      }}
                    />
                  )}
                </div>
                <h5
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    color: color.textSecondary,
                  }}
                >
                  {convertToPast(event.createdAt)}
                </h5>
              </div>
              <BasicMenu
                settings={
                  user &&
                  event.creator &&
                  (user.isAdmin || user._id === event.creator._id)
                    ? creatorSettings
                    : viewerSettings
                }
                handleSettingClick={handleSettingClick}
              />
            </div>
            <h2 style={{ ...styles.title, marginTop: "0px" }}>
              {event.title + " "}
              <Chip
                size="small"
                style={{
                  backgroundColor:
                    status === "Processing"
                      ? color.orange
                      : status === "Resolved"
                      ? color.primary
                      : color.green,
                  color: color.textPrimary,
                }}
                label={status}
              />
              {event.topics &&
                event.topics.map((topic) => (
                  <Chip
                    size="small"
                    style={{
                      backgroundColor: color.secondary,
                      color: color.textPrimary,
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(
                        `/search?topic=${topic.name}&topicId=${topic._id}`
                      )
                    }
                    label={topic.name}
                  />
                ))}
            </h2>
            {event.image && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <img
                  src={event.image}
                  style={{
                    width: "80%",
                    maxHeight: window.innerWidth < 600 ? "400px" : "600px",
                    objectFit: "contain",
                  }}
                  alt="preview"
                />
              </div>
            )}
            <p style={styles.description}>{event.description}</p>

            <p style={{ ...styles.description, fontSize: "14px" }}>
              Rule: <b>{event.validation}</b> by {convertToString(event.endsAt)}
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              {markets.length > 1 ? (
                <MultiMarket event={event} setShouldReload={setShouldReload} />
              ) : (
                <SingleMarket event={event} setShouldReload={setShouldReload} />
              )}
            </div>
            {status == "Resolved" ? (
              <div>
                <div
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  **NOTE** This pot has ended, next section is the market
                  resolution
                </div>
                <Divider
                  style={{
                    backgroundColor: color.textPrimary,
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                />
                <h2 style={styles.title}>Market Resolution</h2>
                <p
                  style={{
                    ...styles.description,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  The market resolves to{" "}
                  {event.markets && event.markets.length > 1
                    ? result.description
                    : result.result
                    ? "Yes"
                    : "No"}{" "}
                  {result.result ? (
                    <CheckIcon style={{ color: color.green }} />
                  ) : (
                    <CloseIcon style={{ color: color.red }} />
                  )}
                </p>
                <h3 style={styles.title}>
                  The evidence provided by {event.creator.name}:
                </h3>
                {event.evidenceImage && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={event.evidenceImage}
                      style={{
                        width: "80%",
                        maxHeight: window.innerWidth < 600 ? "400px" : "600px",
                        objectFit: "contain",
                      }}
                      alt="preview"
                    />
                  </div>
                )}
                <p style={styles.description}>{event.evidenceDescription}</p>
              </div>
            ) : status == "Processing" ? (
              <div
                style={{
                  marginBottom: "20px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                {event.creator && user && event.creator._id === user._id ? (
                  <div>
                    **NOTE** This pot has ended, you can resolve the market{" "}
                    <a href={`/resolve/${event._id}`}>here</a>
                  </div>
                ) : (
                  <div>
                    **NOTE** This pot has ended, waiting for OP to resolve
                    market
                  </div>
                )}
              </div>
            ) : event.creator && user && event.creator._id === user._id ? (
              <div
                style={{
                  marginBottom: "20px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div>
                  **NOTE** This pot is still ongoing, but you can resolve the
                  market <a href={`/resolve/${event._id}`}>here</a>
                </div>
              </div>
            ) : null}
            <CommentSection
              comments={event.comments}
              eventId={event._id}
              setShouldReload={setShouldReload}
            />
          </>
        )}
      </Page>
    </>
  );
};

const styles = {
  app: {
    textAlign: "left",
    backgroundColor: color.background,
    color: color.textSecondary,
  },
  title: {
    margin: "20px",
    overflowWrap: "break-word",
  },
  betButton: {
    padding: "10px 20px",
    border: "none",
    cursor: "pointer",
    marginBottom: "20px",
    marginLeft: "20px",
    marginRight: "20px",
  },
  description: {
    overflowWrap: "break-word",
    fontSize: "16px",
    marginBottom: "20px",
    marginLeft: "20px",
    marginRight: "20px",
  },
};

export default EventPage;
