import axios from "axios";
import baseUrl from "./baseUrl";

const getNotifications = async (lastId, amount) => {
  return await axios.get(
    `${await baseUrl()}/notification/getNotifications?lastId=${
      lastId ? lastId : ""
    }&amount=${amount}`,
    {
      withCredentials: true,
    }
  );
};

const getUnreadNotificationCount = async () => {
  return await axios.get(
    `${await baseUrl()}/notification/getUnreadNotificationCount`,
    {
      withCredentials: true,
    }
  );
};

const subscribe = async (subscription) => {
  return await axios.post(
    `${await baseUrl()}/notification/subscribe`,
    { subscription },
    {
      withCredentials: true,
    }
  );
};

const unsubscribe = async (subscriptionEndpoint) => {
  return await axios.post(
    `${await baseUrl()}/notification/unsubscribe`,
    { subscriptionEndpoint },
    {
      withCredentials: true,
    }
  );
};

export { getNotifications, getUnreadNotificationCount, subscribe, unsubscribe };
