import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getFriendsEvents } from "../api/user";

export const fetchFriendEvents = createAsyncThunk(
  "friendEvents/fetch",
  async ({ lastId, amount }) => {
    const response = await getFriendsEvents(lastId, amount);
    return response.data;
  }
);

const friendEventsSlice = createSlice({
  name: "friendEvents",
  initialState: { events: [], status: "idle", error: null },
  reducers: {
    updateFriendEvent: (state, action) => {
      const index = state.events.findIndex(
        (event) => event._id === action.payload._id
      );
      if (index !== -1) {
        state.events[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFriendEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFriendEvents.fulfilled, (state, action) => {
        if (action.payload.length === 0) {
          state.status = "finished";
          return;
        }
        state.status = "succeeded";
        state.events = state.events
          .concat(action.payload)
          .filter((value, index, array) => {
            return (
              array.findIndex((elements) => value._id === elements._id) ===
              index
            );
          });
      })
      .addCase(fetchFriendEvents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { updateFriendEvent } = friendEventsSlice.actions;

export default friendEventsSlice.reducer;
