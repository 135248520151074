import axios from "axios";
import baseUrl from "./baseUrl";

const getPopularTopics = async () => {
  return await axios.get(`${await baseUrl()}/topic/getPopularTopics`);
};

const searchTopics = async (searchTerm) => {
  return await axios.get(
    `${await baseUrl()}/topic/search?searchTerm=${searchTerm}`,
    {
      withCredentials: true,
    }
  );
};

const createTopic = async (name) => {
  return await axios.post(
    `${await baseUrl()}/topic/create`,
    { name },
    {
      withCredentials: true,
    }
  );
};

export { getPopularTopics, searchTopics, createTopic };
