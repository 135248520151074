import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { LoginButton, useFacebook } from "react-facebook";
import VerifyModal from "./VerifyModal";
import color from "../utils/color";
import { useAccount } from "../providers/AccountProvider";
import { Divider } from "@mui/material";
import AddPhoneModal from "./AddPhoneModal";
import { facebookSignup, phoneSignup } from "../api/user";
import Button from "@mui/material/Button";

const SignupModal = forwardRef((props, ref) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState(false);
  const { login } = useAccount();

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!phone) {
      setError("Please enter phone");
    }
    if (!name) {
      setError("Please enter name");
    }
    if (error) {
      return;
    }
    try {
      const referrer = localStorage.getItem("referrer");
      const result = await phoneSignup(name, phone, referrer);
      if (result.data.loggedIn) {
        setShowModal(false);
        verifyModalRef.current.show();
      } else {
        setError("Server error, please try again later.");
      }
    } catch (error) {
      setError(error.response.data);
    }
  };

  const { init } = useFacebook();

  async function handleFbSuccess(response) {
    const api = await init();
    const FB = await api.getFB();
    FB.api("/me", function (response) {
      const facebook = response;
      FB.api(
        `/${facebook.id}/picture?redirect=false&width=9999`,
        "GET",
        {},
        async function (response) {
          const referrer = localStorage.getItem("referrer");
          try {
            const result = await facebookSignup(
              {
                ...facebook,
                profilePicture: response.data.url,
              },
              referrer
            );
            const needsPhone = result.data.needsPhone;
            setShowModal(false);
            if (needsPhone) {
              setToken(result.data.token);
              addPhoneModalRef.current.show();
            } else {
              await login(result.data.token);
            }
          } catch (error) {
            setError(error.response.data);
          }
        }
      );
    });
  }

  function handleFbError(error) {
    setError(error);
  }

  const [showModal, setShowModal] = useState(false);

  const show = () => {
    setShowModal(true);
  };

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  const verifyModalRef = useRef();
  const addPhoneModalRef = useRef();

  return (
    <>
      <AddPhoneModal ref={addPhoneModalRef} token={token} />
      <VerifyModal ref={verifyModalRef} phone={phone} />
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000000,
          }}
          onClick={() => setShowModal(false)}
        >
          <div
            style={{
              backgroundColor: color.secondary,
              padding: "20px",
              borderRadius: "8px",
              color: color.textSecondary,
              width: "80%",
              maxWidth: "500px",
              margin: "auto",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2
              style={{
                marginBottom: "20px",
                color: color.textSecondary,
                textAlign: "center",
              }}
            >
              Sign Up to Claim $10
            </h2>
            <div style={{ color: "red" }}>{error}</div>
            <form onSubmit={handleLogin}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    marginBottom: "10px",
                    color: color.textSecondary,
                    textTransform: "uppercase",
                    fontSize: "12px",
                    letterSpacing: "1px",
                  }}
                >
                  Name:
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setError("");
                  }}
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    width: "100%",
                    borderRadius: "5px",
                    border: "none",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    boxSizing: "border-box",
                    marginBottom: "20px",
                  }}
                />
              </div>
              <div
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    marginBottom: "10px",
                    color: color.textSecondary,
                    textTransform: "uppercase",
                    fontSize: "12px",
                    letterSpacing: "1px",
                  }}
                >
                  Phone:
                </label>
                <input
                  type="tel"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setError("");
                  }}
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    width: "100%",
                    borderRadius: "5px",
                    border: "none",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    boxSizing: "border-box",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  style={{
                    backgroundColor: color.green,
                    color: color.textPrimary,
                    padding: "10px 20px",
                    fontSize: "16px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Continue
                </Button>
                <Divider
                  style={{
                    margin: "20px 0",
                    color: color.textSecondary,
                  }}
                >
                  OR
                </Divider>
                <LoginButton
                  style={{
                    backgroundColor: color.facebook,
                    color: color.textPrimary,
                    padding: "10px 20px",
                    fontSize: "16px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  scope="public_profile, email, user_friends"
                  onError={handleFbError}
                  onSuccess={handleFbSuccess}
                >
                  Sign Up with Facebook
                </LoginButton>
              </div>
              <br />
            </form>
          </div>
        </div>
      )}
    </>
  );
});

export default SignupModal;
