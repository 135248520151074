import React, { useEffect, useState } from "react";
import { useAccount } from "../providers/AccountProvider";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { convertToString } from "../utils/convertTime";
import color from "../utils/color";
import Page from "../components/Page";
import CommentBox from "../components/CommentBox";
import { getEvent, submitEvidence } from "../api/event";
import Button from "@mui/material/Button";
import Hunchies from "../components/Hunchies";

const ResolvePage = () => {
  const { id } = useParams();
  const [event, setEvent] = useState({});
  const [evidenceImage, setEvidenceImage] = useState(null);
  const [evidenceDescription, setEvidenceDescription] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [resultMarket, setResultMarket] = useState(null);
  const [missingDescription, setMissingDescription] = useState("");

  const { user, updateUser, sessionLoading } = useAccount();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!evidenceDescription) {
      setMissingDescription("This is a required section");
      return;
    }
    await submitEvidence(
      id,
      resultMarket._id,
      result,
      evidenceImage,
      evidenceDescription
    );
    await updateUser();
    navigate(`/event/${id}`);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getEvent(id);
      setEvent(result.data);
      setTotalAmount(result.data.votes.reduce((a, b) => a + b.amount, 0));
      setLoading(false);
    })();
  }, [id]);

  useEffect(() => {
    if (!sessionLoading && !user) {
      navigate("/login");
      return;
    }
    if (user && event.creator && user._id !== event.creator._id) {
      navigate(`/event/${id}`);
    }
  }, [user, sessionLoading, event]);

  return (
    <>
      {loading && <Loading />}
      <Page>
        {!event.evidenceDescription ? (
          <div
            style={{
              ...styles.postContainer,
              marginTop: window.innerWidth < 600 ? "0px" : "50px",
              marginBottom: window.innerWidth < 600 ? "0px" : "50px",
            }}
          >
            <h2 style={styles.centeredText}>Resolve Your Hunch Pot</h2>
            <div style={styles.inputGroup}>
              <label style={styles.blockDisplay}>Title:</label>
              <p>{event.title}</p>
            </div>
            <div style={styles.inputGroup}>
              <label style={styles.blockDisplay}>Description:</label>
              <p>{event.description}</p>
            </div>
            <div style={styles.inputGroup}>
              <label style={styles.blockDisplay}>Rules:</label>
              <p>{event.validation}</p>
            </div>
            <div style={styles.inputGroup}>
              <label style={styles.blockDisplay}>Deadline:</label>
              <p>{event.endsAt ? convertToString(event.endsAt) : ""}</p>
            </div>
            <div style={styles.inputGroup}>
              <label style={styles.blockDisplay}>Result:</label>
              {event.markets && event.markets.length > 1 ? (
                <div style={styles.ButtonContainer}>
                  {event.markets.map((market, i) => (
                    <Button
                      key={i}
                      style={{
                        ...styles.marketButton,
                        backgroundColor: color.multipleChoice[i],
                        opacity: resultMarket === market ? 1 : 0.5,
                      }}
                      onClick={() => {
                        setResultMarket(market);
                        setResult(true);
                      }}
                    >
                      {market.description}
                    </Button>
                  ))}
                </div>
              ) : (
                <div style={styles.ButtonContainer}>
                  <Button
                    style={{
                      ...styles.betButton,
                      ...styles.betYes,
                      opacity: result === true ? 1 : 0.5,
                    }}
                    onClick={() => {
                      setResultMarket(event.markets[0]);
                      setResult(true);
                    }}
                  >
                    YES
                  </Button>
                  <Button
                    style={{
                      ...styles.betButton,
                      ...styles.betNo,
                      opacity: result === false ? 1 : 0.5,
                    }}
                    onClick={() => {
                      setResultMarket(event.markets[0]);
                      setResult(false);
                    }}
                  >
                    NO
                  </Button>
                </div>
              )}
            </div>
            {result != null && (
              <div style={styles.inputGroup}>
                <label style={styles.blockDisplay}>
                  Validation Evidence (
                  {event.markets && event.markets.length > 1
                    ? resultMarket.description
                    : result
                    ? "YES"
                    : "NO"}
                  ):
                </label>
                <label style={styles.blockDisplay}>
                  Image (Strongly Recommend)
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setEvidenceImage(e.target.files[0])}
                />
                <br />
                <br />
                {evidenceImage && (
                  <img
                    src={URL.createObjectURL(evidenceImage)}
                    style={{
                      width: "100%",
                      height: "400px",
                      objectFit: "contain",
                    }}
                    alt="preview"
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <label
                    style={{
                      ...styles.blockDisplay,
                      width: "100%",
                      textAlign: "flex-start",
                    }}
                  >
                    Description<span style={{ color: color.red }}>*</span>{" "}
                    <div style={styles.errorText}>{missingDescription}</div>
                  </label>
                  <CommentBox
                    style={{ width: "90%" }}
                    value={evidenceDescription}
                    onChange={(e) => setEvidenceDescription(e.target.value)}
                  />
                </div>
              </div>
            )}
            <div>
              <label style={styles.blockDisplay}>Awards</label>
              <p>
                You get {(totalAmount * 0.01).toFixed(2)}
                <Hunchies /> (1% of total traded volume)
              </p>
            </div>
            {!evidenceDescription ? (
              <Button disabled style={styles.disabledButton}>
                Please Enter Result and Submit Evidence
              </Button>
            ) : (
              <Button onClick={handleSubmit} style={styles.Button}>
                Resolve Your Hunch Pot
              </Button>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              position: "fixed",
              x: 0,
              y: 0,
              backgroundColor: color.background,
              height: "100%",
              width: window.innerWidth < 1535 ? "100%" : 1535,
              justifyContent: "center",
              alignItems: "center",
              color: color.textSecondary,
              marginTop: -64,
              marginBottom: -64,
            }}
          >
            This event has been resolved already.
          </div>
        )}
      </Page>
    </>
  );
};

const styles = {
  postContainer: {
    padding: "20px",
    color: color.textSecondary,
    borderRadius: "8px",
    margin: "auto",
    backgroundColor: color.secondary,
  },
  centeredText: {
    textAlign: "center",
    marginBottom: "20px",
  },
  inputGroup: {
    marginBottom: "20px",
  },
  blockDisplay: {
    display: "block",
    marginBottom: "10px",
  },
  input: {
    padding: "10px",
    width: "100%",
    borderRadius: "5px",
    border: "none",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    marginBottom: "20px",
  },
  Button: {
    display: "block",
    width: "100%",
    padding: "10px",
    backgroundColor: color.blue,
    color: color.textPrimary,
    border: "none",
    cursor: "pointer",
    borderRadius: "4px",
    marginBottom: "20px",
  },
  disabledButton: {
    display: "block",
    width: "100%",
    padding: "10px",
    backgroundColor: "grey",
    color: color.textPrimary,
    border: "none",
    borderRadius: "4px",
  },
  betButton: {
    padding: "10px 20px",
    margin: "5px",
    border: "none",
    cursor: "pointer",
    color: color.textPrimary,
  },
  betYes: {
    backgroundColor: color.green,
  },
  betNo: {
    backgroundColor: color.red,
  },
  ButtonContainer: {
    marginBottom: "20px",
  },
  marketButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    color: color.textPrimary,
  },
  errorText: {
    color: color.red,
    fontSize: "12px",
    marginTop: "5px",
  },
};

export default ResolvePage;
