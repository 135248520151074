import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS
import CommentBox from "../components/CommentBox";
import { useNavigate } from "react-router-dom";
import color from "../utils/color";
import Page from "../components/Page";
import Hunchies from "../components/Hunchies";
import ChoicesInput from "../components/ChoicesInput";
import { Button, ButtonGroup, FormControlLabel, Switch } from "@mui/material";
import { createEvent } from "../api/event";
import { useAccount } from "../providers/AccountProvider";
import TopicInput from "../components/TopicInput";

const CreatePage = () => {
  const [title, setTitle] = useState("");
  const [topics, setTopics] = useState([]);
  const [description, setDescription] = useState("");
  const [validation, setValidation] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [deadline, setDeadline] = useState(
    new Date().setDate(new Date().getDate() + 1)
  );
  const [loading, setLoading] = useState(false);
  const [multipleChoice, setMultipleChoice] = useState(false);
  const [marketDescriptions, setMarketDescriptions] = useState(["", ""]);
  const [selectedTime, setSelectedTime] = useState("day");
  const [errors, setErrors] = useState({
    title: "",
    topics: "",
    description: "",
    validation: "",
    imageFile: "",
    deadline: "",
    lessThanTwoChoices: "",
  });
  const [publiclyListed, setPubliclyListed] = useState(true);
  const { user, sessionLoading } = useAccount();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    let newErrors = {
      title: "",
      description: "",
      validation: "",
      imageFile: "",
      deadline: "",
      lessThanTwoChoices: "",
    };

    if (!title) newErrors.title = "This is a required section";
    if (!topics.length) newErrors.topics = "This is a required section";
    if (!validation) newErrors.validation = "This is a required section";
    if (!deadline) newErrors.deadline = "This is a required section";

    if (
      multipleChoice &&
      (!marketDescriptions ||
        marketDescriptions.length < 2 ||
        marketDescriptions.some((desc) => !desc))
    ) {
      newErrors.lessThanTwoChoices =
        "Please provide at least TWO choices for multiple choice questions, choices cannot be empty!";
    }

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      setLoading(true);
      if (multipleChoice) {
        await createEvent(
          title,
          topics,
          description,
          marketDescriptions,
          validation,
          deadline,
          imageFile,
          publiclyListed
        );
      } else {
        await createEvent(
          title,
          topics,
          description,
          [""],
          validation,
          deadline,
          imageFile,
          publiclyListed
        );
      }
      setLoading(false);
      navigate("/");
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (!sessionLoading && !user) {
      navigate("/login");
      return;
    }
  }, [user, sessionLoading]);

  return (
    <Page>
      <div
        style={{
          ...styles.postContainer,
          marginTop: window.innerWidth < 600 ? "0px" : "50px",
          width: window.innerWidth < 600 ? "100%" : "60%",
          height: window.innerWidth < 600 ? "100%" : "60%",
          boxSizing: "border-box",
        }}
      >
        <h2 style={styles.centeredText}>Create a Hunch Pot</h2>
        <div style={styles.inputGroup}>
          <label style={styles.blockDisplay}>
            Title <span style={{ color: color.red }}>*</span>{" "}
            <div style={styles.errorText}>{errors.title}</div>
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.blockDisplay}>
            Topics <span style={{ color: color.red }}>*</span>{" "}
            <div style={styles.errorText}>{errors.topics}</div>
          </label>
          <TopicInput topics={topics} setTopics={setTopics} />
        </div>
        <div style={styles.inputGroup}>
          <FormControlLabel
            onChange={(e) => setMultipleChoice(e.target.checked)}
            value={multipleChoice}
            control={<Switch />}
            label="Multiple Choice"
          />
          {multipleChoice && (
            <>
              <label style={styles.blockDisplay}>
                Choices:{" "}
                <div style={styles.errorText}>{errors.lessThanTwoChoices}</div>
              </label>
              <ChoicesInput
                choices={marketDescriptions}
                setChoices={setMarketDescriptions}
              />
            </>
          )}
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.blockDisplay}>Description</label>
          <CommentBox
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.blockDisplay}>
            Rules<span style={{ color: color.red }}>*</span>{" "}
            <div style={styles.errorText}>{errors.validation}</div>
          </label>
          <CommentBox
            value={validation}
            onChange={(e) => setValidation(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.blockDisplay}>
            Cover Photo (Strongly Recommended)
            <div style={styles.errorText}>{errors.imageFile}</div>
          </label>
          <input
            type="file"
            accept="image/png, image/jpeg"
            onChange={(e) => setImageFile(e.target.files[0])}
          />
          <br />
          <br />
          {imageFile && (
            <img
              src={URL.createObjectURL(imageFile)}
              style={{ width: "100%", height: "400px", objectFit: "contain" }}
              alt="preview"
            />
          )}
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.blockDisplay}>
            Deadline<span style={{ color: color.red }}>*</span>{" "}
            <div style={styles.errorText}>{errors.deadline}</div>
          </label>
          <ButtonGroup
            style={{ marginBottom: "20px" }}
            variant="outlined"
            aria-label="outlined Button group"
          >
            <Button
              onClick={() => {
                const date = new Date();
                date.setHours(date.getHours() + 1);
                setDeadline(date);
                setSelectedTime("hour");
              }}
              style={
                selectedTime == "hour"
                  ? styles.dateButtonSelected
                  : styles.dateButton
              }
            >
              A hour
            </Button>
            <Button
              onClick={() => {
                const date = new Date();
                date.setDate(date.getDate() + 1);
                setDeadline(date);
                setSelectedTime("day");
              }}
              style={
                selectedTime == "day"
                  ? styles.dateButtonSelected
                  : styles.dateButton
              }
            >
              A day
            </Button>
            <Button
              onClick={() => {
                const date = new Date();
                date.setDate(date.getDate() + 7);
                setDeadline(date);
                setSelectedTime("week");
              }}
              style={
                selectedTime == "week"
                  ? styles.dateButtonSelected
                  : styles.dateButton
              }
            >
              A week
            </Button>
            <Button
              onClick={() => {
                const date = new Date();
                date.setDate(date.getDate() + 30);
                setDeadline(date);
                setSelectedTime("month");
              }}
              style={
                selectedTime == "month"
                  ? styles.dateButtonSelected
                  : styles.dateButton
              }
            >
              A month
            </Button>
          </ButtonGroup>
          <DatePicker
            selected={deadline}
            onChange={(date) => {
              setDeadline(date);
              setSelectedTime("");
            }}
            dateFormat="MMMM d, yyyy h:mm aa"
            showTimeInput
            minDate={new Date()}
          />
        </div>
        <div style={styles.inputGroup}>
          <FormControlLabel
            onChange={(e) => setPubliclyListed(e.target.checked)}
            value={publiclyListed}
            control={<Switch checked={publiclyListed} />}
            label="Publicly Listed"
          />
          {!publiclyListed && (
            <p>
              **NOTE** This Hunch Pot will only appear for your friends in the
              friends tab
            </p>
          )}
        </div>
        <div>
          <label style={styles.blockDisplay}>Awards</label>
          <p>
            You get 1% of total traded volume (max 500
            <Hunchies />)
          </p>
        </div>
        <Button
          onClick={loading ? () => {} : handleSubmit}
          style={loading ? styles.loadingButton : styles.Button}
        >
          {loading ? "Loading..." : "Create Your Hunch Pot"}
        </Button>
      </div>
    </Page>
  );
};

const styles = {
  errorText: {
    color: color.red,
    fontSize: "12px",
    marginTop: "5px",
  },
  postContainer: {
    padding: "20px",
    color: color.textSecondary,
    borderRadius: "8px",
    margin: "auto",
    backgroundColor: color.secondary,
  },
  centeredText: {
    textAlign: "center",
    marginBottom: "20px",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  blockDisplay: {
    display: "block",
    marginBottom: "10px",
  },
  input: {
    padding: "10px",
    width: "100%",
    borderRadius: "5px",
    border: "none",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    marginBottom: "20px",
  },
  Button: {
    display: "block",
    width: "100%",
    padding: "10px",
    backgroundColor: "#14B8A6",
    color: color.textPrimary,
    border: "none",
    cursor: "pointer",
    borderRadius: "4px",
  },
  loadingButton: {
    display: "block",
    width: "100%",
    padding: "10px",
    backgroundColor: color.textSecondary,
    color: color.textPrimary,
    border: "none",
    cursor: "disabled",
    borderRadius: "4px",
  },
  ButtonHover: {
    backgroundColor: "#12A89D",
  },
  dateButton: {
    color: color.textSecondary,
    borderColor: color.textSecondary,
  },
  dateButtonSelected: {
    backgroundColor: color.primary,
    color: color.textPrimary,
    borderColor: color.textSecondary,
  },
  datePickerWrapper: {
    width: "100%",
    padding: "10px",
    border: "none",
    backgroundColor: "#4A5568",
    color: color.textSecondary,
    borderRadius: "4px",
  },
};

export default CreatePage;
