import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useAccount } from "../providers/AccountProvider";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Alert, Box, Tab } from "@mui/material";
import color from "../utils/color";
import Hunchies from "./Hunchies";
import Select from "react-select";
import { cashIn, cashOut } from "../api/user";
import Button from "@mui/material/Button";

const PayModal = forwardRef((props, ref) => {
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState("");

  const show = () => {
    setShowModal(true);
  };

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  const [amount, setAmount] = useState(0);
  const stripe = useStripe();
  const elements = useElements();
  const { updateUser } = useAccount();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [paymentType, setPaymentType] = useState({});

  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "HunchPot",
        amount: amount * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result.applePay) {
        setPaymentRequest(pr);
      }
    });

    pr.on("paymentmethod", async (e) => {
      await handleBuy(e);
      e.complete("success");
    });
  }, [stripe, elements]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.update({
        total: {
          label: "HunchPot",
          amount: amount * 100,
        },
      });
    }
    setPaymentRequest(paymentRequest);
  }, [amount]);

  const paymentTypeRef = useRef();
  paymentTypeRef.current = paymentType;

  const amountRef = useRef();
  amountRef.current = amount;

  const handleBuy = async (e) => {
    if (e.preventDefault) e.preventDefault();
    const paymentType = paymentTypeRef.current;
    const amount = amountRef.current;
    if (amount < 1) {
      setError("Please enter a amount greater than $1");
      return;
    }
    let paymentId;
    if (paymentType.value === "card") {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      if (error) {
        setError(error.message);
        return;
      }
      paymentId = paymentMethod.id;
    } else if (paymentType.value === "applePay") {
      paymentId = e.paymentMethod.id;
    } else {
      setError("Invalid payment method.");
      return;
    }
    setLoading(true);
    try {
      await cashIn(amount, paymentId);
      await updateUser();
      setShowModal(false);
      setAlert(`Successfully added ${amount * 100} Hunchies`);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
    }

    setLoading(false);
    setTimeout(() => {
      setAlert("");
    }, 3000);
    return;
  };

  const [eventType, setEventType] = useState("Buy Hunchies");

  const handleChange = (event, newValue) => {
    setError("");
    setEventType(newValue);
  };

  function handleAmountChange(e) {
    setError("");
    const { value } = e.target;
    let newValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    // Handle leading zeroes
    if (newValue.startsWith("0")) {
      newValue = String(parseInt(newValue, 10) || "");
    }

    setAmount(newValue);
  }

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: color.primary,
        color: color.textPrimary,
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: color.primary },
        "::placeholder": { color: color.textSecondary },
      },
      invalid: {
        iconColor: color.red,
        color: color.red,
      },
    },
  };

  const [payoutInfo, setPayoutInfo] = useState("");
  const [payoutType, setPayoutType] = useState({});

  const paymentInfoText = (payoutType) => {
    {
      switch (payoutType) {
        case "venmo":
          return "Venmo ID";
        case "zelle":
          return "Zelle #";
        case "paypal":
          return "Paypal Email";
        default:
          return "Please choose valid payout method";
      }
    }
  };

  const handleRedeem = async () => {
    if (amount < 1) {
      setError("Please enter a amount greater than $1");
      return;
    }
    if (!payoutInfo) {
      setError(`Please enter valid payout info for ${payoutType.label}`);
      return;
    }
    setLoading(true);
    try {
      await cashOut(amount, { method: payoutType.label, info: payoutInfo });
      await updateUser();
      setShowModal(false);
      setAlert(
        `Your redeem request Hunchies will be processed in 1-2 business days`
      );
    } catch (error) {
      console.log(error);
      setError(error.response.data);
    }
    setLoading(false);
    setTimeout(() => {
      setAlert("");
    }, 3000);
  };

  return (
    <>
      {alert && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            zIndex: 1000000,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(false);
          }}
        >
          <div
            style={{
              position: "fixed",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000000,
            }}
          >
            <div
              style={{
                width: window.innerWidth < 900 ? "100%" : 900,
              }}
            >
              <Alert onClose={() => setAlert("")} severity="success">
                {alert}
              </Alert>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <div
          style={styles.overlay}
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(false);
          }}
        >
          <div style={styles.modal} onClick={(e) => e.stopPropagation()}>
            <TabContext value={eventType}>
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  textColor="inherit"
                  centered
                >
                  <Tab
                    style={{ color: color.textSecondary }}
                    label="Buy Hunchies"
                    value="Buy Hunchies"
                  />
                  <Tab
                    style={{ color: color.textSecondary }}
                    label="Redeem for Cash"
                    value="Redeem for Cash"
                  />
                </TabList>
              </Box>
              {error && (
                <div
                  style={{ color: color.red, margin: "20px", marginBottom: 0 }}
                >
                  {error}
                </div>
              )}
              <TabPanel value="Buy Hunchies">
                <div style={styles.inputContainer}>
                  <label>Amount ($):</label>
                  <input
                    value={amount}
                    onChange={handleAmountChange}
                    style={styles.input}
                  />
                </div>
                <p>
                  You will receive: {amount * 100}
                  <Hunchies />
                </p>
                <div style={styles.inputContainer}>
                  <label>Payment Method</label>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 0,
                        backgroundColor: color.textPrimary,
                      }),
                    }}
                    value={paymentType.value ? paymentType : null}
                    options={
                      paymentRequest
                        ? [
                            { value: "applePay", label: "Apple Pay" },
                            { value: "card", label: "Card" },
                          ]
                        : [{ value: "card", label: "Card" }]
                    }
                    onChange={(option) => {
                      setError("");
                      setPaymentType(option);
                    }}
                  />
                </div>
                {paymentType.value === "card" ? (
                  <div>
                    <fieldset>
                      <CardElement options={CARD_OPTIONS} />
                    </fieldset>
                    <br />
                    <Button
                      onClick={loading ? () => {} : handleBuy}
                      style={loading ? styles.loadingButton : styles.payButton}
                    >
                      {loading ? "Loading..." : "Pay"}
                    </Button>
                  </div>
                ) : paymentRequest && paymentType.value === "applePay" ? (
                  <PaymentRequestButtonElement options={{ paymentRequest }} />
                ) : null}
              </TabPanel>
              <TabPanel value="Redeem for Cash">
                <div style={styles.inputContainer}>
                  <label>
                    Amount (<Hunchies />
                    ):
                  </label>
                  <input
                    value={amount}
                    onChange={handleAmountChange}
                    style={styles.input}
                  />
                </div>
                <p>You will receive: ${(amount / 100).toFixed(2)}</p>
                <div style={styles.inputContainer}>
                  <label htmlFor="phone" style={styles.label}>
                    Payout Method
                  </label>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 0,
                        backgroundColor: color.textPrimary,
                      }),
                    }}
                    defaultValue={payoutType.value ? payoutType : null}
                    options={[
                      { value: "venmo", label: "Venmo" },
                      { value: "zelle", label: "Zelle" },
                      { value: "paypal", label: "Paypal" },
                    ]}
                    onChange={(option) => {
                      setError("");
                      setPayoutType(option);
                    }}
                  />
                </div>
                {payoutType.value && (
                  <div>
                    <div style={styles.inputContainer}>
                      <label>{paymentInfoText(payoutType.value)}</label>
                      <input
                        style={styles.input}
                        type="text"
                        value={payoutInfo}
                        onChange={(event) => {
                          setError("");
                          setPayoutInfo(event.target.value);
                        }}
                        required
                      />
                    </div>
                    <Button
                      onClick={loading ? () => {} : handleRedeem}
                      style={loading ? styles.loadingButton : styles.payButton}
                    >
                      {loading ? "Loading..." : "Redeem"}
                    </Button>
                  </div>
                )}
              </TabPanel>
            </TabContext>
          </div>
        </div>
      )}
    </>
  );
});

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    zIndex: 1000000,
  },
  modal: {
    backgroundColor: color.background,
    borderRadius: "8px",
    width: "80%",
    maxWidth: "500px",
    margin: "auto",
    color: color.textSecondary,
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  input: {
    padding: "10px",
    border: "none",
    backgroundColor: color.textPrimary,
  },
  payButton: {
    backgroundColor: color.green,
    color: color.textPrimary,
    padding: "10px 20px",
    border: "none",
    cursor: "pointer",
    width: "100%",
    marginBottom: "10px",
  },
  loadingButton: {
    width: "100%",
    padding: "10px 20px",
    backgroundColor: color.textSecondary,
    color: color.textPrimary,
    border: "none",
    cursor: "disabled",
    marginBottom: "10px",
  },
};

export default PayModal;
