import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import color from "../utils/color";
import { Avatar } from "@mui/material";
import { useAccount } from "../providers/AccountProvider";
import { editProfile } from "../api/user";
import Button from "@mui/material/Button";

const ProfileEditModal = forwardRef((props, ref) => {
  const { user, updateUser } = useAccount();
  const [name, setName] = useState(props.name);
  const [bio, setBio] = useState(props.bio);
  const [originalImage, setOriginalImage] = useState(props.profilePicture);
  const [imageFile, setImageFile] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!user) return;
    setName(user.name);
    setBio(props.bio);
    setOriginalImage(user.profilePicture);
    setImageFile(null);
  }, [props, user]);

  const show = () => {
    setShowModal(true);
  };

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  const handleSave = async () => {
    await editProfile(name, bio, imageFile, originalImage);
    await updateUser();
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000000,
          }}
          onClick={() => setShowModal(false)}
        >
          <div style={styles.editModal} onClick={(e) => e.stopPropagation()}>
            <h3>Edit Profile</h3>
            <div style={styles.inputGroup}>
              <label style={styles.blockDisplay}>Profile Image:</label>
              <input
                style={{ marginBottom: "20px" }}
                type="file"
                accept="image/png, image/jpeg"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {(imageFile || originalImage) && (
                  <Avatar
                    src={
                      imageFile
                        ? URL.createObjectURL(imageFile)
                        : originalImage
                        ? originalImage
                        : "/"
                    }
                    style={{
                      width: "200px",
                      height: "200px",
                      border: "1px solid white",
                      objectFit: "contain",
                    }}
                    alt="preview"
                  />
                )}
              </div>
            </div>
            <div style={styles.inputGroup}>
              <label>Name: </label>
              <input
                value={name}
                maxLength={20}
                onChange={(e) => setName(e.target.value)}
                style={styles.input}
              />
            </div>
            <div style={styles.inputGroup}>
              <label>Bio: </label>
              <textarea
                value={bio}
                maxLength={100}
                onChange={(e) => setBio(e.target.value)}
                style={styles.textarea}
              />
            </div>
            <Button style={styles.saveButton} onClick={handleSave}>
              Save Changes
            </Button>
          </div>
        </div>
      )}
    </>
  );
});

const styles = {
  editModal: {
    backgroundColor: color.secondary,
    padding: "20px",
    borderRadius: "8px",
    color: color.textPrimary,
    width: "80%",
    maxWidth: "500px",
    margin: "auto",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    marginRight: "20px",
    marginBottom: "20px",
  },
  input: {
    width: "100%",
    padding: "10px",
    border: "none",
    backgroundColor: color.textPrimary,
    marginTop: "5px",
  },
  textarea: {
    width: "100%",
    padding: "10px",
    border: "none",
    backgroundColor: color.textPrimary,
    marginTop: "5px",
    resize: "none",
    height: "80px",
  },
  saveButton: {
    backgroundColor: color.blue,
    color: color.textPrimary,
    padding: "10px 20px",
    border: "none",
    cursor: "pointer",
    width: "100%",
  },
};

export default ProfileEditModal;
