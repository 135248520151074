import React from "react";
import color from "../utils/color";

const Hunchies = (props) => {
  return (
    <span
      {...props}
      style={{
        fontSize: 20,
        lineHeight: 0.8,
        color: props.color ? props.color : color.primary,
        ...props.style,
      }}
    >
      ℋ
    </span>
  );
};

export default Hunchies;
