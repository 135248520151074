import React, { forwardRef, useImperativeHandle, useState } from "react";
import color from "../utils/color";
import CommentBox from "./CommentBox";
import { sendReportRequest } from "../api/user";
import Button from "@mui/material/Button";

const ReportModal = forwardRef((props, ref) => {
  const [reportReason, setReportReason] = useState("");
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);
  const { event } = props;

  const handleSubmit = async (e) => {
    if (sending) return;
    e.preventDefault();
    if (!reportReason) {
      setError("Please enter your reason for reporting the event");
    }
    if (error) {
      return;
    }
    try {
      setSending(true);
      await sendReportRequest(event._id, reportReason);
      setSending(false);
      setShowModal(false);
    } catch (error) {
      setError(error.response.data);
    }
    return;
  };

  const [showModal, setShowModal] = useState(false);

  const show = () => {
    setShowModal(true);
  };

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  return (
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000000,
          }}
          onClick={() => setShowModal(false)}
        >
          <div
            style={{
              backgroundColor: color.secondary,
              padding: "20px",
              borderRadius: "8px",
              color: color.textSecondary,
              width: "80%",
              maxWidth: "500px",
              margin: "auto",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2
              style={{
                marginBottom: "20px",
                color: color.textSecondary,
                textAlign: "center",
              }}
            >
              Report Event
            </h2>
            <p>
              **NOTE** Once our team has received the report we will contact the
              original poster about the issue.
            </p>
            <div style={{ color: "red" }}>{error}</div>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    marginBottom: "10px",
                    color: color.textSecondary,
                    textTransform: "uppercase",
                    fontSize: "12px",
                    letterSpacing: "1px",
                  }}
                >
                  Reason for Reporting Event:
                </label>
                <CommentBox
                  value={reportReason}
                  onChange={(e) => {
                    setError("");
                    setReportReason(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  style={{
                    backgroundColor: sending ? "grey" : color.blue,
                    color: color.textPrimary,
                    padding: "10px 20px",
                    fontSize: "16px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: sending ? "not-allowed" : "pointer",
                  }}
                >
                  {sending ? "Sending..." : "Send Report"}
                </Button>
              </div>
              <br />
            </form>
          </div>
        </div>
      )}
    </>
  );
});

export default ReportModal;
