import React, { forwardRef, useImperativeHandle, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import color from "../utils/color";
import Hunchies from "./Hunchies";
import { claimBirthdayGift } from "../api/user";
import { useAccount } from "../providers/AccountProvider";
import Confetti from "react-confetti";

const HappyBirthdayModal = forwardRef((props, ref) => {
  const [showModal, setShowModal] = useState(false);
  const { updateUser } = useAccount();

  const show = () => {
    setShowModal(true);
  };

  const hide = () => {
    setShowModal(false);
  };

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const handleClose = async () => {
    await claimBirthdayGift();
    await updateUser();
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000000,
          }}
          onClick={handleClose}
        >
          <Confetti />
          <div
            style={{
              backgroundColor: color.secondary,
              padding: "20px",
              borderRadius: "8px",
              color: color.textSecondary,
              width: "80%",
              maxWidth: "500px",
              margin: "auto",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Typography
              variant="h5"
              id="tutorial-modal-title"
              style={styles.title}
            >
              🎂 Happy Birthday! 🎂
            </Typography>
            <Typography
              variant="body1"
              id="tutorial-modal-description"
              style={styles.description}
            >
              Thank you for using HunchPot! You have earned 10K
              <Hunchies /> for logging in today!
            </Typography>
            <Button style={styles.Button} onClick={handleClose}>
              Got it!
            </Button>
          </div>
        </div>
      )}
    </>
  );
});

const styles = {
  title: {
    textAlign: "center",
    marginBottom: "15px",
    color: color.textPrimary,
  },
  description: {
    marginBottom: "10px",
    color: color.textSecondary,
  },
  list: {
    paddingLeft: "20px",
    marginBottom: "15px",
    color: color.textSecondary,
  },
  Button: {
    display: "block",
    margin: "20px auto 0",
    backgroundColor: color.primary,
    color: color.textPrimary,
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default HappyBirthdayModal;
