import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS
import CommentBox from "../components/CommentBox";
import { useAccount } from "../providers/AccountProvider";
import { useParams, useNavigate } from "react-router-dom";
import color from "../utils/color";
import Page from "../components/Page";
import { editEvent, getEvent } from "../api/event";
import Button from "@mui/material/Button";
import { FormControlLabel, Switch } from "@mui/material";
import TopicInput from "../components/TopicInput";
import { updateCreatedEvent } from "../redux/createdEventsSlice";
import { updateParticipatedEvent } from "../redux/participatedEventsSlice";
import { updateExploreEvent } from "../redux/exploreEventsSlice";
import { updateFriendEvent } from "../redux/friendEventsSlice";
import { updateTopicEvent } from "../redux/topicEventsSlice";
import { useDispatch } from "react-redux";

const EditPage = () => {
  const { id } = useParams();
  const [event, setEvent] = useState({});
  const [title, setTitle] = useState("");
  const [topics, setTopics] = useState([]);
  const [missingTitle, setMissingTitle] = useState("");
  const [missingTopics, setMissingTopics] = useState("");
  const [description, setDescription] = useState("");
  const [validation, setValidation] = useState("");
  const [missingValidation, setMissingValidation] = useState("");
  const [originalImage, setOriginalImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [deadline, setDeadline] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [publiclyListed, setPubliclyListed] = useState(true);

  const { user, sessionLoading } = useAccount();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (!title || !validation) {
      if (!title) setMissingTitle("This is a required section");
      if (!topics) setMissingTopics("This is a required section");
      if (!validation) setMissingValidation("This is a required section");
      return;
    }
    setLoading(true);
    await editEvent(
      id,
      title,
      topics,
      description,
      validation,
      deadline,
      imageFile,
      originalImage,
      publiclyListed
    );
    setLoading(false);
    try {
      dispatch(updateCreatedEvent(event));
      dispatch(updateParticipatedEvent(event));
      dispatch(updateExploreEvent(event));
      dispatch(updateFriendEvent(event));
      dispatch(updateTopicEvent(event));
    } catch (error) {
      console.log(error);
    }
    navigate(`/event/${id}`);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getEvent(id);
      setEvent(result.data);
      setTitle(result.data.title);
      setTopics(result.data.topics.map((topic) => topic.name));
      setDescription(result.data.description);
      setValidation(result.data.validation);
      setDeadline(new Date(result.data.endsAt));
      setOriginalImage(result.data.image);
      setPubliclyListed(result.data.publiclyListed);
      setLoading(false);
    })();
  }, [id]);

  useEffect(() => {
    if (!sessionLoading && !user) {
      navigate("/login");
      return;
    }
    if (
      user &&
      event.creator &&
      !user.isAdmin &&
      user._id !== event.creator._id
    ) {
      navigate(`/event/${id}`);
    }
  }, [user, sessionLoading, event]);

  return (
    <Page>
      <div
        style={{
          ...styles.postContainer,
          marginTop: window.innerWidth < 600 ? "0px" : "50px",
          marginBottom: window.innerWidth < 600 ? "0px" : "50px",
        }}
      >
        <h2 style={styles.centeredText}>Edit your Hunch Pot</h2>
        <p>**NOTE** Your edit history can be accessed publicly</p>
        <div style={styles.inputGroup}>
          <label style={styles.blockDisplay}>
            Title<span style={{ color: color.red }}>*</span>{" "}
            <div style={styles.errorText}>{missingTitle}</div>
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.blockDisplay}>
            Topics <span style={{ color: color.red }}>*</span>{" "}
            <div style={styles.errorText}>{missingTopics}</div>
          </label>
          <div style={{ width: "90%" }}>
            <TopicInput topics={topics} setTopics={setTopics} />
          </div>
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.blockDisplay}>Description</label>
          <CommentBox
            style={styles.input}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.blockDisplay}>
            Rules<span style={{ color: color.red }}>*</span>{" "}
            <div style={styles.errorText}>{missingValidation}</div>
          </label>
          <CommentBox
            style={styles.input}
            value={validation}
            onChange={(e) => setValidation(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label style={styles.blockDisplay}>
            Cover Photo (Strong Recommended)
          </label>
          <input
            type="file"
            accept="image/png, image/jpeg"
            onChange={(e) => setImageFile(e.target.files[0])}
          />
          <br />
          <br />
          {(imageFile || originalImage) && (
            <img
              src={imageFile ? URL.createObjectURL(imageFile) : originalImage}
              style={{ width: "100%", height: "400px", objectFit: "contain" }}
              alt="preview"
            />
          )}
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label style={styles.blockDisplay}>Deadline:</label>
          {!loading && (
            <DatePicker
              selected={deadline}
              onChange={(date) => setDeadline(date)}
              dateFormat="MMMM d, yyyy h:mm aa"
              showTimeInput
              minDate={new Date()}
            />
          )}
        </div>
        <div style={styles.blockDisplay}>
          <FormControlLabel
            onChange={(e) => setPubliclyListed(e.target.checked)}
            value={publiclyListed}
            control={<Switch checked={publiclyListed} />}
            label="Publicly Listed"
          />
          {!publiclyListed && (
            <p>
              **NOTE** This Hunch Pot will only appear for your friends in the
              friends tab
            </p>
          )}
        </div>
        <div>
          <label style={styles.blockDisplay}>Awards</label>
          <p>You get 1% of total traded volume (max $500)</p>
        </div>
        <Button
          onClick={loading ? () => {} : handleSubmit}
          style={loading ? styles.loadingButton : styles.Button}
        >
          {loading ? "Loading..." : "Edit Your Hunch Pot"}
        </Button>
      </div>
    </Page>
  );
};

const styles = {
  postContainer: {
    padding: "20px",
    color: color.textSecondary,
    borderRadius: "8px",
    margin: "auto",
    backgroundColor: color.secondary,
  },
  centeredText: {
    textAlign: "center",
    marginBottom: "20px",
  },
  inputGroup: {
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  blockDisplay: {
    display: "block",
    marginBottom: "10px",
    width: "100%",
    textAlign: "flex-start",
  },
  input: {
    padding: "10px",
    width: "90%",
    borderRadius: "5px",
    border: "none",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    marginBottom: "20px",
  },
  Button: {
    display: "block",
    width: "100%",
    padding: "10px",
    backgroundColor: "#14B8A6",
    color: color.textPrimary,
    border: "none",
    cursor: "pointer",
    borderRadius: "4px",
  },
  loadingButton: {
    display: "block",
    width: "100%",
    padding: "10px",
    backgroundColor: color.textSecondary,
    color: color.textPrimary,
    border: "none",
    cursor: "disabled",
    borderRadius: "4px",
  },
  ButtonHover: {
    backgroundColor: "#12A89D",
  },
  datePickerWrapper: {
    width: "100%",
    padding: "10px",
    border: "none",
    backgroundColor: "#4A5568",
    color: color.textSecondary,
    borderRadius: "4px",
  },
  errorText: {
    color: color.red,
    fontSize: "12px",
    marginTop: "5px",
  },
};

export default EditPage;
