import SingleMarket from "./singleMarket.js";

class MultiMarket {
  constructor(markets) {
    this.markets = markets;
  }

  buy = (vote, amount, marketId) => {
    const marketIndex = this.markets.findIndex(
      (market) => market._id === marketId
    );
    if (vote) {
      return this.buyYes(amount, marketIndex);
    } else {
      return this.buyNo(amount, marketIndex);
    }
  };

  sell = (vote, amountShares, marketId) => {
    const marketIndex = this.markets.findIndex(
      (market) => market._id === marketId
    );
    if (vote) {
      return this.sellYes(amountShares, marketIndex);
    } else {
      return this.sellNo(amountShares, marketIndex);
    }
  };

  binarySearchAmount = (amount, marketIndex, simulate) => {
    let low = 0;
    let high = amount;
    const epsilon = 1e-12;
    while (high - low > epsilon) {
      const mid = low + (high - low) / 2;
      const result = simulate(mid, marketIndex);
      if (result.amount < amount) {
        low = mid;
      } else {
        high = mid;
      }
    }
    return {
      amount: low,
      otherAmountShares: simulate(low, marketIndex).otherAmountShares,
    };
  };

  binarySearchBuyNoShares = (markets, marketIndex, deltaP) => {
    const marketP = markets
      .filter((market, index) => index !== marketIndex)
      .map((market) => market.marketProbability());
    const totalMarketP = marketP.reduce((a, b) => a + b);
    let shares = [];
    markets.forEach((market, index) => {
      if (index === marketIndex) return;
      const otherDeltaP = (deltaP * market.marketProbability()) / totalMarketP;
      shares.push(
        market.amountNoToMakeProbability(
          market.marketProbability() - otherDeltaP
        )
      );
    });
    let low = Math.min(...shares);
    let high = Math.max(...shares);
    const epsilon = 1e-12;
    while (high - low > epsilon) {
      const mid = low + (high - low) / 2;
      let total = 0;
      markets.forEach((market, index) => {
        if (index === marketIndex) return;
        let prev = market.marketProbability();
        market.buyNoShares(market.amountToBuyNoShares(mid));
        total += market.marketProbability() - prev;
        market.sellNoShares(mid);
      });
      if (-total < deltaP) {
        low = mid;
      } else {
        high = mid;
      }
    }
    return low;
  };

  buyYes = (amount, marketIndex) => {
    const markets = this.markets;
    if (markets.length === 1) {
      return markets[0].buyYesShares(amount);
    }
    const sharesTracker = markets.map(() => ({
      yes: 0,
      no: 0,
    }));
    const result = this.binarySearchAmount(
      amount,
      marketIndex,
      this.simulateBuyYes
    );
    sharesTracker[marketIndex].yes += markets[marketIndex].buyYesShares(
      result.amount
    );
    let excess = 0;
    markets.forEach((market, index) => {
      if (index === marketIndex) return;
      excess = market.buyNoShares(
        market.amountToBuyNoShares(result.otherAmountShares)
      );
      markets.forEach((market, convertIndex) => {
        if (convertIndex !== index) {
          sharesTracker[convertIndex].yes += excess;
        }
      });
    });
    return markets.length > 2
      ? sharesTracker[marketIndex].yes - excess
      : sharesTracker[marketIndex].yes;
  };

  simulateBuyYes = (amount, marketIndex) => {
    const markets = this.markets;
    const fakeMarkets = markets.map(
      (market) => new SingleMarket(market._id, market.y, market.n, market.k)
    );
    const oP = fakeMarkets[marketIndex].marketProbability();
    fakeMarkets[marketIndex].buyYesShares(amount);
    const deltaP = fakeMarkets[marketIndex].marketProbability() - oP;
    const otherAmountShares = this.binarySearchBuyNoShares(
      fakeMarkets,
      marketIndex,
      deltaP
    );
    let total = amount;
    let excess = 0;
    fakeMarkets.forEach((market, index) => {
      if (index === marketIndex) return;
      const otherMarketAmount = market.amountToBuyNoShares(otherAmountShares);
      total += otherMarketAmount;
      excess = market.buyNoShares(otherMarketAmount);
    });
    return {
      amount: markets.length > 2 ? total - excess : total,
      otherAmountShares,
    };
  };

  binarySearchBuyYesShares = (markets, marketIndex, deltaP) => {
    const marketP = markets
      .filter((market, index) => index !== marketIndex)
      .map((market) => market.marketProbability());
    const totalMarketP = marketP.reduce((a, b) => a + b);
    let shares = [];
    markets.forEach((market, index) => {
      if (index === marketIndex) return;
      const otherDeltaP = (deltaP * market.marketProbability()) / totalMarketP;
      shares.push(
        market.amountYesToMakeProbability(
          market.marketProbability() - otherDeltaP
        )
      );
    });
    let low = Math.min(...shares);
    let high = Math.max(...shares);
    const epsilon = 1e-12;
    while (high - low > epsilon) {
      const mid = low + (high - low) / 2;
      let total = 0;
      markets.forEach((market, index) => {
        if (index === marketIndex) return;
        let prev = market.marketProbability();
        market.buyYesShares(market.amountToBuyYesShares(mid));
        total += market.marketProbability() - prev;
        market.sellYesShares(mid);
      });
      if (total < -deltaP) {
        low = mid;
      } else {
        high = mid;
      }
    }
    return low;
  };

  // may not work for two markets
  buyNo = (amount, marketIndex) => {
    const markets = this.markets;
    if (markets.length === 1) {
      return markets[0].buyNoShares(amount);
    }
    const sharesTracker = markets.map(() => ({
      yes: 0,
      no: 0,
    }));
    const result = this.binarySearchAmount(
      amount,
      marketIndex,
      this.simulateBuyNo
    );
    sharesTracker[marketIndex].no += markets[marketIndex].buyNoShares(
      result.amount
    );
    let convertedAmount = 0;
    markets.forEach((market, index) => {
      if (index === marketIndex) return;
      const otherMarketAmount = market.amountToBuyYesShares(
        result.otherAmountShares
      );
      convertedAmount = market.buyYesShares(otherMarketAmount);
    });
    return sharesTracker[marketIndex].no + convertedAmount;
  };

  // may not work for two markets
  simulateBuyNo = (amount, marketIndex) => {
    const markets = this.markets;
    const fakeMarkets = markets.map(
      (market) => new SingleMarket(market._id, market.y, market.n, market.k)
    );
    const oP = fakeMarkets[marketIndex].marketProbability();
    fakeMarkets[marketIndex].buyNoShares(amount);
    const deltaP = fakeMarkets[marketIndex].marketProbability() - oP;
    const otherAmountShares = this.binarySearchBuyYesShares(
      fakeMarkets,
      marketIndex,
      deltaP
    );
    let total = amount;
    fakeMarkets.forEach((market, index) => {
      if (index === marketIndex) return;
      const otherMarketAmount = market.amountToBuyYesShares(otherAmountShares);
      total += otherMarketAmount;
      market.buyYesShares(otherMarketAmount);
    });
    return { amount: total, otherAmountShares };
  };

  // may not work for two markets
  binarySearchSellNoShares = (markets, marketIndex, deltaP) => {
    const marketP = markets
      .filter((market, index) => index !== marketIndex)
      .map((market) => market.marketProbability());
    const totalMarketP = marketP.reduce((a, b) => a + b);
    let shares = [];
    markets.forEach((market, index) => {
      if (index === marketIndex) return;
      const otherDeltaP = (deltaP * market.marketProbability()) / totalMarketP;
      shares.push(
        -market.amountNoToMakeProbability(
          market.marketProbability() - otherDeltaP
        )
      );
    });
    let low = Math.min(...shares);
    let high = Math.max(...shares);
    const epsilon = 1e-12;
    while (high - low > epsilon) {
      const mid = low + (high - low) / 2;
      let total = 0;
      markets.forEach((market, index) => {
        if (index === marketIndex) return;
        let prev = market.marketProbability();
        const amount = market.sellNoShares(mid);
        total += market.marketProbability() - prev;
        market.buyNoShares(amount);
      });
      if (total < -deltaP) {
        low = mid;
      } else {
        high = mid;
      }
    }
    return low;
  };

  sellYes = (amountYes, marketIndex) => {
    const markets = this.markets;
    if (markets.length === 1) {
      return markets[0].sellYesShares(amountYes);
    }
    const result = this.binarySearchAmount(
      amountYes,
      marketIndex,
      this.simulateSellYes
    );
    let amount = markets[marketIndex].sellYesShares(result.amount);
    let excess = result.otherAmountShares;
    markets.forEach((market, index) => {
      if (index === marketIndex) return;
      amount += market.sellNoShares(result.otherAmountShares);
    });
    return markets.length > 2 ? amount - excess : amount;
  };

  simulateSellYes = (amountYes, marketIndex) => {
    const markets = this.markets;
    const sharesTracker = markets.map(() => ({
      yes: 0,
      no: 0,
    }));
    const fakeMarkets = markets.map(
      (market) => new SingleMarket(market._id, market.y, market.n, market.k)
    );
    const oP = fakeMarkets[marketIndex].marketProbability();
    fakeMarkets[marketIndex].sellYesShares(amountYes);
    sharesTracker[marketIndex].yes -= amountYes;
    const deltaP = fakeMarkets[marketIndex].marketProbability() - oP;
    const otherAmountShares = this.binarySearchSellNoShares(
      fakeMarkets,
      marketIndex,
      deltaP
    );
    fakeMarkets.forEach((market, index) => {
      if (index === marketIndex) return;
      market.sellNoShares(otherAmountShares);
      fakeMarkets.forEach((market, convertIndex) => {
        if (convertIndex !== index) {
          sharesTracker[convertIndex].yes -= otherAmountShares;
        }
      });
    });
    return {
      amount:
        sharesTracker.length > 2
          ? -sharesTracker[marketIndex].yes - otherAmountShares
          : -sharesTracker[marketIndex].yes,
      otherAmountShares,
    };
  };

  binarySearchSellYesShares = (markets, marketIndex, deltaP) => {
    const marketP = markets
      .filter((market, index) => index !== marketIndex)
      .map((market) => market.marketProbability());
    const totalMarketP = marketP.reduce((a, b) => a + b);
    let shares = [];
    markets.forEach((market, index) => {
      if (index === marketIndex) return;
      const otherDeltaP = (deltaP * market.marketProbability()) / totalMarketP;
      shares.push(
        -market.amountYesToMakeProbability(
          market.marketProbability() - otherDeltaP
        )
      );
    });
    let low = Math.min(...shares);
    let high = Math.max(...shares);
    const epsilon = 1e-12;
    while (high - low > epsilon) {
      const mid = low + (high - low) / 2;
      let total = 0;
      markets.forEach((market, index) => {
        if (index === marketIndex) return;
        let prev = market.marketProbability();
        const amount = market.sellYesShares(mid);
        total += market.marketProbability() - prev;
        market.buyYesShares(amount);
      });
      if (total > -deltaP) {
        low = mid;
      } else {
        high = mid;
      }
    }
    return low;
  };

  // may not work for two markets
  sellNo = (amountNo, marketIndex) => {
    const markets = this.markets;
    if (markets.length === 1) {
      return markets[0].sellNoShares(amountNo);
    }
    const result = this.binarySearchAmount(
      amountNo,
      marketIndex,
      this.simulateSellNo
    );
    let amount = markets[marketIndex].sellNoShares(result.amount);
    markets.forEach((market, index) => {
      if (index === marketIndex) return;
      amount += market.sellYesShares(result.otherAmountShares);
    });
    return amount;
  };

  // may not work for two markets
  simulateSellNo = (amountNo, marketIndex) => {
    const markets = this.markets;
    const fakeMarkets = markets.map(
      (market) => new SingleMarket(market._id, market.y, market.n, market.k)
    );
    const oP = fakeMarkets[marketIndex].marketProbability();
    fakeMarkets[marketIndex].sellNoShares(amountNo);
    const deltaP = fakeMarkets[marketIndex].marketProbability() - oP;
    const otherAmountShares = this.binarySearchSellYesShares(
      fakeMarkets,
      marketIndex,
      deltaP
    );
    fakeMarkets.forEach((market, index) => {
      if (index === marketIndex) return;
      market.sellYesShares(otherAmountShares);
    });
    return { amount: amountNo + otherAmountShares, otherAmountShares };
  };
}

export default MultiMarket;
