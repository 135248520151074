import axios from "axios";
import baseUrl from "./baseUrl";

const getExploreEvents = async (lastId, amount) => {
  return await axios.get(
    `${await baseUrl()}/event/explore?lastId=${
      lastId ? lastId : ""
    }&amount=${amount}`
  );
};

const getSearchEvents = async (searchTerm, lastId, amount) => {
  return await axios.get(
    `${await baseUrl()}/event/search?lastId=${
      lastId ? lastId : ""
    }&amount=${amount}&searchTerm=${searchTerm}`
  );
};

const getTopicEvents = async (topicId, lastId, amount) => {
  return await axios.get(
    `${await baseUrl()}/event/topic?lastId=${
      lastId ? lastId : ""
    }&amount=${amount}&topicId=${topicId}`
  );
};

const getSearchSuggestions = async (searchTerm) => {
  return await axios.get(
    `${await baseUrl()}/event/searchSuggestions?searchTerm=${searchTerm}`
  );
}

const getEvent = async (id) => {
  const machineId = localStorage.getItem("machineId");
  return await axios.get(
    `${await baseUrl()}/event/${id}?machineId=${machineId}`
  );
};

const createEvent = async (
  title,
  topics,
  description,
  marketDescriptions,
  validation,
  endsAt,
  image,
  publiclyListed
) => {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("topics", JSON.stringify(topics));
  formData.append("description", description);
  formData.append("endsAt", endsAt);
  formData.append("validation", validation);
  formData.append("image", image);
  formData.append("marketDescriptions", JSON.stringify(marketDescriptions));
  formData.append("publiclyListed", publiclyListed);
  return await axios.post(`${await baseUrl()}/event/create`, formData, {
    withCredentials: true,
  });
};

const editEvent = async (
  eventId,
  title,
  topics,
  description,
  validation,
  endsAt,
  image,
  originalImage,
  publiclyListed
) => {
  const formData = new FormData();
  formData.append("eventId", eventId);
  formData.append("title", title);
  formData.append("topics", JSON.stringify(topics));
  formData.append("description", description);
  formData.append("endsAt", endsAt);
  formData.append("validation", validation);
  formData.append("image", image);
  formData.append("originalImage", originalImage);
  formData.append("publiclyListed", publiclyListed);
  return await axios.post(`${await baseUrl()}/event/edit`, formData, {
    withCredentials: true,
  });
};

const submitEvidence = async (
  eventId,
  marketId,
  result,
  evidenceImage,
  evidenceDescription
) => {
  const formData = new FormData();
  formData.append("eventId", eventId);
  formData.append("marketId", marketId);
  formData.append("result", result);
  formData.append("evidenceDescription", evidenceDescription);
  formData.append("image", evidenceImage);
  return await axios.post(`${await baseUrl()}/event/submitEvidence`, formData, {
    withCredentials: true,
  });
};

const claimPayout = async (eventId) => {
  return await axios.post(
    `${await baseUrl()}/event/claimPayout`,
    { eventId },
    {
      withCredentials: true,
    }
  );
};

const sellShares = async (eventId, marketId, vote, shares) => {
  return await axios.post(
    `${await baseUrl()}/event/sellShares`,
    { eventId, marketId, vote, shares },
    {
      withCredentials: true,
    }
  );
};

export {
  getExploreEvents,
  getTopicEvents,
  getSearchEvents,
  getSearchSuggestions,
  getEvent,
  createEvent,
  editEvent,
  submitEvidence,
  claimPayout,
  sellShares,
};
