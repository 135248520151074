import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { clearSearchEvents } from "../redux/searchEventsSlice";
import { useDispatch } from "react-redux";
import { getSearchSuggestions } from "../api/event";

const SearchInput = () => {
  // Example options, replace with your actual data
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(
    searchParams.get("searchTerm") ? searchParams.get("searchTerm") : ""
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleKeyDown = (event) => {
    if (!searchTerm || event.key != "Enter") return;
    dispatch(clearSearchEvents());
    navigate(`/search?searchTerm=${searchTerm}`);
  };

  useEffect(() => {
    if (searchTerm) {
      getSearchSuggestions(searchTerm).then((result) => {
        setOptions(
          result.data.map((eventTitle) => {
            return eventTitle;
          })
        );
      });
    } else {
      setOptions([]);
    }
  }, [searchTerm]);

  return (
    <Autocomplete
      style={{
        backgroundColor: "white",
        borderRadius: "5px",
        width: "100%",
        maxWidth: "500px",
      }}
      renderOption={(props, option) => (
        <li
          {...props}
          onClick={() => {
            setOpen(false);
            dispatch(clearSearchEvents());
            setSearchTerm(option);
            navigate(`/search?searchTerm=${option}`);
          }}
        >
          {option}
        </li>
      )}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={searchTerm}
      freeSolo
      disablePortal={true}
      options={options}
      getOptionLabel={(option) => option}
      filterOptions={(options, params) => {
        if (params.inputValue !== "") {
          options.unshift(params.inputValue);
        }
        const uniqueArray = options.filter(
          (item, index) => options.indexOf(item) === index
        );
        return uniqueArray;
      }}
      renderInput={(params) => (
        <TextField
          style={{ height: "40px", justifyContent: "center" }}
          {...params}
          placeholder={"Search"}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(event) =>
            setSearchTerm(event.target.value.trim().replace(/\s+/g, " "))
          }
          onKeyDown={handleKeyDown}
        />
      )}
      sx={{
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none", // Remove border on focus
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "none", // Optional: Remove border on hover
        },
      }}
    />
  );
};

export default SearchInput;
