import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import color from "../utils/color";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, AvatarGroup, Chip } from "@mui/material";
import { convertToPast } from "../utils/convertTime";
import PersonIcon from "@mui/icons-material/Person";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import SingleMarket from "../utils/singleMarket";

const EventCard = ({ event, friends }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState(event.title);
  const [creator, setCreator] = useState(event.creator);
  const [description, setDescription] = useState(event.description);
  const [status, setStatus] = useState(event.status);
  const [eventFriends, setEventFriends] = useState(
    friends.filter((friend) => {
      return event.votes.find((vote) => vote.voter === friend._id);
    })
  );
  const [marketProbabilities, setMarketProbabilities] = useState(
    event.markets.map((market) => {
      const singleMarket = new SingleMarket(
        market._id,
        market.y,
        market.n,
        market.k
      );
      return singleMarket.marketProbability();
    })
  );
  const votersCount = event.votes ? event.votes.length : 0;

  const adjustToScreenSize = () => {
    setTimeout(() => {
      wrapString({ title: event.title }, 2, setTitle);
      wrapString({ description: event.description }, 3, setDescription);
    }, 0);
  };

  window.addEventListener("resize", () => {
    adjustToScreenSize();
  });

  setTimeout(() => {
    adjustToScreenSize();
  }, 0);

  useEffect(() => {
    setTitle(event.title);
    setDescription(event.description);
    setCreator(event.creator);
    setMarketProbabilities(
      event.markets.map((market) => {
        const singleMarket = new SingleMarket(market._id, market.y, market.n);
        return singleMarket.marketProbability();
      })
    );
    setEventFriends(
      friends.filter((friend) => {
        return event.votes.find((vote) => vote.voter === friend._id);
      })
    );
    if (event.status) {
      setStatus(event.status);
    } else {
      if (event.processing) {
        setStatus("Processing");
      } else if (event.evidenceDescription) {
        setStatus("Resolved");
      } else {
        setStatus("Active");
      }
    }
  }, [event, friends]);

  const wrapString = (text, lineLimit, setText) => {
    const varToString = (varObj) => Object.keys(varObj)[0];
    const stringVariable = varToString(text);
    const clientWidth = document.getElementById("measure")?.clientWidth;
    const font = getCanvasFont(document.getElementById(stringVariable));
    const stringArray = text[stringVariable].split(" ");
    const lineArray = [];
    for (let i = 0; i < stringArray.length; i++) {
      if (lineArray.length > lineLimit) {
        break;
      }
      const possibleLine =
        lineArray[lineArray.length - 1] + " " + stringArray[i];
      if (
        lineArray.length &&
        getTextWidth(possibleLine, font) < clientWidth - 50
      ) {
        lineArray[lineArray.length - 1] = possibleLine;
      } else {
        lineArray.push(stringArray[i]);
      }
    }
    if (lineArray.length > lineLimit) {
      const lastLine = lineArray[lineLimit - 1] + " " + lineArray[lineLimit];
      for (let i = 0; i < lastLine.length; i++) {
        const truncatedString =
          lastLine.substring(0, lastLine.length - i) + "...";
        const tagWidth =
          stringVariable === "title"
            ? document.getElementById("tag")?.clientWidth
            : 0;
        if (getTextWidth(truncatedString, font) < clientWidth - 60 - tagWidth) {
          lineArray[lineLimit - 1] = truncatedString;
          break;
        }
      }
    }
    setText(lineArray.slice(0, lineLimit).join(" "));
  };

  const getTextWidth = (text, font) => {
    const canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  };

  const getCssStyle = (element, prop) => {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
  };

  const getCanvasFont = (el = document.body) => {
    const fontWeight = getCssStyle(el, "font-weight") || "normal";
    const fontSize = getCssStyle(el, "font-size") || "16px";
    const fontFamily = getCssStyle(el, "font-family") || "Times New Roman";

    return `${fontWeight} ${fontSize} ${fontFamily}`;
  };

  const FriendsParticipated = () => {
    if (eventFriends.length === 0) {
      return null;
    }
    const topThreeFriends = eventFriends.slice(0, 3);
    const participatedText = (
      <>
        <b>{topThreeFriends[0].name}</b>{" "}
        {eventFriends.length - 1 ? (
          <>
            &nbsp;and&nbsp;
            <b>{eventFriends.length - 1}</b>&nbsp;
            {eventFriends.length - 1 > 1 ? "others" : "other"}
          </>
        ) : (
          ""
        )}
        &nbsp;participated
      </>
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: color.textSecondary,
          marginTop: "10px",
          marginLeft: "20px",
          fontSize: "12px",
        }}
      >
        <AvatarGroup total={eventFriends.length} spacing={"small"}>
          {topThreeFriends.map((friend, index) => (
            <Avatar
              sx={{ width: 24, height: 24 }}
              key={index}
              alt={friend.name}
              src={friend.profilePicture ? friend.profilePicture : "/"}
            />
          ))}
        </AvatarGroup>
        &nbsp;{participatedText}
      </div>
    );
  };

  return (
    <div
      style={styles.container}
      onClick={() => navigate(`/event/${event._id}`)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "space-between",
          marginLeft: "20px",
          marginRight: "20px",
          marginTop: "5px",
          marginBottom: "-10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/profile/${creator._id}`);
          }}
        >
          <Avatar
            style={{ marginRight: "10px" }}
            alt={creator.name}
            src={creator.profilePicture ? creator.profilePicture : "/"}
          />
          <div
            style={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h5 style={styles.infoText}>{creator.name}</h5>
            {creator.verified && (
              <img
                src={require("../assets/images/verified.png")}
                alt="Verified"
                style={{
                  marginLeft: "-15px",
                  height: "30px",
                  alignSelf: "center",
                }}
              />
            )}
          </div>
        </div>
        <h5 style={{ color: color.textSecondary }}>
          {convertToPast(event.createdAt)}
        </h5>
      </div>
      <p id={"measure"}>{String(" ").repeat(100)}</p>
      {event.image && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            src={event.image}
            style={{
              objectFit: "contain",
              width: "100%",
              maxHeight: "600px",
            }}
          />
        </div>
      )}
      <FriendsParticipated />
      <div style={styles.productInfo}>
        <span
          style={{
            ...styles.infoText,
            fontWeight: "bold",
            whiteSpace: "pre-wrap",
          }}
          id={"title"}
        >
          {title + " "}
          <Chip
            size="small"
            style={{
              backgroundColor:
                status === "Processing"
                  ? color.orange
                  : status === "Resolved"
                  ? color.primary
                  : color.green,
              color: color.textPrimary,
            }}
            label={status}
          />
          {event.topics &&
            event.topics.map((topic) => (
              <Chip
                size="small"
                style={{
                  backgroundColor: color.secondary,
                  color: color.textPrimary,
                  marginLeft: "5px",
                }}
                label={topic.name}
              />
            ))}
        </span>
        <p
          id={"description"}
          style={{ ...styles.infoText, whiteSpace: "pre-wrap" }}
        >
          {description}
        </p>
      </div>
      {event.markets && event.markets.length > 1 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "20px",
            marginTop: "10px",
            color: color.textSecondary,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 25,
              marginRight: "10px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            {marketProbabilities.map((prob, index) => (
              <div
                key={index}
                style={{
                  width: `${prob * 100}%`,
                  backgroundColor: color.multipleChoice[index],
                  height: "100%",
                  color: color.textPrimary,
                  textAlign: "center",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                {(prob * 100).toFixed(0)}%
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            {event.views}&nbsp;
            <ViewIcon style={{ color: color.textSecondary }} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {votersCount}
            <PersonIcon style={{ color: color.textSecondary }} />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "20px",
            marginTop: "10px",
            color: color.textSecondary,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            {marketProbabilities.length
              ? (marketProbabilities[0] * 100).toFixed(2)
              : ""}
            {"%"}
            <CheckIcon style={{ color: color.green }} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            {marketProbabilities.length
              ? ((1 - marketProbabilities[0]) * 100).toFixed(2)
              : ""}
            {"%"}
            <CloseIcon style={{ color: color.red }} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            {event.views}&nbsp;
            <ViewIcon style={{ color: color.textSecondary }} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {votersCount}
            <PersonIcon style={{ color: color.textSecondary }} />
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: color.background,
    borderRadius: "10px",
  },
  productInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: 20,
    paddingRight: 20,
    color: color.textSecondary,
    overflowWrap: "break-word",
  },
  infoText: {
    margin: 0,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "left",
    color: color.textSecondary,
    overflowWrap: "break-word",
    width: "100%",
  },
};

export default EventCard;
