import React, { useEffect, useState } from "react";
import { useAccount } from "../providers/AccountProvider";
import color from "../utils/color";
import Gallery from "../components/Gallery";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Page from "../components/Page";
import { LoginButton, useFacebook } from "react-facebook";
import { useNavigate } from "react-router-dom";
import { addFacebook } from "../api/user";
import { fetchExploreEvents } from "../redux/exploreEventsSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchFriendEvents } from "../redux/friendEventsSlice";
import { setHomeTab } from "../redux/tabSlice";
import { getPopularTopics } from "../api/topic";
import { AppBar } from "@mui/material";
import { fetchTopicEvents } from "../redux/topicEventsSlice";

const HomePage = () => {
  const { user } = useAccount();
  let homeTab = useSelector((state) => state.homeTab.tab);
  const [eventType, setEventType] = useState(homeTab);
  const [popularTopics, setPopularTopics] = useState([]);

  useEffect(() => {
    getPopularTopics().then((result) => {
      setPopularTopics(result.data);
    });
  }, []);

  const handleChange = (event, newValue) => {
    setEventType(newValue);
    dispatch(setHomeTab(newValue));
  };

  useEffect(() => {
    if (eventType === "Friends" && !user) {
      dispatch(setHomeTab("Explore"));
      navigate("/login");
      return;
    }
  }, [user, eventType]);

  const { login } = useAccount();

  const navigate = useNavigate();

  const { init } = useFacebook();

  async function handleFbSuccess(response) {
    const api = await init();
    const FB = await api.getFB();
    FB.api("/me", function (response) {
      const facebook = response;
      FB.api(
        `/${facebook.id}/picture?redirect=false&width=9999`,
        "GET",
        {},
        async function (response) {
          const result = await addFacebook({
            ...facebook,
            profilePicture: response.data.url,
          });
          await login(result.data);
          navigate("/");
        }
      );
    });
  }

  function handleFbError(error) {
    // setError(error);
  }

  const dispatch = useDispatch();

  const exploreEvents = useSelector((state) => state.exploreEvents.events);
  const exploreEventsStatus = useSelector(
    (state) => state.exploreEvents.status
  );

  const friendEvents = useSelector((state) => state.friendEvents.events);
  const friendEventsStatus = useSelector((state) => state.friendEvents.status);

  const topicEvents = useSelector((state) => state.topicEvents);

  return (
    <Page style={{ backgroundColor: color.secondary }}>
      <Box
        sx={{
          width: "100%",
          typography: "body1",
          backgroundColor: color.secondary,
        }}
      >
        <TabContext value={eventType}>
          <AppBar
            style={{
              position: "sticky",
              top: 64,
              backgroundColor: color.secondary,
              boxShadow: "0px 0px 0px rgba(0, 0, 0)",
              zIndex: 1,
            }}
          >
            <TabList
              onChange={handleChange}
              textColor="inherit"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              sx={{
                flexGrow: 1,
                justifyContent: 'center',
                '& .MuiTabs-scroller': {
                  flexGrow: '0',
                }
              }}
            >
              <Tab
                style={{ color: color.textSecondary }}
                label="Explore"
                value="Explore"
              />
              <Tab
                style={{ color: color.textSecondary }}
                label="Friends"
                value="Friends"
              />
              {popularTopics.map((topic) => (
                <Tab
                  style={{ color: color.textSecondary }}
                  label={topic.name}
                  value={topic.name}
                />
              ))}
            </TabList>
          </AppBar>
          <TabPanel value="Explore">
            <Gallery
              events={exploreEvents}
              status={exploreEventsStatus}
              fetchEvents={async (lastId, amount) => {
                dispatch(fetchExploreEvents({ lastId, amount }));
              }}
            />
          </TabPanel>
          <TabPanel value="Friends">
            {user && user.facebookId ? (
              <Gallery
                events={friendEvents}
                status={friendEventsStatus}
                fetchEvents={async (lastId, amount) => {
                  dispatch(fetchFriendEvents({ lastId, amount }));
                }}
              />
            ) : (
              <div style={styles.needFacebookContainer}>
                <p>
                  You need to connect your Facebook account to see your friends'
                  events!
                </p>
                <LoginButton
                  style={{
                    backgroundColor: color.facebook,
                    color: color.textPrimary,
                    padding: "10px 20px",
                    fontSize: "16px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  scope="public_profile, email, user_friends"
                  onError={handleFbError}
                  onSuccess={handleFbSuccess}
                >
                  Login with Facebook
                </LoginButton>
              </div>
            )}
          </TabPanel>
          {popularTopics.map((topic) => (
            <TabPanel value={topic.name}>
              <Gallery
                events={
                  topicEvents && topicEvents[topic._id]
                    ? topicEvents[topic._id].events
                    : []
                }
                status={
                  topicEvents && topicEvents[topic._id]
                    ? topicEvents[topic._id].status
                    : "idle"
                }
                fetchEvents={async (lastId, amount) => {
                  dispatch(fetchTopicEvents({ id: topic._id, lastId, amount }));
                }}
              />
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Page>
  );
};

const styles = {
  needFacebookContainer: {
    color: color.textSecondary,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default HomePage;
