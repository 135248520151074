import React from "react";
import { Avatar } from "@mui/material";
import { convertToPast } from "../utils/convertTime";
import Hunchies from "./Hunchies";
import color from "../utils/color";
import CampaignIcon from "@mui/icons-material/Campaign";

const NotificationContainer = ({ notification }) => {
  const parts = notification.content.split("ℋ");

  return (
    <div
      key={notification._id}
      style={styles.notification}
      onClick={() => (window.location.href = `/event/${notification.event}`)}
    >
      {notification.from ? (
        <Avatar
          src={
            notification.from.profilePicture
              ? notification.from.profilePicture
              : "/"
          }
          alt={notification.from.name}
          style={styles.profilePicture}
          onClick={(e) => {
            e.stopPropagation();
            window.location.href = `/profile/${notification.from._id}`;
          }}
        />
      ) : (
        <Avatar style={styles.systemIcon}>
          <CampaignIcon />
        </Avatar>
      )}
      <span style={styles.notificationText}>
        {notification.from && (
          <span
            style={styles.username}
            onClick={(e) => {
              e.stopPropagation();
              window.location.href = `/profile/${notification.from._id}`;
            }}
          >
            {notification.from.name}
          </span>
        )}
        {parts.map((part, index) => (
          <span key={index}>
            {part}
            {index !== parts.length - 1 && <Hunchies />}
          </span>
        ))}
        <span style={styles.timeAgo}>{convertToPast(notification.time)}</span>
      </span>
    </div>
  );
};

const styles = {
  notification: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    borderBottom: "1px solid grey",
    padding: "10px 0",
    cursor: "pointer",
  },
  profilePicture: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    marginRight: "10px",
  },
  systemIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginRight: "10px",
    backgroundColor: color.primary,
  },
  notificationText: {
    flex: 1,
  },
  username: {
    fontWeight: "bold",
    color: color.textPrimary,
  },
  timeAgo: {
    color: "#aaa",
    marginLeft: "10px",
  },
};

export default NotificationContainer;
