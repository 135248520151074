import React, { useEffect, useRef } from "react";
import Header from "./Header";
import BottomBar from "./BottomBar";
import DailyLoginModal from "./DailyLoginModal";
import TutorialModal from "./TutorialModal";
import { useAccount } from "../providers/AccountProvider";
import { checkDailyLogin } from "../api/user";
import { useSearchParams } from "react-router-dom";
import HappyBirthdayModal from "./HappyBirthdayModal";

const Page = (props) => {
  const { user, updateUser } = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();
  const referrer = searchParams.get("referrer");
  localStorage.setItem("referrer", referrer);

  useEffect(() => {
    const finishedTutorial = localStorage.getItem("finishedTutorial");
    if (!finishedTutorial) {
      showTutorialModal();
    }
    if (!user) return;
    checkDailyLogin().then((result) => {
      if (!result.data.dailyLogin) {
        showDailyLoginModal();
      } else if (result.data.birthdayGift) {
        showHappyBirthdayModalRef();
      }
    });
    if (!user.finishedTutorial) {
      showTutorialModal();
    } else {
      hideTutorialModal();
    }
  }, [user]);

  const tutorialModalRef = useRef();

  const showTutorialModal = () => {
    tutorialModalRef.current.show();
  };

  const hideTutorialModal = () => {
    tutorialModalRef.current.hide();
  };

  const dailyLoginModalRef = useRef();

  const showDailyLoginModal = () => {
    dailyLoginModalRef.current.show();
  };

  const happyBirthdayModalRef = useRef();

  const showHappyBirthdayModalRef = () => {
    happyBirthdayModalRef.current.show();
  };

  return (
    <div style={props.style}>
      <HappyBirthdayModal ref={happyBirthdayModalRef} />
      <DailyLoginModal ref={dailyLoginModalRef} />
      <TutorialModal ref={tutorialModalRef} />
      <Header />
      <div
        style={{
          width: window.innerWidth < 900 ? "100%" : 900,
          margin: "auto",
        }}
      >
        {props.children}
      </div>
      <BottomBar />
    </div>
  );
};

export default Page;
