import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTopicEvents } from "../api/event";

export const fetchTopicEvents = createAsyncThunk(
  "topicEvents/fetch",
  async ({ id, lastId, amount }) => {
    const response = await getTopicEvents(id, lastId, amount);
    return { id, events: response.data };
  }
);

const topicEventsSlice = createSlice({
  name: "topicEvents",
  initialState: {},
  reducers: {
    updateTopicEvent: (state, action) => {
      const event = action.payload;
      for (const id in state) {
        const topic = state[id];
        const index = topic.events.findIndex(
          (element) => element._id === event._id
        );
        if (index !== -1) {
          state[id].events[index] = event;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopicEvents.pending, (state, action) => {
        const { id } = action.meta.arg;
        const topic = state[id];
        if (topic) {
          state[id].status = "loading";
        } else {
          state[id] = { events: [], status: "loading", error: null };
        }
      })
      .addCase(fetchTopicEvents.fulfilled, (state, action) => {
        const status =
          action.payload.events.length === 0 ? "finished" : "succeeded";
        state[action.payload.id].status = status;
        state[action.payload.id].events = state[action.payload.id].events
          .concat(action.payload.events)
          .filter((value, index, array) => {
            return (
              array.findIndex((elements) => value._id === elements._id) ===
              index
            );
          });
      })
      .addCase(fetchTopicEvents.rejected, (state, action) => {
        const { id } = action.meta.arg;
        const topic = state[id];
        if (topic) {
          state[id].status = "failed";
          state[id].error = action.error.message;
        } else {
          state[id] = {
            events: [],
            status: "failed",
            error: action.error.message,
          };
        }
      });
  },
});

export const { updateTopicEvent } = topicEventsSlice.actions;

export default topicEventsSlice.reducer;
