import React, { useState } from "react";
import color from "../utils/color";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Page from "../components/Page";
import Hunchies from "../components/Hunchies";
import { useSearchParams } from "react-router-dom";

const HowItWorksPage = () => {
  const [expandedStep, setExpandedStep] = useState(null);
  const toggleStep = (index) => {
    if (expandedStep === index) {
      setExpandedStep(null);
    } else {
      setExpandedStep(index);
    }
  };

  return (
    <Page>
      <div style={styles.container}>
        <h1 style={styles.title}>How It Works</h1>
        <p style={styles.intro}>
          Welcome to Hunch Pot, where you can make your hunches and see if
          others agree or disagree with you. Here's a step-by-step guide on how
          our platform functions:
        </p>

        {steps.map((step, index) => (
          <div
            key={index}
            style={styles.step}
            onClick={() => toggleStep(index)}
          >
            <h2 style={styles.stepTitle}>
              {step.title} <KeyboardArrowDownIcon />
            </h2>
            {expandedStep === index && (
              <div style={styles.stepDescription}>{step.description}</div>
            )}
          </div>
        ))}

        <p style={styles.footer}>
          Remember, Hunch Pot is all about fun and community engagement. Always
          make hunches responsibly and enjoy the experience!
        </p>
      </div>
    </Page>
  );
};

const steps = [
  {
    title: "1. Browse Hunch Pots",
    description:
      "Once you're logged in, you can browse through various hunch pots created by other users. These are questions or predictions about future events, and you make a hunch on whether you think they will come true or not.",
  },
  {
    title: `2. Earn Hunchies (100 Hunchies = $1 USD)`,
    description:
      'Found a hunch pot you have a strong feeling about? Click on it to view more details. You can then choose either "Yes" or "No" based on what you believe will happen. Enter the amount you want to place on your hunch and see your potential payout.',
  },
  {
    title: "3. Create Your Own Hunch Pot",
    description:
      "Have a hunch of your own? Create a new hunch pot! Just provide a title, description, and set a deadline for when the outcome will be determined. Share your hunch pot with others and see where the majority stands. Plus, as a little bonus, you'll earn a tiny bit (1%) from all the hunches placed on your hunch pot. Think of it like a small thank-you for starting the fun!",
  },
  {
    title: "4. Profile & Account Management",
    description: (
      <>
        Visit your profile page to view your stats, edit your bio, and manage
        your account settings.
      </>
    ),
  },
  {
    title: "5. Resolution & Payout",
    description:
      "Once the deadline for a hunch pot is reached, the creator will confirm the outcome. If your hunch was correct, your winnings will be added to your account.",
  },
  {
    title: "6. Contact Us",
    description:
      `Email: founders@hunchpot.com | Phone: 949-565-5311`,
  },
];

const styles = {
  container: {
    backgroundColor: color.background,
    color: color.textSecondary,
    padding: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "20px",
    marginRight: "20px",
  },
  title: {
    fontSize: "1.5em",
    marginBottom: "10px",
  },
  intro: {
    marginBottom: "20px",
    textAlign: "left",
  },
  step: {
    marginBottom: "15px",
    backgroundColor: color.secondary, // Highlight color for the card
    borderRadius: "8px",
    padding: "20px",
    width: "100%",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  arrow: {
    marginLeft: "10px",
    alignItems: "right",
  },
  stepTitle: {
    fontSize: "1.2em",
    marginBottom: "10px",
    display: "flex", // Add this line
    justifyContent: "space-between", // Add this line
    alignItems: "center",
  },
  stepDescription: {
    fontSize: "1em",
  },
  footer: {
    marginTop: "20px",
    textAlign: "left",
    fontSize: "0.9em",
  },
};

export default HowItWorksPage;
