import { configureStore } from "@reduxjs/toolkit";
import exploreEventsReducer from "./exploreEventsSlice";
import createdEventsReducer from "./createdEventsSlice";
import friendEventsReducer from "./friendEventsSlice";
import participatedEventsReducer from "./participatedEventsSlice";
import tabReducers from './tabSlice';
import topicEventsfReducer from "./topicEventsSlice";
import searchEventsReducer from "./searchEventsSlice";

const store = configureStore({
  reducer: {
    exploreEvents: exploreEventsReducer,
    createdEvents: createdEventsReducer,
    friendEvents: friendEventsReducer,
    participatedEvents: participatedEventsReducer,
    topicEvents: topicEventsfReducer,
    searchEvents: searchEventsReducer,
    ...tabReducers,
  },
});

export default store;
