import axios from "axios";
import baseUrl from "./baseUrl";

const renewUserSession = async (token) => {
  return await axios.post(
    `${await baseUrl()}/user/renewUserSession`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

const logoutUserSession = async () => {
  return await axios.post(
    `${await baseUrl()}/user/logoutUserSession`,
    {},
    {
      withCredentials: true,
    }
  );
};

const getCreatedEvents = async (id, lastId, amount) => {
  return await axios.get(
    `${await baseUrl()}/user/getCreatedEvents/${id}?lastId=${
      lastId ? lastId : ""
    }&amount=${amount}`
  );
};

const phoneSignup = async (name, phone, referrer) => {
  return await axios.post(
    `${await baseUrl()}/user/signup`,
    {
      name,
      phone,
      referrer,
    },
    {
      withCredentials: true,
    }
  );
};

const facebookSignup = async (facebook, referrer) => {
  return await axios.post(
    `${await baseUrl()}/user/signup`,
    {
      facebook,
      referrer,
    },
    {
      withCredentials: true,
    }
  );
};

const phoneLogin = async (phone) => {
  return await axios.post(
    `${await baseUrl()}/user/login`,
    {
      phone,
    },
    {
      withCredentials: true,
    }
  );
};

const facebookLogin = async (facebook) => {
  return await axios.post(
    `${await baseUrl()}/user/login`,
    {
      facebook,
    },
    {
      withCredentials: true,
    }
  );
};

const verify = async (phone, verification) => {
  return await axios.post(
    `${await baseUrl()}/user/verify`,
    {
      phone,
      verification,
    },
    {
      withCredentials: true,
    }
  );
};

const buyShares = async (eventId, marketId, vote, amount) => {
  return await axios.post(
    `${await baseUrl()}/event/buyShares`,
    { eventId, marketId, vote, amount },
    {
      withCredentials: true,
    }
  );
};

const getParticipatedEvents = async (id, lastId, amount) => {
  return await axios.get(
    `${await baseUrl()}/user/getParticipatedEvents/${id}?lastId=${
      lastId ? lastId : ""
    }&amount=${amount}`
  );
};

const sendDeleteRequest = async (eventId, deleteReason) => {
  return await axios.post(
    `${await baseUrl()}/user/sendDeleteRequest`,
    { eventId, deleteReason },
    {
      withCredentials: true,
    }
  );
};

const sendReportRequest = async (eventId, reportReason) => {
  return await axios.post(
    `${await baseUrl()}/user/sendReportRequest`,
    { eventId, reportReason },
    {
      withCredentials: true,
    }
  );
};

const getProfile = async (id) => {
  return await axios.get(`${await baseUrl()}/user/getProfile/${id}`);
};

const editProfile = async (name, bio, image, originalImage) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("bio", bio);
  formData.append("image", image);
  formData.append("originalImage", originalImage);
  return await axios.post(`${await baseUrl()}/user/editProfile`, formData, {
    withCredentials: true,
  });
};

const cashIn = async (amount, paymentMethod) => {
  return await axios.post(
    `${await baseUrl()}/user/cashIn`,
    { amount, paymentMethod },
    {
      withCredentials: true,
    }
  );
};

const cashOut = async (amount, payout) => {
  return await axios.post(
    `${await baseUrl()}/user/cashOut`,
    { amount, payout },
    {
      withCredentials: true,
    }
  );
};

const getFriendsEvents = async (lastId, amount) => {
  return await axios.get(
    `${await baseUrl()}/user/getFriendsEvents?lastId=${
      lastId ? lastId : ""
    }&amount=${amount}`,
    {
      withCredentials: true,
    }
  );
};

const finishTutorial = async () => {
  return await axios.post(
    `${await baseUrl()}/user/finishTutorial`,
    {},
    {
      withCredentials: true,
    }
  );
};

const addFacebook = async (facebook) => {
  return await axios.post(
    `${await baseUrl()}/user/addFacebook`,
    { facebook },
    {
      withCredentials: true,
    }
  );
};

const addPhone = async (phone, token) => {
  return await axios.post(
    `${await baseUrl()}/user/addPhone`,
    { phone },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

const updateFacebookFriends = async (facebookFriends) => {
  return await axios.post(
    `${await baseUrl()}/user/updateFacebookFriends`,
    { facebookFriends },
    {
      withCredentials: true,
    }
  );
};

const getFriends = async () => {
  return await axios.get(`${await baseUrl()}/user/getFriends`, {
    withCredentials: true,
  });
};

const checkDailyLogin = async () => {
  return await axios.post(
    `${await baseUrl()}/user/checkDailyLogin`,
    {},
    {
      withCredentials: true,
    }
  );
};

const claimDailyLogin = async () => {
  return await axios.post(
    `${await baseUrl()}/user/claimDailyLogin`,
    {},
    {
      withCredentials: true,
    }
  );
};

const claimBirthdayGift = async () => {
  return await axios.post(
    `${await baseUrl()}/user/claimBirthdayGift`,
    {},
    {
      withCredentials: true,
    }
  );
};

const addMachineId = async () => {
  const machineId = localStorage.getItem("machineId");
  return await axios.post(
    `${await baseUrl()}/user/addMachineId`,
    { machineId },
    {
      withCredentials: true,
    }
  );
};

export {
  renewUserSession,
  logoutUserSession,
  getCreatedEvents,
  phoneSignup,
  facebookSignup,
  phoneLogin,
  facebookLogin,
  verify,
  getParticipatedEvents,
  sendDeleteRequest,
  sendReportRequest,
  getProfile,
  editProfile,
  cashIn,
  cashOut,
  getFriendsEvents,
  finishTutorial,
  addFacebook,
  addPhone,
  updateFacebookFriends,
  getFriends,
  buyShares,
  checkDailyLogin,
  claimDailyLogin,
  claimBirthdayGift,
  addMachineId,
};
