import React from "react";
import color from "../utils/color";
import { useSearchParams } from "react-router-dom";
import Page from "../components/Page";

const PrivacyPage = () => {
  return (
    <Page>
      <div style={{ padding: "20px" }}>
        <h1 style={styles.title}>HunchPot Privacy Policy</h1>
        <p style={styles.lastUpdated}>Last updated: 10/05/2023</p>
        <p style={styles.paragraph}>
          At Hunchpot, we respect your personal information and, as such, we
          have established this Privacy Policy so that you can understand the
          care with which we intend to treat your information.
        </p>

        <h2 style={styles.subTitle}>1. User Data</h2>
        <p style={styles.paragraph}>
          We collect basic user data to provide and enhance our services. This
          includes your name, email, and any other information you provide
          during registration.
        </p>

        <h2 style={styles.subTitle}>2. Use of Data</h2>
        <p style={styles.paragraph}>
          We use the data collected to improve our platform, provide customer
          support, and occasionally send updates or news related to Hunchpot. We
          do not sell or share your data with third-party advertisers.
        </p>

        <h2 style={styles.subTitle}>3. Facebook Data</h2>
        <p style={styles.paragraph}>
          If you choose to connect your Facebook account, we will access basic
          account information, as permitted by your Facebook settings. We
          respect the privacy settings you set on Facebook and only use this
          data to enhance your Hunchpot experience.
        </p>

        <h2 style={styles.subTitle}>4. Cookies</h2>
        <p style={styles.paragraph}>
          We use cookies to improve user experience by enabling the website to
          'remember' users, either for the duration of their visit or for repeat
          visits.
        </p>
        <h2 style={styles.subTitle}>5. Account Deletion and Data Removal</h2>
        <p style={styles.paragraph}>
          We believe in giving our users full control over their data. If you
          decide to delete your Hunchpot account, we will promptly and
          permanently remove all your associated data from our systems. Your
          trust is paramount to us, and we're committed to ensuring the privacy
          and integrity of your information.
        </p>
        <p style={styles.paragraph}>
          If you have questions or concerns about our privacy practices, please
          contact us at hugozhan0802@gmail.com
        </p>
      </div>
    </Page>
  );
};

const styles = {
  title: {
    color: color.textPrimary,
    textAlign: "center",
    marginBottom: "20px",
  },
  subTitle: {
    color: color.textPrimary,
    marginTop: "20px",
  },
  paragraph: {
    color: color.textSecondary,
    lineHeight: "1.5",
    marginBottom: "20px",
  },
  lastUpdated: {
    color: color.textPrimary,
    marginTop: "40px",
  },
};

export default PrivacyPage;
