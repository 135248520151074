import React from "react";
import color from "../utils/color";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";

const ChoicesInput = ({ choices, setChoices }) => {
  const handleChoiceChange = (index, value) => {
    const newChoices = [...choices];
    newChoices[index] = value;
    setChoices(newChoices);
  };

  const addChoice = () => {
    setChoices([...choices, ""]);
  };

  const removeChoice = (removeIndex) => {
    setChoices(choices.filter((choice, index) => index !== removeIndex));
  };

  return (
    <div style={styles.container}>
      {choices.map((choice, index) => (
        <div key={index} style={styles.choiceContainer}>
          <label style={styles.choiceLabel}>{index + 1}.</label>
          <input
            type="text"
            value={choice}
            onChange={(e) => handleChoiceChange(index, e.target.value)}
            style={styles.choiceInput}
            placeholder="Add choice here"
          />
          <CancelIcon
            onClick={() => removeChoice(index)}
            style={{ cursor: "pointer" }}
          />
        </div>
      ))}
      <Button onClick={addChoice} style={styles.addButton}>
        Add Choice
      </Button>
    </div>
  );
};

const styles = {
  container: {
    marginBottom: "20px",
  },
  choiceContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  choiceLabel: {
    marginRight: "10px",
    width: "20px",
    color: color.textSecondary,
  },
  choiceInput: {
    flex: 1,
    padding: "10px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    marginRight: "10px",
  },
  addButton: {
    padding: "10px",
    backgroundColor: "#14B8A6",
    color: color.textPrimary,
    border: "none",
    cursor: "pointer",
    borderRadius: "4px",
  },
};

export default ChoicesInput;
