const color = {
  primary: "#8D98F6",
  secondary: "#2E3048",
  background: "#1E2133",
  textPrimary: "#F4F5FC",
  textSecondary: "#BFC1D6",
  red: "#F56565",
  green: "#48BB78",
  blue: "#14B8A6",
  gold: "#F6E58D",
  orange: "#E67E22",
  facebook: "#316FF6",
  multipleChoice: [
    "#007BFF", // Cerulean Blue
    "#FF8C42", // Mango Tango
    "#50C878", // Emerald Green
    "#9966CC", // Amethyst Purple
    "#FFC75F", // Sunset Yellow
    "#FC5185", // Flamingo Pink
    "#3FC1C9", // Skyline Teal
    "#FF6B6B", // Soft Red
    "#FDD835", // Lemon Yellow
    "#88D498", // Mint Green
    "#DFFF00", // Bright Yellow
    "#FFD700", // Golden Poppy
    "#DC143C", // Crimson Red
    "#9ACD32", // Yellow Green
    "#00FFFF", // Aqua
    "#A52A2A", // Brown
    "#8A2BE2", // Blue Violet
    "#7FFF00", // Chartreuse
    "#D2691E", // Chocolate
    "#5F9EA0", // Cadet Blue
]

};

export default color;
