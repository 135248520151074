import React, { useEffect, useState } from "react";
import color from "../utils/color";
import { KeyboardArrowDown } from "@mui/icons-material";
import { convertToString, convertToPast } from "../utils/convertTime";

const HistoryListItem = ({ event, index, toggleStep, expandedStep }) => {
  const [historyEvent, setHistoryEvent] = useState({});

  useEffect(() => {
    const shortenedTitle = wrapString({ title: event.title }, 2);
    const shortenedDescription = wrapString(
      { description: event.description },
      3
    );
    setHistoryEvent({
      ...event,
      shortenedTitle,
      shortenedDescription,
    });
  }, [event]);

  const wrapString = (text, lineLimit) => {
    const varToString = (varObj) => Object.keys(varObj)[0];
    const stringVariable = varToString(text);
    const clientWidth = document.getElementById("measure")?.clientWidth;
    const font = getCanvasFont(document.getElementById(stringVariable));
    const stringArray = text[stringVariable].split(" ");
    const lineArray = [];
    for (let i = 0; i < stringArray.length; i++) {
      if (lineArray.length > lineLimit) {
        break;
      }
      const possibleLine =
        lineArray[lineArray.length - 1] + " " + stringArray[i];
      if (
        lineArray.length &&
        getTextWidth(possibleLine, font) < clientWidth - 50
      ) {
        lineArray[lineArray.length - 1] = possibleLine;
      } else {
        lineArray.push(stringArray[i]);
      }
    }
    if (lineArray.length > lineLimit) {
      const lastLine = lineArray[lineLimit - 1] + " " + lineArray[lineLimit];
      for (let i = 0; i < lastLine.length; i++) {
        const truncatedString =
          lastLine.substring(0, lastLine.length - i) + "...";
        const tagWidth =
          stringVariable === "title"
            ? document.getElementById("tag")?.clientWidth
            : 0;
        if (getTextWidth(truncatedString, font) < clientWidth - 60 - tagWidth) {
          lineArray[lineLimit - 1] = truncatedString;
          break;
        }
      }
    }
    return lineArray.slice(0, lineLimit).join(" ");
  };

  const getTextWidth = (text, font) => {
    const canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  };

  const getCssStyle = (element, prop) => {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
  };

  const getCanvasFont = (el = document.body) => {
    const fontWeight = getCssStyle(el, "font-weight") || "normal";
    const fontSize = getCssStyle(el, "font-size") || "16px";
    const fontFamily = getCssStyle(el, "font-family") || "Times New Roman";

    return `${fontWeight} ${fontSize} ${fontFamily}`;
  };

  return (
    <div key={index} style={styles.list} onClick={() => toggleStep(index)}>
      <p id={"measure"}>{String(" ").repeat(100)}</p>
      <h3 style={styles.title} id={"title"}>
        {historyEvent.shortenedTitle} <KeyboardArrowDown />
      </h3>
      {expandedStep !== index && (
        <p style={styles.description} id={"description"}>
          {historyEvent.shortenedDescription}
        </p>
      )}
      {expandedStep === index && (
        <>
          {historyEvent.image && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src={historyEvent.image}
                style={{
                  width: "80%",
                  maxHeight: window.innerWidth < 600 ? "400px" : "600px",
                  objectFit: "contain",
                }}
                alt="preview"
              />
            </div>
          )}
          <p style={styles.description}>{historyEvent.description}</p>
          <p style={{ ...styles.description, fontSize: "14px" }}>
            Rule: <b>{historyEvent.validation}</b> by{" "}
            {convertToString(historyEvent.endsAt)}
          </p>
        </>
      )}
      <p style={{ ...styles.description, fontSize: "14px" }}>
        This version was modified <b>{convertToPast(historyEvent.changedAt)}</b>
      </p>
    </div>
  );
};

const styles = {
  title: {
    margin: "20px",
    overflowWrap: "break-word",
  },
  description: {
    overflowWrap: "break-word",
    fontSize: "16px",
    marginBottom: "20px",
    marginLeft: "20px",
    marginRight: "20px",
  },
  list: {
    marginBottom: "15px",
    backgroundColor: color.secondary,
    borderRadius: "8px",
    padding: "20px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  Button: {
    display: "block",
    margin: "20px auto 0",
    backgroundColor: color.primary,
    color: color.textPrimary,
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default HistoryListItem;
