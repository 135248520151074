import ProfileEditModal from "../components/ProfileEditModal";
import React, { useEffect, useRef, useState } from "react";
import color from "../utils/color";
import EditIcon from "@mui/icons-material/Edit";
import Hunchies from "../components/Hunchies";
import { AppBar, Avatar, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Gallery from "../components/Gallery";
import { useAccount } from "../providers/AccountProvider";
import { useParams } from "react-router-dom";
import Page from "../components/Page";
import { getProfile } from "../api/user";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreatedEvents } from "../redux/createdEventsSlice";
import { fetchParticipatedEvents } from "../redux/participatedEventsSlice";
import ProfilePictureModal from "../components/ProfilePictureModal";
import { setProfileTab } from "../redux/tabSlice";

const ProfilePage = () => {
  const { id } = useParams();
  const { user } = useAccount();
  const [name, setName] = useState("");
  const [createdEventsCount, setCreatedEventsCount] = useState(0);
  const [biggestWin, setBiggestWin] = useState(0);
  const [hunchies, setHunchies] = useState(0);
  const [bio, setBio] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    getProfile(id).then((result) => {
      setName(result.data.name);
      setProfilePicture(result.data.profilePicture);
      setBio(result.data.bio);
      setHunchies(result.data.hunchies);
      setBiggestWin(result.data.biggestWin);
      setCreatedEventsCount(result.data.createdEventsCount);
      setVerified(result.data.verified);
    });
  }, [user, id]);

  const profileTab = useSelector((state) => state.profileTab[id]);
  const [eventType, setEventType] = useState(
    profileTab ? profileTab : "Created"
  );

  const handleChange = (event, newValue) => {
    setEventType(newValue);
    dispatch(setProfileTab({ id, tab: newValue }));
  };

  const editModalRef = useRef();

  const handleEditClick = () => {
    editModalRef.current.show();
  };

  const dispatch = useDispatch();

  const createdEventsState = useSelector((state) => state.createdEvents[id]);

  const participatedEventsState = useSelector(
    (state) => state.participatedEvents[id]
  );

  const profilePictureModalRef = useRef();

  const handleProfilePictureClick = () => {
    profilePictureModalRef.current.show();
  };

  return (
    <Page style={{ backgroundColor: color.secondary }}>
      <div style={styles.accountContainer}>
        <ProfileEditModal
          ref={editModalRef}
          name={name}
          bio={bio}
          profilePicture={profilePicture}
        />
        <ProfilePictureModal
          ref={profilePictureModalRef}
          profilePicture={profilePicture}
        />
        <div
          style={{
            width: window.innerWidth < 600 ? "100%" : "auto",
          }}
        >
          <div style={styles.profileSection}>
            <div style={styles.profilePictureContainer}>
              <Avatar
                onClick={handleProfilePictureClick}
                style={styles.profilePicture}
                alt={name}
                src={profilePicture ? profilePicture : "/"}
              />
              {user && user._id == id && (
                <span style={styles.editIcon} onClick={handleEditClick}>
                  <EditIcon style={styles.editIconImage} />
                </span>
              )}
            </div>
            <div style={{ width: "400px" }}>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h2 style={{ margin: 0 }}>{name}</h2>
                {verified && (
                  <img
                    src={require("../assets/images/verified.png")}
                    alt="Verified"
                    style={{
                      marginLeft: "-15px",
                      height: "30px",
                      alignSelf: "center",
                    }}
                  />
                )}
              </div>
              <div style={styles.stats}>
                <div style={styles.stat}>
                  <span style={styles.statNumber}>{createdEventsCount}</span>
                  <span style={styles.statLabel}>Pots</span>
                </div>
                <div style={styles.stat}>
                  <span style={styles.statNumber}>
                    {biggestWin.toFixed(1)} <Hunchies />
                  </span>
                  <span style={styles.statLabel}>Biggest Win</span>
                </div>
                <div style={styles.stat}>
                  <span style={styles.statNumber}>
                    {hunchies.toFixed(1)} <Hunchies />
                  </span>
                  <span style={styles.statLabel}>Hunchies</span>
                </div>
              </div>
            </div>
          </div>
          <p style={styles.bio}>{bio}</p>
        </div>

        <Box
          sx={{
            width: "100%",
            typography: "body1",
            backgroundColor: color.secondary,
          }}
        >
          <TabContext value={eventType}>
            <AppBar
              style={{
                position: "sticky",
                top: 64,
                backgroundColor: color.secondary,
                boxShadow: "0px 0px 0px rgba(0, 0, 0)",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                textColor="inherit"
                centered
              >
                <Tab
                  style={{ color: color.textSecondary }}
                  label="Created"
                  value="Created"
                />
                <Tab
                  style={{ color: color.textSecondary }}
                  label="Participated"
                  value="Participated"
                />
              </TabList>
            </AppBar>
            <TabPanel value="Created">
              <Gallery
                events={createdEventsState ? createdEventsState.events : []}
                status={createdEventsState ? createdEventsState.status : "idle"}
                fetchEvents={async (lastId, amount) => {
                  dispatch(fetchCreatedEvents({ id, lastId, amount }));
                }}
              />
            </TabPanel>
            <TabPanel value="Participated">
              <Gallery
                events={
                  participatedEventsState ? participatedEventsState.events : []
                }
                status={
                  participatedEventsState
                    ? participatedEventsState.status
                    : "idle"
                }
                fetchEvents={async (lastId, amount) => {
                  dispatch(fetchParticipatedEvents({ id, lastId, amount }));
                }}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </Page>
  );
};

const styles = {
  accountContainer: {
    backgroundColor: color.secondary,
    color: color.textSecondary,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  profileSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px",
  },
  profilePicture: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    objectFit: "cover",
    fontSize: "50px",
  },
  stats: {
    display: "flex",
    justifyContent: "space-around",
  },
  stat: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  statNumber: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "20px",
  },
  bio: {
    flexDirection: "row",
    marginLeft: "20px",
    marginRight: "20px",
  },
  moneySection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  profilePictureContainer: {
    position: "relative",
    width: "100px",
    height: "100px",
  },
  editIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
    cursor: "pointer",
    width: "15px", // Adjust as needed
    height: "15px", // Adjust as needed
    borderRadius: "50%",
    backgroundColor: color.primary,
    padding: "5px",
  },
  editIconImage: {
    width: "100%",
    height: "100%",
    color: color.textPrimary,
  },
};

export default ProfilePage;
