import React, { useEffect, useRef, useState } from "react";
import color from "../utils/color";
import Button from "@mui/material/Button";
import BetModal from "./BetModal";
import Leaderboard from "./Leaderboard";
import { useAccount } from "../providers/AccountProvider";
import SingleMarketLeaderboard from "./SingleMarketLeaderboard";

const SingleMarket = ({ event, setShouldReload }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [market, setMarket] = useState(null);
  const [status, setStatus] = useState("");
  const { user } = useAccount();

  // const handleAddChoice = () => {
  //   if (newChoice) {
  //     optionsData.push({ label: newChoice, votes: 0 });
  //     setNewChoice("");
  //   }
  // };

  window.onclick = (e) => {
    let element = e.target;
    while (element.parentNode) {
      if (
        element.parentNode.id === "multiplechoice" ||
        element.parentNode.id == "menu-appbar"
      )
        return;
      element = element.parentNode;
    }
    setSelectedOption(null);
  };

  useEffect(() => {
    if (!event || !event.markets) return;
    setMarket(event.markets[0]);
    if (event.status) {
      setStatus(event.status);
    } else {
      if (event.processing) {
        setStatus("Processing");
      } else if (event.evidenceDescription) {
        setStatus("Resolved");
      } else {
        setStatus("Active");
      }
    }
  }, [event]);

  const betModalRef = useRef();

  const showBetModal = () => {
    betModalRef.current.show();
  };

  return (
    <>
      {market && (
        <div id={"multiplechoice"} style={styles.container}>
          <BetModal
            ref={betModalRef}
            event={event}
            market={market}
            setShouldReload={setShouldReload}
            selectedOption={selectedOption}
          />
          <div
            key={true}
            style={{
              ...styles.option,
              border:
                selectedOption === true
                  ? `3px solid ${styles.selectionColor}`
                  : "none",
              background: `linear-gradient(90deg, ${color.green} ${
                (market.n / (market.y + market.n)) * 100
              }%, ${styles.defaultBackground} ${
                (market.n / (market.y + market.n)) * 100
              }%)`,
            }}
            onClick={() => setSelectedOption(true)}
          >
            <span>{"Yes"}</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span style={styles.odds}>
                {((market.n / (market.y + market.n)) * 100).toFixed(2)}%
              </span>
              {(!user || event.creator._id != user._id) &&
                status == "Active" && (
                  <Button style={styles.betButton} onClick={showBetModal}>
                    Bet
                  </Button>
                )}
            </div>
          </div>
          <div
            key={false}
            style={{
              ...styles.option,
              border:
                selectedOption === false
                  ? `3px solid ${styles.selectionColor}`
                  : "none",
              background: `linear-gradient(90deg, ${color.red} ${
                (market.y / (market.y + market.n)) * 100
              }%, ${styles.defaultBackground} ${
                (market.y / (market.y + market.n)) * 100
              }%)`,
            }}
            onClick={() => setSelectedOption(false)}
          >
            <span>{"No"}</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span style={styles.odds}>
                {((market.y / (market.y + market.n)) * 100).toFixed(2)}%
              </span>
              {(!user || event.creator._id != user._id) &&
                status == "Active" && (
                  <Button style={styles.betButton} onClick={showBetModal}>
                    Bet
                  </Button>
                )}
            </div>
          </div>
          <SingleMarketLeaderboard
            event={event}
            market={market}
            setShouldReload={setShouldReload}
          />
        </div>
      )}
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderRadius: "10px",
    margin: "0 auto",
    width: "85%",
  },
  option: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    color: color.textPrimary,
  },
  odds: {
    fontWeight: "bold",
  },
  addChoiceContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "10px",
  },
  input: {
    flex: 1,
    padding: "10px",
    marginRight: "10px",
    borderRadius: "5px",
  },
  addButton: {
    padding: "10px 20px",
    backgroundColor: color.blue,
    color: color.textPrimary,
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  betButton: {
    backgroundColor: color.blue,
    color: color.textPrimary,
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginLeft: "10px",
  },
  disabledButton: {
    padding: "10px 20px",
    backgroundColor: "grey",
    color: color.textPrimary,
    border: "none",
    borderRadius: "5px",
    cursor: "not-allowed",
  },
  defaultBackground: color.secondary, // Dark blue background
  selectionColor: color.blue, // Selection border color
};

export default SingleMarket;
