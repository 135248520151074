import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Button from "@mui/material/Button";
import color from "../utils/color";
import HistoryListItem from "./HistoryListItem";

const HistoryModal = forwardRef((props, ref) => {
  const { event } = props;
  const [showModal, setShowModal] = useState(false);
  const [history, setHistory] = useState([]);
  const [expandedStep, setExpandedStep] = useState(null);
  const toggleStep = (index) => {
    if (expandedStep === index) {
      setExpandedStep(null);
    } else {
      setExpandedStep(index);
    }
  };

  useEffect(() => {
    if (!event.history) return;
    setHistory(event.history);
  }, [event]);

  const show = () => {
    setShowModal(true);
  };

  const hide = () => {
    setShowModal(false);
  };

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const handleClose = async () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000000,
          }}
          onClick={handleClose}
        >
          <div
            style={{
              backgroundColor: color.background,
              padding: "20px",
              borderRadius: "8px",
              color: color.textSecondary,
              width: "80%",
              maxWidth: "500px",
              margin: "auto",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              overflowY: "scroll",
              maxHeight: "600px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h3 style={styles.title}>History</h3>
            {history.map((event, index) => (
              <HistoryListItem
                event={event}
                index={index}
                toggleStep={toggleStep}
                expandedStep={expandedStep}
              />
            ))}
            <Button style={styles.Button} onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      )}
    </>
  );
});

const styles = {
  title: {
    margin: "20px",
    overflowWrap: "break-word",
  },
  description: {
    overflowWrap: "break-word",
    fontSize: "16px",
    marginBottom: "20px",
    marginLeft: "20px",
    marginRight: "20px",
  },
  list: {
    marginBottom: "15px",
    backgroundColor: color.secondary,
    borderRadius: "8px",
    padding: "20px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  Button: {
    display: "block",
    margin: "20px auto 0",
    backgroundColor: color.primary,
    color: color.textPrimary,
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default HistoryModal;
