import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useAccount } from "../providers/AccountProvider";
import LoginModal from "./LoginModal";
import color from "../utils/color";
import Hunchies from "./Hunchies";
import SingleMarket from "../utils/singleMarket";
import MultiMarket from "../utils/multiMarket";
import { buyShares } from "../api/user";
import Button from "@mui/material/Button";

const BetModal = forwardRef((props, ref) => {
  const { event, market, setShouldReload, selectedOption } = props;
  const [amount, setAmount] = useState(0);
  const [payout, setPayout] = useState(0);
  const [newProb, setNewProb] = useState(0);
  const [error, setError] = useState("");
  const [markets, setMarkets] = useState([]);
  const [marketId, setMarketId] = useState("");
  const [loading, setLoading] = useState(false);

  const { user, updateUser } = useAccount();

  const vote = async (vote) => {
    try {
      setLoading(true);
      await buyShares(event._id, marketId, vote, amount);
      await updateUser();
      setShouldReload(true);
      setShowModal(false);
      setAmount(0);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
    }
    setLoading(false);
  };

  const [showModal, setShowModal] = useState(false);

  const show = () => {
    setShowModal(true);
  };

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  const loginModalRef = useRef();

  const showLoginModal = () => {
    setShowModal(false);
    loginModalRef.current.show();
  };

  function handleAmountChange(e) {
    setError("");
    const { value } = e.target;
    let newValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    // Handle leading zeroes
    if (newValue.startsWith("0")) {
      newValue = String(parseInt(newValue, 10) || "");
    }
    const multiMarket = new MultiMarket(
      markets.map(
        (market) => new SingleMarket(market._id, market.y, market.n, market.k)
      )
    );
    const singleMarket = multiMarket.markets.find(
      (singleMarket) => singleMarket._id === marketId
    );
    if (!newValue) {
      setAmount(0);
      setPayout(0);
      setNewProb(
        selectedOption
          ? singleMarket.marketProbability()
          : 1 - singleMarket.marketProbability()
      );
      return;
    }
    const shares = multiMarket.buy(selectedOption, newValue, marketId);
    setPayout(shares);
    setNewProb(
      selectedOption
        ? singleMarket.marketProbability()
        : 1 - singleMarket.marketProbability()
    );
    setAmount(newValue);
  }

  useEffect(() => {
    if (!event || !market) return;
    setAmount(0);
    setPayout(0);
    setMarketId(market._id);
    setMarkets(event.markets);
    const multiMarket = new MultiMarket(
      event.markets.map(
        (market) => new SingleMarket(market._id, market.y, market.n, market.k)
      )
    );
    const singleMarket = multiMarket.markets.find(
      (singleMarket) => singleMarket._id === market._id
    );
    setNewProb(
      selectedOption
        ? singleMarket.marketProbability()
        : 1 - singleMarket.marketProbability()
    );
  }, [event, market, selectedOption]);

  return (
    <>
      <LoginModal ref={loginModalRef} />
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000000,
          }}
          onClick={() => setShowModal(false)}
        >
          <div style={styles.betModal} onClick={(e) => e.stopPropagation()}>
            <h3>
              Submit Your Hunch{" "}
              {market.description && `(${market.description})`}
            </h3>
            <div style={{ color: "red", marginBottom: "20px" }}>{error}</div>
            <div style={styles.betAmount}>
              <label>
                Amount (<Hunchies />
                ):
              </label>
              <input
                value={amount}
                onChange={handleAmountChange}
                style={styles.input}
              />
            </div>
            <div style={styles.betPayout}>
              <p
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                Payout if {selectedOption ? "Yes" : "No"}:{" "}
                {payout ? payout.toFixed(1) : 0}
                <Hunchies />
                &nbsp;(+
                {amount ? ((payout / amount) * 100 - 100).toFixed(2) : 0}
                %)
              </p>
              <p
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                New Probability of {selectedOption ? "Yes" : "No"}:{" "}
                {newProb ? (newProb * 100).toFixed(2) : ""}%
              </p>
            </div>
            {user ? (
              amount <= 0 ? (
                <Button style={{ ...styles.betButton, ...styles.disabled }}>
                  Enter Hunchies Amount
                </Button>
              ) : loading ? (
                <Button style={{ ...styles.betButton, ...styles.disabled }}>
                  Loading...
                </Button>
              ) : selectedOption ? (
                <Button
                  style={{ ...styles.betButton, ...styles.betYes }}
                  onClick={() => vote(true)}
                >
                  Buy YES
                </Button>
              ) : (
                <Button
                  style={{ ...styles.betButton, ...styles.betNo }}
                  onClick={() => vote(false)}
                >
                  Buy NO
                </Button>
              )
            ) : (
              <Button style={styles.signupButton} onClick={showLoginModal}>
                Login to Predict
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
});

const styles = {
  betModal: {
    backgroundColor: color.background,
    padding: "20px",
    borderRadius: "8px",
    color: color.textSecondary,
    width: "80%",
    maxWidth: "500px",
    margin: "auto",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  betAmount: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  input: {
    padding: "10px",
    border: "none",
    backgroundColor: color.textSecondary,
  },
  betPayout: {
    marginBottom: "20px",
  },
  signupButton: {
    backgroundColor: color.blue,
    color: color.textPrimary,
    padding: "10px 20px",
    border: "none",
    cursor: "pointer",
    width: "100%",
  },
  betButton: {
    padding: "10px 20px",
    margin: "5px",
    border: "none",
    cursor: "pointer",
    width: "100%",
    color: color.textPrimary,
  },
  betYes: {
    backgroundColor: color.green,
  },
  betNo: {
    backgroundColor: color.red,
  },
  disabled: {
    backgroundColor: "grey",
    cursor: "not-allowed",
  },
};

export default BetModal;
