const CommentBox = (props) => {
  return (
    <textarea
      {...props}
      style={
        props.style ? { ...styles.textarea, ...props.style } : styles.textarea
      }
    ></textarea>
  );
};

const styles = {
  textarea: {
    padding: "10px",
    width: "100%",
    height: "100px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
  },
};

export default CommentBox;
