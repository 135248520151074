import React, { useState, useEffect, useRef } from "react";
import Page from "../components/Page";
import color from "../utils/color";
import NotificationContainer from "../components/NotificationContainer";
import { getNotifications } from "../api/notification";
import Button from "@mui/material/Button";

const NotificationPage = () => {
  const [filter, setFilter] = useState("All");
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const notificationRef = useRef();
  notificationRef.current = notifications;

  useEffect(() => {
    let currentNotifications = notificationRef.current;
    let unmounted = false;
    const loadMore = async () => {
      if (unmounted) return;
      try {
        if (
          !loading &&
          window.scrollY + window.innerHeight + 5 > document.body.offsetHeight
        ) {
          setLoading(true);
          const result = await getNotifications(
            currentNotifications[currentNotifications.length - 1]
              ? currentNotifications[currentNotifications.length - 1]._id
              : null,
            20
          );
          if (result.data.length === 0) {
            setNotifications(currentNotifications);
            setLoading(false);
            return;
          }
          currentNotifications = currentNotifications.concat(result.data);
          currentNotifications = currentNotifications.filter(onlyUnique);
          setNotifications(currentNotifications);
          loadMore();
          return;
        } else {
          setNotifications(currentNotifications);
          setLoading(false);
          return;
        }
      } catch (error) {
        console.log(error);
        return;
      }
    };
    loadMore();
    window.addEventListener("scroll", loadMore);
    window.addEventListener("resize", loadMore);
    window.addEventListener("visibilitychange", loadMore);
    return () => {
      window.removeEventListener("scroll", loadMore);
      window.removeEventListener("resize", loadMore);
      window.removeEventListener("visibilitychange", loadMore);
      unmounted = true;
    };
  }, []);

  function onlyUnique(value, index, array) {
    return array.findIndex((elements) => value._id === elements._id) === index;
  }

  return (
    <Page>
      <div style={styles.notificationContainer}>
        <div style={styles.header}>
          <h2>Notifications</h2>
          {/* <div style={styles.filterButtons}>
            <Button
              style={{
                ...styles.filterButton,
                color:
                  filter === "All" ? color.textPrimary : color.textSecondary,
                backgroundColor: filter === "All" ? color.blue : "transparent",
              }}
              onClick={() => setFilter("All")}
            >
              All
            </Button>
            <Button
              style={{
                ...styles.filterButton,
                color:
                  filter === "Trading Activity"
                    ? color.textPrimary
                    : color.textSecondary,
                backgroundColor:
                  filter === "Trading Activity" ? color.blue : "transparent",
              }}
              onClick={() => setFilter("Trading Activity")}
            >
              Trading Activity
            </Button>
            <Button
              style={{
                ...styles.filterButton,
                color:
                  filter === "My Post"
                    ? color.textPrimary
                    : color.textSecondary,
                backgroundColor:
                  filter === "My Post" ? color.blue : "transparent",
              }}
              onClick={() => setFilter("My Post")}
            >
              My Pots
            </Button>
          </div> */}
        </div>
        {[...notifications].map((notification) => (
          <NotificationContainer
            key={notification._id}
            notification={notification}
          />
        ))}
      </div>
    </Page>
  );
};

const styles = {
  notificationContainer: {
    backgroundColor: color.background,
    color: color.textSecondary,
    padding: "20px",
    paddingTop: "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // marginBottom: "20px",
  },
  filterButtons: {
    display: "flex",
    gap: "10px",
    marginTop: "10px",
  },
  filterButton: {
    padding: "5px 10px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
};

export default NotificationPage;
