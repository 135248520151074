import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import color from "../utils/color";
import Hunchies from "./Hunchies";
import MultiMarket from "../utils/multiMarket";
import SingleMarket from "../utils/singleMarket";
import { useAccount } from "../providers/AccountProvider";
import { sellShares } from "../api/event";
import Button from "@mui/material/Button";

const SellModal = forwardRef((props, ref) => {
  const {
    event,
    market,
    setShouldReload,
    selectedOption,
    userYesVote,
    userNoVote,
  } = props;
  const [shares, setShares] = useState(0);
  const [newProb, setNewProb] = useState(0);
  const [amount, setAmount] = useState(0);
  const [marketId, setMarketId] = useState("");
  const [markets, setMarkets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { updateUser } = useAccount();

  const sell = async (vote) => {
    try {
      setLoading(true);
      await sellShares(event._id, marketId, vote, shares);
      await updateUser();
      setShouldReload(true);
      setShowModal(false);
      setShares(0);
    } catch (error) {
      setError(error.response.data);
    }
    setLoading(false);
  };

  const [showModal, setShowModal] = useState(false);

  const show = () => {
    setShowModal(true);
  };

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  function handleSharesChange(e) {
    setError("");
    const { value } = e.target;
    let newValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    // Handle leading zeroes
    if (newValue.startsWith("0")) {
      newValue = String(parseInt(newValue, 10) || "");
    }
    const multiMarket = new MultiMarket(
      markets.map(
        (market) => new SingleMarket(market._id, market.y, market.n, market.k)
      )
    );
    const singleMarket = multiMarket.markets.find(
      (singleMarket) => singleMarket._id === marketId
    );
    if (!newValue) {
      setShares(0);
      setAmount(0);
      setNewProb(
        selectedOption
          ? singleMarket.marketProbability()
          : 1 - singleMarket.marketProbability()
      );
      return;
    }
    newValue = Math.min(newValue, selectedOption ? userYesVote : userNoVote);
    const amount = multiMarket.sell(selectedOption, newValue, marketId);
    setShares(newValue);
    setAmount(amount);
    setNewProb(
      selectedOption
        ? singleMarket.marketProbability()
        : 1 - singleMarket.marketProbability()
    );
  }

  useEffect(() => {
    if (!event || !market) return;
    setAmount(0);
    setShares(0);
    setMarketId(market._id);
    setMarkets(event.markets);
    const multiMarket = new MultiMarket(
      event.markets.map(
        (market) => new SingleMarket(market._id, market.y, market.n, market.k)
      )
    );
    const singleMarket = multiMarket.markets.find(
      (singleMarket) => singleMarket._id === market._id
    );
    setNewProb(
      selectedOption
        ? singleMarket.marketProbability()
        : 1 - singleMarket.marketProbability()
    );
  }, [event, market, selectedOption]);

  return (
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000000,
          }}
          onClick={() => setShowModal(false)}
        >
          <div style={styles.betModal} onClick={(e) => e.stopPropagation()}>
            <h3>
              Sell Your Position (<u>{selectedOption ? "Yes" : "No"}</u>
              {market.description && ` on ${market.description}`})
            </h3>
            <div style={{ color: "red", marginBottom: "20px" }}>{error}</div>

            <div style={styles.betAmount}>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                Shares (<Hunchies />
                ):
              </label>
              <input
                value={shares.toFixed(0)}
                onChange={handleSharesChange}
                style={styles.input}
              />
            </div>
            <div style={styles.betPayout}>
              <p
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                Sale Value: {amount ? amount.toFixed(1) : 0}
                <Hunchies />
              </p>
              <p
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                New Probability of {selectedOption ? "Yes" : "No"}:{" "}
                {(newProb * 100).toFixed(2)}%
              </p>
            </div>

            {amount <= 0 ? (
              <Button style={{ ...styles.betButton, ...styles.disabled }}>
                Enter an Amount
              </Button>
            ) : loading ? (
              <Button style={{ ...styles.betButton, ...styles.disabled }}>
                Loading...
              </Button>
            ) : selectedOption ? (
              <Button
                style={{ ...styles.betButton, ...styles.betYes }}
                onClick={() => sell(true)}
              >
                Sell YES
              </Button>
            ) : (
              <Button
                style={{ ...styles.betButton, ...styles.betNo }}
                onClick={() => sell(false)}
              >
                Sell NO
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
});

const styles = {
  betModal: {
    backgroundColor: color.background,
    padding: "20px",
    borderRadius: "8px",
    color: color.textSecondary,
    width: "80%",
    maxWidth: "500px",
    margin: "auto",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  betAmount: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  input: {
    padding: "10px",
    border: "none",
    backgroundColor: color.textSecondary,
  },
  betPayout: {
    marginBottom: "20px",
  },
  signupButton: {
    backgroundColor: color.blue,
    color: color.textPrimary,
    padding: "10px 20px",
    border: "none",
    cursor: "pointer",
    width: "100%",
  },
  betButton: {
    padding: "10px 20px",
    margin: "5px",
    border: "none",
    cursor: "pointer",
    width: "100%",
    color: color.textPrimary,
  },
  betYes: {
    backgroundColor: color.green,
  },
  betNo: {
    backgroundColor: color.red,
  },
  disabled: {
    backgroundColor: "grey",
    cursor: "not-allowed",
  },
};

export default SellModal;
