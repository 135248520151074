import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import color from "../utils/color";
import Hunchies from "./Hunchies";
import { claimPayout } from "../api/event";
import { useAccount } from "../providers/AccountProvider";

const ClaimPayoutModal = forwardRef((props, ref) => {
  const { event, setShouldReload } = props;
  const [showModal, setShowModal] = useState(false);
  const [payout, setPayout] = useState(0);
  const { user, updateUser } = useAccount();

  useEffect(() => {
    if (!event.votes || !user) return;
    const userVote = event.votes.find((vote) => vote.voter._id === user._id);
    if (userVote) {
      setPayout(userVote.shares.toFixed(2));
    }
  }, [event, user]);

  const show = () => {
    setShowModal(true);
  };

  const hide = () => {
    setShowModal(false);
  };

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const handleClose = async () => {
    await claimPayout(event._id);
    await updateUser();
    setShouldReload(true);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000000,
          }}
          onClick={handleClose}
        >
          <div
            style={{
              backgroundColor: color.secondary,
              padding: "20px",
              borderRadius: "8px",
              color: color.textSecondary,
              width: "80%",
              maxWidth: "500px",
              margin: "auto",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Typography
              variant="h5"
              id="tutorial-modal-title"
              style={styles.title}
            >
              Your hunch was correct!
            </Typography>
            <Typography
              variant="body1"
              id="tutorial-modal-description"
              style={styles.description}
            >
              Congrats! You have earned {payout}
              <Hunchies /> for being correct!
            </Typography>
            <Button style={styles.Button} onClick={handleClose}>
              Got it!
            </Button>
          </div>
        </div>
      )}
    </>
  );
});

const styles = {
  title: {
    textAlign: "center",
    marginBottom: "15px",
    color: color.textPrimary,
  },
  description: {
    marginBottom: "10px",
    color: color.textSecondary,
  },
  list: {
    paddingLeft: "20px",
    marginBottom: "15px",
    color: color.textSecondary,
  },
  Button: {
    display: "block",
    margin: "20px auto 0",
    backgroundColor: color.primary,
    color: color.textPrimary,
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default ClaimPayoutModal;
