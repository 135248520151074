import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCreatedEvents } from "../api/user";

export const fetchCreatedEvents = createAsyncThunk(
  "createdEvents/fetch",
  async ({ id, lastId, amount }) => {
    const response = await getCreatedEvents(id, lastId, amount);
    return { id, events: response.data };
  }
);

const createdEventsSlice = createSlice({
  name: "created",
  initialState: {},
  reducers: {
    updateCreatedEvent(state, action) {
      const event = action.payload;
      for (const id in state) {
        const user = state[id];
        const index = user.events.findIndex(
          (element) => element._id === event._id
        );
        if (index !== -1) {
          state[id].events[index] = event;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreatedEvents.pending, (state, action) => {
        const { id } = action.meta.arg;
        const user = state[id];
        if (user) {
          state[id].status = "loading";
        } else {
          state[id] = { events: [], status: "loading", error: null };
        }
      })
      .addCase(fetchCreatedEvents.fulfilled, (state, action) => {
        const status =
          action.payload.events.length === 0 ? "finished" : "succeeded";
        state[action.payload.id].status = status;
        state[action.payload.id].events = state[action.payload.id].events
          .concat(action.payload.events)
          .filter((value, index, array) => {
            return (
              array.findIndex((elements) => value._id === elements._id) ===
              index
            );
          });
      })
      .addCase(fetchCreatedEvents.rejected, (state, action) => {
        const { id } = action.meta.arg;
        const user = state[id];
        if (user) {
          state[id].status = "failed";
          state[id].error = action.error.message;
        } else {
          state[id] = {
            events: [],
            status: "failed",
            error: action.error.message,
          };
        }
      });
  },
});

export const { updateCreatedEvent } = createdEventsSlice.actions;

export default createdEventsSlice.reducer;
