import React, { useEffect, useRef, useState } from "react";
import color from "../utils/color";
import { Avatar, Button } from "@mui/material";
import Hunchies from "./Hunchies";
import { useAccount } from "../providers/AccountProvider";
import SellModal from "./SellModal";
import MultiMarket from "../utils/multiMarket";
import SingleMarket from "../utils/singleMarket";

const Leaderboard = ({ event, market, selectedOption, setShouldReload }) => {
  const [topThree, setTopThree] = useState([]);
  const [userVote, setUserVote] = useState(null);
  const [status, setStatus] = useState("");

  const { user } = useAccount();

  useEffect(() => {
    let votes;
    if (selectedOption != undefined) {
      votes = event.votes.filter((vote) => vote.vote == selectedOption);
    } else {
      votes = event.votes.filter((vote) => vote.market == market._id);
    }
    setTopThree(votes.sort((a, b) => b.amount - a.amount).slice(0, 3));
    if (user) {
      const userVote = votes.find((vote) => vote.voter._id === user._id);
      if (userVote) {
        const multiMarket = new MultiMarket(
          event.markets.map(
            (market) =>
              new SingleMarket(market._id, market.y, market.n, market.k)
          )
        );
        const worth = multiMarket.sell(
          selectedOption,
          userVote.shares,
          market._id
        );
        setUserVote({ ...userVote, worth });
      } else {
        setUserVote(null);
      }
    }
    if (event.status) {
      setStatus(event.status);
    } else {
      if (event.processing) {
        setStatus("Processing");
      } else if (event.evidenceDescription) {
        setStatus("Resolved");
      } else {
        setStatus("Active");
      }
    }
  }, [event, market, user, selectedOption]);

  const sellModalRef = useRef();

  const showSellModal = () => {
    sellModalRef.current.show();
  };

  return (
    <>
      <div style={styles.container}>
        {userVote && (
          <div
            style={{
              color: color.textPrimary,
              backgroundColor: "#2D3748",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <div>Your Bet: </div>
            <div style={styles.userEntryStyle}>
              <span style={{ marginRight: "20px" }}>
                Payout: {userVote.shares.toFixed(1)}
                <Hunchies />
              </span>
              <span style={{ marginRight: "20px" }}>
                Spent: {userVote.amount.toFixed(1)}
                <Hunchies />
              </span>
              <span style={{ marginRight: "20px" }}>
                Worth: {userVote.worth.toFixed(1)}
                <Hunchies />
              </span>
              {status == "Active" && (
                <Button style={styles.sellButton} onClick={showSellModal}>
                  Sell
                </Button>
              )}
            </div>
          </div>
        )}
        <SellModal
          ref={sellModalRef}
          event={event}
          market={market}
          setShouldReload={setShouldReload}
          selectedOption={selectedOption != undefined ? selectedOption : true}
          userYesVote={
            (selectedOption == undefined || selectedOption) && userVote
              ? userVote.shares
              : 0
          }
          userNoVote={
            selectedOption != undefined && !selectedOption && userVote
              ? userVote.shares
              : 0
          }
        />
        <h2 style={styles.title}>
          Leaderboard for{" "}
          {selectedOption != undefined
            ? selectedOption
              ? "Yes"
              : "No"
            : market.description}
        </h2>
        {topThree.map((vote, index) => (
          <div
            key={vote.voter._id}
            style={styles.entryStyle}
            onClick={() => {
              window.location.href = `/profile/${vote.voter._id}`;
            }}
          >
            <span>{index + 1}.</span>
            <Avatar
              style={styles.avatarStyle}
              src={vote.voter.profilePicture ? vote.voter.profilePicture : "/"}
              alt={vote.voter.name}
            />
            <span style={userVote == vote ? { color: color.green } : {}}>
              {vote.voter.name}'s bet: {vote.amount.toFixed(1)}
              <Hunchies />
            </span>
          </div>
        ))}
        {topThree.length == 3 && <div style={styles.entryStyle}>. . .</div>}
      </div>
    </>
  );
};

const styles = {
  container: {
    margin: "10px",
    backgroundColor: color.secondary,
    padding: "20px",
    borderRadius: "8px",
  },
  title: {
    borderBottom: "2px solid #14B8A6",
    paddingBottom: "10px",
    marginBottom: "10px",
    alignItems: "center",
    color: color.textPrimary,
    width: "100%",
    textAlign: "center",
  },
  entryStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
    color: color.textPrimary,
    cursor: "pointer",
  },
  userEntryStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avatarStyle: {
    margin: "0 10px",
  },
  sellButton: {
    backgroundColor: color.blue,
    color: color.textPrimary,
    border: "none",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "5px 10px",
  },
};

export default Leaderboard;
