import React, { useEffect, useRef, useState } from "react";
import CommentContainer from "./CommentContainer";
import { useAccount } from "../providers/AccountProvider";
import CommentBox from "./CommentBox";
import color from "../utils/color";
import LoginModal from "./LoginModal";
import { postComment } from "../api/comment";
import Button from "@mui/material/Button";

const CommentSection = ({ comments, eventId, setShouldReload }) => {
  const [newComment, setNewComment] = useState("");
  const { user } = useAccount();
  const [allComments, setAllComments] = useState([]);

  const post = async () => {
    if (!user) {
      showLoginModal();
    } else {
      postComment(eventId, newComment).then(() => {
        setShouldReload(true);
        setNewComment("");
      });
    }
  };

  useEffect(() => {
    setAllComments(comments);
  }, [comments]);

  const loginModalRef = useRef();

  const showLoginModal = () => {
    loginModalRef.current.show();
  };

  return (
    <div style={styles.commentSection}>
      <LoginModal ref={loginModalRef} />
      <h2>Comments</h2>
      <CommentBox
        placeholder="Write your comment here..."
        value={newComment}
        onChange={(e) => {
          setNewComment(e.target.value);
        }}
      />
      <Button
        style={{ ...styles.commentButton, ...styles.betButton }}
        onClick={post}
      >
        Post Comment
      </Button>
      {allComments &&
        [...allComments]
          .reverse()
          .map((commentId) => (
            <CommentContainer key={commentId} commentId={commentId} />
          ))}
    </div>
  );
};

const styles = {
  commentSection: {
    borderTop: "1px solid #ccc",
    padding: "20px",
    backgroundColor: color.background,
  },
  commentButton: {
    backgroundColor: color.blue,
    color: color.textPrimary,
    padding: "10px 20px",
    border: "none",
    cursor: "pointer",
    marginTop: "10px",
  },
};

export default CommentSection;
