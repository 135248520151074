import React, { useEffect, useState } from "react";
import MessageContainer from "./MessageContainer";
import { getComment } from "../api/comment";

const RepliesList = ({ comment, setNumReplies }) => {
  const [allReplies, setAllReplies] = useState([]);
  const [shouldReload, setShouldReload] = useState(true);

  useEffect(() => {
    if (!comment.commenter) return;
    (async () => {
      const unsortedReplies = await getAllReplies();
      const sortedReplies = unsortedReplies.sort((a, b) => {
        return (
          new Date(a.commentedAt).getTime() - new Date(b.commentedAt).getTime()
        );
      });
      const filteredReplies = sortedReplies.filter((reply) => !reply.deleted);
      setNumReplies(filteredReplies.length);
      setAllReplies(filteredReplies);
      setShouldReload(false);
    })();
  }, [comment, shouldReload]);

  const getAllReplies = async () => {
    let repliesList = [];
    const recursiveGetReplies = async (parentCommenter, replies) => {
      if (!replies || replies.length === 0) return [];
      for (let i = 0; i < replies.length; i++) {
        const reply = replies[i];
        const result = await getComment(reply);
        repliesList.push({
          ...result.data,
          content: (
            <>
              <b>@{parentCommenter}</b> {result.data.content}
            </>
          ),
        });
        await recursiveGetReplies(
          result.data.commenter.name,
          result.data.replies
        );
      }
      return repliesList;
    };
    return await recursiveGetReplies(comment.commenter.name, comment.replies);
  };

  return (
    <div>
      {allReplies.map((comment, i) => (
        <MessageContainer
          key={i}
          comment={comment}
          setShouldReload={setShouldReload}
        />
      ))}
    </div>
  );
};

export default RepliesList;
