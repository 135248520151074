import React from "react";
import { CircularProgress } from "@mui/material";
import color from "../utils/color";

const Loading = (props) => {
  return (
    <div
      {...props}
      style={{
        display: "flex",
        flex: 1,
        position: "fixed",
        x: 0,
        y: 0,
        backgroundColor: color.background,
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000000,
        ...props.style,
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default Loading;
