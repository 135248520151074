import React from "react";
import color from "../utils/color";
import Gallery from "../components/Gallery";
import Page from "../components/Page";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopicEvents } from "../redux/topicEventsSlice";
import { fetchSearchEvents } from "../redux/searchEventsSlice";

const SearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const topic = searchParams.get("topic");
  const topicId = searchParams.get("topicId");
  const searchTerm = searchParams.get("searchTerm");

  const dispatch = useDispatch();

  const searchEvents = useSelector((state) => state.searchEvents.events);
  const searchEventsStatus = useSelector((state) => state.searchEvents.status);

  const topicEventsState = useSelector((state) => state.topicEvents[topicId]);

  return (
    <Page style={{ backgroundColor: color.secondary }}>
      <div style={{ padding: "20px" }}>
        {topic ? (
          <h3 style={{ color: color.textSecondary }}>Topic: "{topic}"</h3>
        ) : (
          <h3 style={{ color: color.textSecondary }}>Search: "{searchTerm}"</h3>
        )}
        {topicId ? (
          <Gallery
            events={topicEventsState ? topicEventsState.events : []}
            status={topicEventsState ? topicEventsState.status : "idle"}
            fetchEvents={async (lastId, amount) => {
              dispatch(fetchTopicEvents({ id: topicId, lastId, amount }));
            }}
          />
        ) : (
          <Gallery
            events={searchEvents}
            status={searchEventsStatus}
            fetchEvents={async (lastId, amount) => {
              dispatch(fetchSearchEvents({ lastId, amount, searchTerm }));
            }}
          />
        )}
      </div>
    </Page>
  );
};

const styles = {
  needFacebookContainer: {
    color: color.textSecondary,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default SearchPage;
