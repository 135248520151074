import React, { useEffect, useState } from "react";
import RepliesList from "./RepliesList";
import MessageContainer from "./MessageContainer";
import { Button } from "@mui/material";
import { getComment } from "../api/comment";

const CommentContainer = ({ commentId }) => {
  const [comment, setComment] = useState({});
  const [shouldReload, setShouldReload] = useState(true);
  const [numAllReplies, setNumAllReplies] = useState(1);
  const [repliesExpanded, setRepliesExpanded] = useState(false);

  useEffect(() => {
    if (!shouldReload) return;
    (async () => {
      const result = await getComment(commentId);
      setComment(result.data);
      setShouldReload(false);
    })();
  }, [shouldReload]);

  return (
    <div>
      {!(comment.deleted && !numAllReplies) && (
        <div>
          <MessageContainer
            comment={comment}
            setShouldReload={setShouldReload}
          />
          <div style={{ marginLeft: "55px", marginTop: "10px" }}>
            {numAllReplies > 0 && (
              <Button
                size={"small"}
                onClick={() => setRepliesExpanded(!repliesExpanded)}
              >
                {repliesExpanded ? "hide" : "show"} {numAllReplies}{" "}
                {numAllReplies === 1 ? "reply" : "replies"}
              </Button>
            )}
            <div style={repliesExpanded ? {} : { display: "none" }}>
              <RepliesList comment={comment} setNumReplies={setNumAllReplies} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentContainer;
