class SingleMarket {
  constructor(_id, y, n, k) {
    this._id = _id;
    this.y = y;
    this.n = n;
    this.k = k;
  }

  marketProbability() {
    return this.n / (this.y + this.n);
  }

  amountToBuyYesShares(amountYesShares) {
    amountYesShares = parseFloat(amountYesShares);
    const newY = this.y - amountYesShares;
    const roots = this.findRoots(1, this.n + newY, newY * this.n - this.k);
    const amount = Math.min(...roots.map((num) => (num < 0 ? Infinity : num)));
    return amount;
  }

  amountToBuyNoShares(amountNoShares) {
    amountNoShares = parseFloat(amountNoShares);
    const newN = this.n - amountNoShares;
    const roots = this.findRoots(1, this.y + newN, this.y * newN - this.k);
    const amount = Math.min(...roots.map((num) => (num < 0 ? Infinity : num)));
    return amount;
  }

  amountYesToMakeProbability(p) {
    p = parseFloat(p);
    const sqrtTerm = Math.sqrt(
      this.n * p * this.y -
        5 * this.n * p * p * this.y +
        8 * this.n * p * p * p * this.y -
        4 * this.n * p * p * p * p * this.y
    );
    const denominator = -p + p * p;

    const yPrime1 =
      (this.n * p - this.n * p * p - p * this.y + p * p * this.y - sqrtTerm) /
      denominator;
    const yPrime2 =
      (this.n * p - this.n * p * p - p * this.y + p * p * this.y + sqrtTerm) /
      denominator;

    return p > 0.5 ? yPrime1 : yPrime2;
  }

  amountNoToMakeProbability(p) {
    p = parseFloat(p);
    const sqrtTerm = Math.sqrt(
      this.y * p * this.n -
        5 * this.y * p * p * this.n +
        8 * this.y * p * p * p * this.n -
        4 * this.y * p * p * p * p * this.n
    );
    const denominator = -p + p * p;

    const nPrime1 =
      (this.y * p - this.y * p * p - p * this.n + p * p * this.n - sqrtTerm) /
      denominator;
    const nPrime2 =
      (this.y * p - this.y * p * p - p * this.n + p * p * this.n + sqrtTerm) /
      denominator;

    return p < 0.5 ? nPrime1 : nPrime2;
  }

  buyYesShares(amount) {
    amount = parseFloat(amount);
    const newY = this.y + amount;
    const newN = this.n + amount;
    const amountYesShares = (newY * newN - this.k) / newN;
    this.y = newY - amountYesShares;
    this.n = newN;
    return amountYesShares;
  }

  buyNoShares(amount) {
    amount = parseFloat(amount);
    const newY = this.y + amount;
    const newN = this.n + amount;
    const amountNoShares = (newY * newN - this.k) / newY;
    this.y = newY;
    this.n = newN - amountNoShares;
    return amountNoShares;
  }

  sellYesShares(amountYesShares) {
    amountYesShares = parseFloat(amountYesShares);
    const newY = this.y + amountYesShares;
    const roots = this.findRoots(1, -(this.n + newY), newY * this.n - this.k);
    const amount = Math.min(...roots);
    this.y = newY - amount;
    this.n = this.n - amount;
    return amount;
  }

  sellNoShares(amountNoShares) {
    amountNoShares = parseFloat(amountNoShares);
    const newN = this.n + amountNoShares;
    const roots = this.findRoots(1, -(this.y + newN), this.y * newN - this.k);
    const amount = Math.min(...roots);
    this.y = this.y - amount;
    this.n = newN - amount;
    return amount;
  }

  findRoots(a, b, c) {
    if (a === 0) {
      throw new Error("Not a quadratic equation");
    }
    let d = b * b - 4 * a * c;
    let sqrt_val = Math.sqrt(Math.abs(d));
    if (d > 0) {
      return [(-b + sqrt_val) / (2 * a), (-b - sqrt_val) / (2 * a)];
    } else if (d === 0) {
      return [-b / (2 * a)];
    } else {
      throw new Error("Roots are complex");
    }
  }

  addLiquidity(amount) {
    amount = parseFloat(amount);
    this.y = this.y + amount * this.marketProbability();
    this.n = this.n + amount * (1 - this.marketProbability());
    this.k = this.y * this.n;
  }
}

export default SingleMarket;
