import React from "react";
import color from "../utils/color";

const Deleted = ({ event }) => {
  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        x: 0,
        y: 0,
        backgroundColor: color.background,
        height: "100%",
        width: 900,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      This event has been deleted by{" "}
      {event.creator ? event.creator.name : "the creator"}{" "}
      {event.deleteReason &&
        `due to "
      ${event.deleteReason}"`}
    </div>
  );
};

export default Deleted;
