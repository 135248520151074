import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export default function BasicMenu({ handleSettingClick, settings }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <MoreHorizIcon style={{ cursor: "pointer" }} onClick={handleClick} />
      <Menu
        sx={{ mt: "25px" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {settings.map((setting) => (
          <MenuItem
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            key={setting}
            onClick={() => {
              handleClose();
              handleSettingClick(setting);
            }}
          >
            {setting}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
