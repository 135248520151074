import React, { useEffect } from "react";
import HomePage from "./pages/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { AccountProvider } from "./providers/AccountProvider";
import { Offline, Online } from "react-detect-offline";
import VerifyPage from "./pages/VerifyPage";
import EventPage from "./pages/EventPage";
import { FacebookProvider } from "react-facebook";
import color from "./utils/color";
import ProfilePage from "./pages/ProfilePage";
import CreatePage from "./pages/CreatePage";
import EditPage from "./pages/EditPage";
import ResolvePage from "./pages/ResolvePage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import HowItWorksPage from "./pages/HowItWorksPage";
import NotificationPage from "./pages/NotificationPage";
import PrivacyPage from "./pages/PrivacyPage";
import AddPhonePage from "./pages/AddPhonePage";
import { Provider } from "react-redux";
import store from "./redux/store";
import posthog from "posthog-js";
import SignupPage from "./pages/SignupPage";
import SearchPage from "./pages/SearchPage";

const App = () => {
  const PUBLIC_KEY =
    "pk_live_51MWxXhJVh0QyQWfRv3nAiKNKd5KGNFszGxyDlnAupoDQmcqHKuRu5BY72VgNQiQWUsHh6TN5fkyDuYsM2NmBuNtD00BUCTCQ8b";
  const stripeTestPromise = loadStripe(PUBLIC_KEY);

  posthog.init("phc_MWBqJMcRIj1BUcwErJVPl923J7XRCAOiAN6BLbVgpXA", {
    api_host: "https://app.posthog.com",
  });

  useEffect(() => {
    document.body.style.margin = "0";
    document.body.style.backgroundColor = color.background;
    document.body.style.fontFamily = "Montserrat, sans-serif";
    document.querySelectorAll("input").forEach((input) => {
      input.style.fontFamily = "Montserrat, sans-serif";
    });
    document.querySelectorAll("textarea").forEach((textarea) => {
      textarea.style.fontFamily = "Montserrat, sans-serif";
    });
    let machineId = localStorage.getItem("machineId");
    if (!machineId) {
      machineId = Math.random().toString(36);
      localStorage.setItem("machineId", machineId);
    }
    posthog.capture("visited", { machineId });
  }, []);

  return (
    <FacebookProvider appId="268387592762445">
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap"
        rel="stylesheet"
      />
      <Provider store={store}>
        <Online>
          <Elements stripe={stripeTestPromise}>
            <AccountProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="" element={<HomePage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/signup" element={<SignupPage />} />
                  <Route path="/verify" element={<VerifyPage />} />
                  <Route path="/event/:id" element={<EventPage />} />
                  <Route path="/profile/:id" element={<ProfilePage />} />
                  <Route path="/create" element={<CreatePage />} />
                  <Route path="/edit/:id" element={<EditPage />} />
                  <Route path="/resolve/:id" element={<ResolvePage />} />
                  <Route path="/how-it-works" element={<HowItWorksPage />} />
                  <Route path="/notifications" element={<NotificationPage />} />
                  <Route path="/privacy-policy" element={<PrivacyPage />} />
                  <Route path="/deletion/:id" element={<PrivacyPage />} />
                  <Route path="/add-phone" element={<AddPhonePage />} />
                  <Route path="/search" element={<SearchPage />} />
                </Routes>
              </BrowserRouter>
            </AccountProvider>
          </Elements>
        </Online>
        <Offline>
          <div />
        </Offline>
      </Provider>
    </FacebookProvider>
  );
};

export default App;
