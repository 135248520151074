import axios from "axios";
import baseUrl from "./baseUrl";

const postComment = async (eventId, content) => {
  return await axios.post(
    `${await baseUrl()}/comment/postComment`,
    { eventId, content },
    {
      withCredentials: true,
    }
  );
};

const getComment = async (commentId) => {
  return await axios.get(`${await baseUrl()}/comment/${commentId}`);
};

const postReply = async (parentCommentId, content) => {
  return await axios.post(
    `${await baseUrl()}/comment/postReply`,
    { parentCommentId, content },
    {
      withCredentials: true,
    }
  );
};

const likeComment = async (commentId) => {
  return await axios.post(
    `${await baseUrl()}/comment/likeComment`,
    { commentId },
    {
      withCredentials: true,
    }
  );
};

const deleteComment = async (commentId) => {
  return await axios.post(
    `${await baseUrl()}/comment/deleteComment`,
    { commentId },
    {
      withCredentials: true,
    }
  );
};

export { postComment, getComment, postReply, likeComment, deleteComment };
