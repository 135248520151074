import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoginButton, useFacebook } from "react-facebook";
import { Divider } from "@mui/material";
import color from "../utils/color";
import { useAccount } from "../providers/AccountProvider";
import { facebookLogin, phoneLogin } from "../api/user";
import Button from "@mui/material/Button";
import Loading from "../components/Loading";
import LogoFull from "../components/LogoFull";

const LoginPage = () => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { login } = useAccount();
  let navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!phone) {
      setError("Please enter phone");
    }
    if (error) {
      return;
    }
    setLoading(true);
    try {
      const result = await phoneLogin(phone);
      if (result.data.loggedIn) {
        navigate("/verify", { state: { phone } });
      } else {
        setError("Server error, please try again later.");
      }
    } catch (error) {
      setError(error.response.data);
    }
    setLoading(false);
  };

  const { init } = useFacebook();

  async function handleFbSuccess(response) {
    setLoading(true);
    const api = await init();
    const FB = await api.getFB();
    FB.api("/me", function (response) {
      const facebook = response;
      FB.api(
        `/${facebook.id}/picture?redirect=false&width=9999`,
        "GET",
        {},
        async function (response) {
          try {
            const result = await facebookLogin({
              ...facebook,
              profilePicture: response.data.url,
            });
            const { token, needsPhone } = result.data;
            if (needsPhone) {
              navigate("/add-phone", { state: { token } });
            } else {
              await login(token);
              navigate("/");
            }
          } catch (error) {
            setError(error.response.data);
          }
          setLoading(false);
        }
      );
    });
  }

  function handleFbError(error) {
    setError(error);
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: color.background,
      }}
    >
      {loading && <Loading />}
      <div
        style={{
          textAlign: "center",
          padding: "50px",
          background: color.secondary,
          borderRadius: "10px",
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
          width: window.innerWidth < 600 ? "100%" : "60%",
          height: window.innerWidth < 600 ? "100%" : "60%",
          boxSizing: "border-box",
        }}
      >
        <LogoFull
          onClick={() => {
            navigate("/");
          }}
          style={{ marginTop: "20px", height: "100px", cursor: "pointer" }}
        />
        <div style={{ color: "red" }}>{error}</div>
        <form onSubmit={handleLogin}>
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                marginBottom: "10px",
                color: color.textSecondary,
                textTransform: "uppercase",
                fontSize: "12px",
                letterSpacing: "1px",
              }}
            >
              Phone:
            </label>
            <input
              type="tel"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                setError("");
              }}
              style={{
                padding: "10px",
                fontSize: "16px",
                width: "100%",
                borderRadius: "5px",
                border: "none",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                boxSizing: "border-box",
              }}
            />
          </div>
          <Button
            type="submit"
            style={{
              backgroundColor: color.green,
              color: color.textPrimary,
              padding: "10px 20px",
              fontSize: "16px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Login
          </Button>
          <Divider
            style={{
              margin: "20px 0",
              color: color.textSecondary,
            }}
          >
            OR
          </Divider>
          <LoginButton
            style={{
              backgroundColor: color.facebook,
              color: color.textPrimary,
              padding: "10px 20px",
              fontSize: "16px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            scope="public_profile, email, user_friends"
            onError={handleFbError}
            onSuccess={handleFbSuccess}
          >
            Login with Facebook
          </LoginButton>
          <br />
          <br />
          <a style={{ color: color.textSecondary }} href="/signup">
            Don't have an account? Sign up to claim $10.
          </a>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
