import React, { forwardRef, useImperativeHandle, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import color from "../utils/color";
import Hunchies from "./Hunchies";
import { finishTutorial } from "../api/user";
import { useAccount } from "../providers/AccountProvider";

const TutorialModal = forwardRef((props, ref) => {
  const [showModal, setShowModal] = useState(false);
const { user, updateUser } = useAccount();

  const show = () => {
    setShowModal(true);
  };

  const hide = () => {
    setShowModal(false);
    };

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const handleClose = async () => {
    if (user) {
      await finishTutorial();
await updateUser();
    }
    localStorage.setItem("finishedTutorial", true);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000000,
          }}
          onClick={handleClose}
        >
          <div
            style={{
              backgroundColor: color.secondary,
              padding: "20px",
              borderRadius: "8px",
              color: color.textSecondary,
              width: "80%",
              maxWidth: "500px",
              margin: "auto",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Typography
              variant="h5"
              id="tutorial-modal-title"
              style={styles.title}
            >
              Welcome to HunchPot!
            </Typography>
            <Typography
              variant="body1"
              id="tutorial-modal-description"
              style={styles.description}
            >
              Hunchpot is a platform where you can bet on the outcomes of future events.
            </Typography>
            <ul style={styles.list}>
              <li>
                Make predictions (or hunches) on various topics and see how the pots
                play out.
              </li>
              <li>
                If you predict correctly, you will earn "hunchies <Hunchies />"
              </li>
              <li>Exchange Rate: 100<Hunchies /> = $1 USD</li>
            </ul>
            <Typography variant="body1" style={styles.description}>
              Dive in, have fun, and happy hunching!
            </Typography>
            <Button style={styles.Button} onClick={handleClose}>
              Got it!
            </Button>
          </div>
        </div>
      )}
    </>
  );
});

const styles = {
  title: {
    marginBottom: "15px",
    color: color.textPrimary,
  },
  description: {
    marginBottom: "10px",
    color: color.textSecondary,
  },
  list: {
    paddingLeft: "20px",
    marginBottom: "15px",
    color: color.textSecondary,
  },
  Button: {
    display: "block",
    margin: "20px auto 0",
    backgroundColor: color.primary,
    color: color.textPrimary,
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default TutorialModal;
