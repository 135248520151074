import React, { forwardRef, useImperativeHandle, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import color from "../utils/color";
import Hunchies from "./Hunchies";
import { Alert } from "@mui/material";
import { useAccount } from "../providers/AccountProvider";

const ReferModal = forwardRef((props, ref) => {
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState("");
  const { user } = useAccount();

  const show = () => {
    setShowModal(true);
  };

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  const handleShareClick = () => {
    const referUrl = user
      ? window.location.href + "?referrer=" + user._id
      : window.location.href;
    if (navigator.share) {
      navigator.share({
        title: "HunchPot Referral",
        text: "Invite friends and earn 500 hunchies!",
        url: referUrl,
      });
    } else {
      navigator.clipboard.writeText(referUrl);
      setAlert("Link copied to clipboard");
    }
    setTimeout(() => {
      setAlert("");
    }, 3000);
  };

  return (
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000000,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(false);
          }}
        >
          {alert && (
            <div
              style={{
                position: "fixed",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                zIndex: 1000000,
              }}
            >
              <div
                style={{
                  width: window.innerWidth < 900 ? "100%" : 900,
                }}
              >
                <Alert onClose={() => setAlert("")} severity="success">
                  {alert}
                </Alert>
              </div>
            </div>
          )}
          <div
            style={{
              backgroundColor: color.secondary,
              padding: "20px",
              borderRadius: "8px",
              color: color.textSecondary,
              width: "80%",
              maxWidth: "400px",
              margin: "auto",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Typography
              variant="h5"
              id="invite-modal-title"
              style={styles.title}
            >
              Invite Your Friends!
            </Typography>
            <Typography
              variant="body1"
              id="invite-modal-description"
              style={styles.description}
            >
              Get 500{<Hunchies />} for each friend who joins Hunch Pot!
            </Typography>
            <Button
              style={alert ? styles.copiedButton : styles.Button}
              onClick={handleShareClick}
            >
              {alert ? "Link Copied!" : "Share Link"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
});

const styles = {
  title: {
    marginBottom: "15px",
    color: color.textPrimary,
  },
  description: {
    marginBottom: "10px",
    color: color.textSecondary,
  },
  Button: {
    display: "block",
    margin: "20px auto 0",
    backgroundColor: color.blue,
    color: color.textPrimary,
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  copiedButton: {
    display: "block",
    margin: "20px auto 0",
    backgroundColor: color.primary,
    color: color.textPrimary,
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "not-allowed",
  },
  notification: {
    marginTop: "10px",
    color: color.textPrimary,
  },
};

export default ReferModal;
