import { createSlice } from "@reduxjs/toolkit";

export const homeTabSlice = createSlice({
  name: "homeTab",
  initialState: { tab: "Explore" },
  reducers: {
    setHomeTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});

export const profileTabSlice = createSlice({
  name: "profileTab",
  initialState: {},
  reducers: {
    setProfileTab: (state, action) => {
      const { id, tab } = action.payload;
      state[id] = tab;
    },
  },
});

export const { setHomeTab } = homeTabSlice.actions;
export const { setProfileTab } = profileTabSlice.actions;

export default {
  homeTab: homeTabSlice.reducer,
  profileTab: profileTabSlice.reducer,
};
