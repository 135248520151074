import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import color from "../utils/color";
import { addPhone } from "../api/user";
import Button from "@mui/material/Button";
import LogoFull from "../components/LogoFull";

const AddPhonePage = () => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  const { token } = location.state;

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!phone) {
      setError("Please enter phone");
    }
    if (error) {
      return;
    }
    try {
      const result = await addPhone(phone, token);
      if (result.data.loggedIn) {
        navigate("/verify", { state: { phone } });
      } else {
        setError("Server error, please try again later.");
      }
    } catch (error) {
      setError(error.response.data);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: color.background,
      }}
    >
      <div
        style={{
          textAlign: "center",
          padding: "50px",
          background: color.secondary,
          borderRadius: "10px",
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
          width: window.innerWidth < 600 ? "100%" : "60%",
          height: window.innerWidth < 600 ? "100%" : "60%",
          boxSizing: "border-box",
        }}
      >
        <LogoFull
          onClick={() => {
            navigate("/");
          }}
          style={{ marginTop: "20px", height: "100px", cursor: "pointer" }}
        />
        <div style={{ color: "red" }}>{error}</div>
        <form onSubmit={handleLogin}>
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3 style={{ color: color.textSecondary }}>One Last Step</h3>
            <label
              style={{
                marginBottom: "10px",
                color: color.textSecondary,
                textTransform: "uppercase",
                fontSize: "12px",
                letterSpacing: "1px",
              }}
            >
              Connect Your Phone:
            </label>
            <input
              type="tel"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                setError("");
              }}
              style={{
                padding: "10px",
                fontSize: "16px",
                width: "100%",
                borderRadius: "5px",
                border: "none",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                boxSizing: "border-box",
              }}
            />
          </div>
          <Button
            type="submit"
            style={{
              backgroundColor: color.green,
              color: color.textPrimary,
              padding: "10px 20px",
              fontSize: "16px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Continue
          </Button>
          <br />
        </form>
      </div>
    </div>
  );
};

export default AddPhonePage;
