import React, { forwardRef, useImperativeHandle, useState } from "react";

const ProfilePictureModal = forwardRef((props, ref) => {
  const { profilePicture } = props;
  const [showModal, setShowModal] = useState(false);

  const show = () => {
    setShowModal(true);
  };

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  return (
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000000,
          }}
          onClick={() => setShowModal(false)}
        >
          <div
            style={{
              margin: "auto",
            }}
          >
            <img
              src={profilePicture ? profilePicture : "/"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
});

export default ProfilePictureModal;
